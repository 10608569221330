
import Vue, { PropType } from 'vue'
import { RelatedTableFilterOptionProps } from '../models/RelatedTableFilterOption'
import Table from './Table.vue'

export default Vue.extend({
  components: {
    Table
  },
  props: {
    filterOption: {
      type: Object as PropType<RelatedTableFilterOptionProps>,
      required: true
    }
  }
})
