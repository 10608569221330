<template>
  <TemplateSinglePage :show-loading="!itemSet">
    <div
      v-if="itemSet"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <h1 class="mb-2 font-sans text-3xl font-bold text-primary">
        {{ itemSet.name }}
      </h1>

      <div
        class="flex flex-wrap justify-between p-0 m-0 font-sans text-base text-black "
      >
        <div
          class="p-3.5 m-0 w-full relative max-w-xl sm:min-w-[30rem] min-h-[20rem] text-base rounded-sm bg-bg-900 text-white "
        >
          <div
            class="block space-y-2 text-right sm:absolute right-6 top-4"
          >
            <div
              v-for="(itemSetClass, index) in itemSet.allowedClasses"
              :key="index"
              class="mb-4"
              :style="{'color': '#' + itemSetClass.colorCode}"
            >
              <img
                class="max-w-[150px]"
                :src="`${baseUrl}/img/Class_${itemSetClass.name}.svg`"
                alt="Class Logo"
              >
            </div>
            <span
              v-if="itemSetCategories[itemSet.itemSetTypeId]"
              class="text-2xl"
            >{{ itemSetCategories[itemSet.itemSetTypeId].name }}</span>
            <span
              class="block text-3xl"
              v-if="itemSet.items.length > 0"
            >Level {{ itemSet.items[0].requiredLevel }}</span>
          </div>

          <item-tooltip
            :item="itemSet"
          />
        </div>
      </div>

      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import ItemTooltip from '@/components/ItemTooltip.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import itemSetMixin from '../../../../lib/src/mixins/item-set-mixin'
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
import RelatedTableFilterOption from '../../../../lib/src/models/RelatedTableFilterOption'
export default {
  name: 'Item',
  components: {
    TemplateSinglePage,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    ItemTooltip
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.itemSet?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.itemSet?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    if (this.itemSet?.allowedClasses?.length > 0) {
      const firstAllowedClass = this.itemSet.allowedClasses[0]
      info.meta.push({ property: 'og:image', vmid: 'og:image', content: `${this.baseUrl}/img/Class_${firstAllowedClass.name}.svg` })
    }

    return info
  },
  mixins: [
    itemSetMixin,
    tooltipMixin
  ],
  data: () => {
    return {
      filterOptions: [
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })],
      itemSetCategories: [
        {
          id: 0,
          name: 'Cloth'
        },
        {
          id: 1,
          name: 'Leather'
        },
        {
          id: 2,
          name: 'Mail'
        },
        {
          id: 3,
          name: 'Plate'
        },
        {
          id: 4,
          name: 'Miscellaneous'
        },
        {
          id: 5,
          name: 'Rings'
        },
        {
          id: 6,
          name: 'Trinkets'
        },
        {
          id: 7,
          name: 'Weapons'
        }
      ],
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    metaDescription () {
      return `View Item Set ${this.itemSet?.name} from The Burning Crusade Classic.`
    },
    itemSet () {
      return this.$store.state.itemSets[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchItemSet().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.itemSet) this.fetchItemSet()
  },
  mounted () { // Client side fetching
    this.fetchItemSet()
  },
  methods: {
    fetchItemSet () {
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchItemSet', this.$route.params.id)
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>
