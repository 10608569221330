
import Vue, { PropType } from 'vue';
import { Zone } from '../../../../lib/src/models/Zone';
export default Vue.extend({
  name: 'ZoneTooltip',
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true
    }
  }

})
