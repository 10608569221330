<template>
  <div class="space-y-2">
    <label
      :for="labelFor"
      class="text-left text-gray-300"
    >
      {{ title }}
    </label>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldWithLabel',
  props: {
    title: {
      type: String,
      default: ''
    },
    labelFor: {
      type: String,
      default: ''
    }
  }
}
</script>
