import * as E from 'fp-ts/Either'
import RelatedTableFilterOption, { RowAccessor } from './RelatedTableFilterOption'
import { TableColumn } from './Table'

type RelatedTableFilterProps = {
  options: RelatedTableFilterOption[],
  selectedIndex?: number | undefined,
  name?: string | undefined
}

export default class RelatedTableFilter {
  #options: RelatedTableFilterOption[]
  #selectedIndex: number
  #name: string | undefined

  constructor (props: RelatedTableFilterProps) {
    const { options, selectedIndex, name } = props

    this.#options = options
    this.#selectedIndex = selectedIndex ?? 0
    this.#name = name
  }

  get selectedIndex (): number {
    return this.#selectedIndex
  }

  get selectedOption (): RelatedTableFilterOption {
    return this.#options[this.#selectedIndex]
  }

  get column (): TableColumn[] {
    return this.selectedOption.column ?? []
  }

  get data (): any {
    if (this.#name && this.#name.length > 0) {
      return this.selectedOption.data?.filter((d) => d.name.toLowerCase().includes(this.#name!.toLowerCase()))
    }

    return this.selectedOption.data
  }

  get rowAccessor (): RowAccessor | undefined {
    return this.selectedOption?.rowAccessor
  }

  get selectedUrlPrefix (): string | undefined {
    return this.selectedOption.urlPrefix
  }

  setSelectedIndex (index: number): E.Either<Error, RelatedTableFilter> {
    if (this.#options[index].isDisabled) return E.left(new Error('Cannot move to a disabled tab.'))

    return E.right(this.copyWith({
      selectedIndex: index
    }))
  }

  setNameFilter (name: string): RelatedTableFilter {
    return this.copyWith({
      name
    })
  }

  copyWith (props: Partial<RelatedTableFilterProps>) {
    const { options, selectedIndex, name } = props

    return new RelatedTableFilter({
      options: options ?? this.#options,
      selectedIndex: selectedIndex ?? this.#selectedIndex,
      name: name ?? this.#name
    })
  }
}
