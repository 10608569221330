<template>
  <div
    v-if="locations && selectedLocation"
    class="h-full max-h-[100vh]"
  >
    <div
      id="tab-group"
      class=""
    >
      <button
        v-for="location in locations.filter((location) => location).sort((a, b) => getAmountOfMarkersForLocation(b) - getAmountOfMarkersForLocation(a)).slice(0, showMore ? locations.length : 5)"
        :key="location ? location.name : location"
        :class="selectedLocation.id === location.id ? 'bg-primary text-bg-300' : 'bg-bg-300 hover:bg-primary hover:text-bg-300 text-primary'"
        class="transition-all border  border-primary px-3 py-0.5 min-w-max"
        @click="changeSelectedLocation(location)"
      >
        <span>{{ location.name }} ({{ getAmountOfMarkersForLocation(location) }})</span>
      </button>
      <button
        v-if="!showMore && locations.length > 5"
        class="text-primary bg-transparent p-0 ml-2 border-none focus:ring-0"
        type="text"
        @click="showMore = !showMore"
      >
        Show more...
      </button>
    </div>
    <WorldMap
      :markers="selectedLocationMarkers ? selectedLocationMarkers : []"
      :location-id="selectedLocation.id"
      @openMapModal="openMapModal"
    />

    <transition name="fade">
      <MapModal
        v-if="showMapModal"
        :location-id="selectedLocation.id"
        :markers="selectedLocationMarkers"
        @close="closeMapModal"
      />
    </transition>
  </div>
</template>

<script>
import WorldMap from '@/components/Map.vue'
import MapModal from '@/components/MapModal.vue'
export default {
  name: 'WorldMapContainer',
  components: {
    WorldMap,
    MapModal
  },
  props: {
    locations: {
      type: Array,
      default: () => []
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedLocation: this.locations[0],
      showMapModal: false,
      showMore: false
    }
  },
  computed: {
    selectedLocationMarkers () {
      return this.markers.filter(marker => marker.location === this.selectedLocation.id)
    }
  },
  methods: {
    getAmountOfMarkersForLocation (location) {
      return this.markers.filter(marker => marker.location === location.id).length
    },
    openMapModal () {
      this.showMapModal = true
    },
    changeSelectedLocation (location) {
      this.selectedLocation = location
    },
    closeMapModal () {
      this.showMapModal = false
    }
  }
}
</script>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
