<template>
  <div class="list-view-pagination flex justify-center">
    <router-link
        v-for="(p, i) in pageEntries"
        :key="i"
        :to="getPageLink(p)"
        class="flex items-center justify-center w-8 h-8 transition-all transform rounded-md hover:scale-125 hover:text-black "
        :class="{ disabled: p === '...', active: p === currentPage }"
    >
      {{ p }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ListViewPagination',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    maxRange: { // Maximum range which will be displayed around the current page before doing a "..."
      type: Number,
      default: 3
    }
  },
  computed: {
    pageEntries () {
      // Generate [1, ..., totalEntries] and only show first and last page, and everything in the range around the current page
      const rawEntries = Array.from({ length: this.totalPages }, (_, i) => i + 1)
        .filter(p => p === 1 || p === this.totalPages || (p <= this.currentPage + this.maxRange && p >= this.currentPage - this.maxRange))

      // Insert "..." between page jumps
      const pageEntries = []
      let lastPage = 0
      for (const p of rawEntries) {
        if ((p - lastPage) > 1) pageEntries.push('...')
        pageEntries.push(p)
        lastPage = p
      }

      return pageEntries
    }
  },
  methods: {
    getPageLink (page) {
      return {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          p: page
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
a {
  &:not(:first-child) {
    margin-left: 10px;
  }

  &:not(.disabled) {
    @apply bg-bg-500;
  }

  &.disabled {
    pointer-events: none;
  }

  &.active {
    @apply bg-primary ring-2 ring-primary font-bold text-black;
  }

  &:hover {
    &:not(.active) {
      @apply bg-primary;
    }
  }
}
</style>
