
import Vue from 'vue'
import { singlePageParser } from '../../../../lib/src/util/stringUtils'

export interface DropdownButtonItem {
  name: String
  href: String
}

export interface DropdownButtonProps {
  label: String
  items: DropdownButtonItem[]
}

export default Vue.extend({

  props: {
    data: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  methods: {
    mouseLeave () {
      this.isOpen = false
    },
    singlePageParser,
    mouseEnter () {
      if (this.data.length) { this.isOpen = true }
    },
    link (label: string) {
      // Replace any spaces or apostrophes with hyphens
      return label?.replace(/\s+|'/g, '-').toLowerCase()
    }

  }
})
