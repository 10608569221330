
<template>
  <div
    id="loading"
    class="flex flex-col items-center justify-center h-full mx-auto my-auto animate-pulse"
  >
    <!-- Icon: https://github.com/HearthSim/hs-icons -->
    <svg
      class="w-48 h-48 text-blue-500 transform"
      version="1.1"
      viewBox="0 0 128 128"
      xml:space="preserve"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m55.09 50.96s18.116-1.506 18.587 15.481c0.47 16.987-16.235 19.339-26.587 18.869-10.352-0.471-32.232-14.822-30.585-37.644l1.277-8.128 3.639-4.185s8.369-16.921 27.474-22.015c0 0 1.637-2.729 3.639-2.729 2.001 0 3.639 2.183 6.55 2.547s4.003-2.729 6.732-2.547 2.911 2.183 5.822 2.729 17.103 2.183 28.565 17.831c0 0 0.546 4.003 2.183 4.731s5.458 2.365 6.004 3.639 4.367 14.919 4.003 20.742c-0.364 5.822-2.001 32.932-20.014 41.301l-0.364 4.912s-13.464 12.008-34.024 10.553-26.096-3.621-30.93-10.189c-0.666-0.905-1.637-4.185-1.637-4.185l-1.819-3.821s-1.637-2.729 1.819-2.183c3.457 0.546 16.011 5.458 22.925 5.64s10.553-3.457 11.826-3.821c1.274-0.364 8.733-3.275 10.917-3.639 2.183-0.364 15.647-8.602 17.649-28.408s-15.83-31.451-26.019-31.633c0 0-6.368-0.182-7.824 0.728s-10.917 5.276-15.101 5.822c0 0-3.457 3.275-2.911 7.278s0.546 17.78 15.101 17.805c0 0 3.476-1.876 3.103-15.481z"
      />
    </svg>
    <h2 class="text-4xl">
      Hearthing...
    </h2>
  </div>
</template>

<script>
export default {

}
</script>
