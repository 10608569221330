<template>
  <div
    v-if="filterOptions.length > 0"
    class="mt-5"
  >
    <div
      v-if="!$route.name === 'Search'"
      class="p-0 mx-0 mt-0 mb-3.5 font-sans text-xl font-bold text-white "
    >
      Related
    </div>
    <div
      class="flex flex-wrap gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base"
    >
      <related-table-filter-button
        v-for="(filter, index) in filterOptions"
        :key="index"
        :filter="filter"
        :is-active="index === selectedIndex"
        @click="select(index)"
      />
    </div>
    <div
      v-for="(filter, index) in filterOptions"
      :key="index"
    >
      <related-filter-table
        v-if="index === selectedIndex"
        :filter-option="filter"
      />
    </div>
  </div>
</template>

<script>
import { match } from 'fp-ts/lib/Either'
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import RelatedTableFilter from '../models/RelatedTableFilter'
import { convertCamelCaseToKebabCase } from '../util'
import RelatedFilterTable from './RelatedFilterTable.vue'
import RelatedTableFilterButton from './RelatedTableFilterButton.vue'

export default Vue.extend({
  components: {
    RelatedTableFilterButton,
    RelatedFilterTable
  },

  props: {
    filterOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      tableFilter: new RelatedTableFilter({ options: this.filterOptions.sort((a, b) => b.data.length - a.data.length) })
    }
  },

  computed: {
    selectedIndex () {
      return this.tableFilter.selectedIndex ?? 0
    },
    myData () {
      return this.tableFilter.data
    }
  },

  watch: {
    filterOptions: function (newVal, oldVal) {
      this.tableFilter = new RelatedTableFilter({ selectedIndex: this.selectedIndex, options: newVal.sort((a, b) => b.data.length - a.data.length) })
    }
  },

  mounted () {
    if (this.filterOptions.length > 0) {
      this.setRelatedTableIndex(this.filterOptions[0].key)
    }
  },

  methods: {
    ...mapActions(['updateRelatedTableData']),
    ...mapMutations(['setRelatedTableIndex', 'setLoading', 'clearLoading', 'setPageInfo']),
    select (index) {
      this.setLoading()
      const maybeChangeIndex = this.tableFilter.setSelectedIndex(index)
      const onError = (error) => {
        this.clearLoading()
        console.error(error)
      }
      const onSuccess = (newTableFilter) => {
        // If the data is loaded on a different page than 1, we need to reload it
        this.setRelatedTableIndex(this.filterOptions[index].key)
        if (newTableFilter.selectedOption.data.currentPage !== 1 && this.$route.name !== 'Search') {
          this.updateRelatedTableData({
            // The name of the current route, e.g. 'Item', 'Spell'
            type: this.$store.state.route.name,
            id: this.$store.state.route.params.id,
            // The name of the function in the API service to call -
            // note that the name of the related table must match the name of the function in the API
            apiFunctionName: convertCamelCaseToKebabCase(this.$store.state.selectedRelatedTableIndex),
            // The name of the related table on the actual item, spell etc
            tableName: this.$store.state.selectedRelatedTableIndex,
            newPage: 1
          })
        }
        // use the mapActions helper to call the setPageInfo action and the setRelatedTableIndex action)
        this.setPageInfo({ currentPage: 1 })

        this.tableFilter = newTableFilter
        this.clearLoading()
      }
      match(
        onError,
        onSuccess
      )(maybeChangeIndex)
    }
  }

})
</script>
