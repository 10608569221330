import { Achievement } from './Achievement'
import { GameObject } from './GameObject'
import { Item } from './Item'
import { NPC, PaginatedData } from './NPC'
import { Quest } from './Quest'
import RelatedTableFilterOption, { setRelatedTable } from './RelatedTableFilterOption'

export type Location = {
  id: number
  locationId: number
  name: string
  suggestedLevel: number
}

export type ZoneArea = {
  areaTypeId: number
  description: string
  id: number
  minLevel: number
  maxLevel: number
  name: string
  recommendedMaxLevel: number | undefined
  recommendedMinLevel: number | undefined
}

export type ZoneDto = {
  area?: ZoneArea | undefined
  areaId: number
  id: number
  name: string
  subLocations: PaginatedData<Location>
  quests: PaginatedData<Quest>
  questRewards: PaginatedData<Item>
  fishing: PaginatedData<Item>
  npcs: PaginatedData<NPC>
  objects: PaginatedData<GameObject>
  suggestedLevel: number | undefined
  criteriaOf: PaginatedData<Achievement>
}

export class Zone {
   #area?: ZoneArea | undefined
   public get area (): ZoneArea | undefined {
     return this.#area
   }

  #areaId: number
  public get areaId (): number {
    return this.#areaId
  }

   #id: number
   public get id (): number {
     return this.#id
   }

   #name: string
   public get name (): string {
     return this.#name
   }

   #subLocations: PaginatedData<Location>
   public get subLocations (): PaginatedData<Location> {
     return this.#subLocations
   }

   #fishing: PaginatedData<Item>
   public get fishing (): PaginatedData<Item> {
     return this.#fishing
   }

   #criteriaOf: PaginatedData<Achievement>
   public get criteriaOf (): PaginatedData<Achievement> {
     return this.#criteriaOf
   }

   #questRewards: PaginatedData<Item>
   public get questRewards (): PaginatedData<Item> {
     return this.#questRewards
   }

   #quests: PaginatedData<Quest>
   public get quests (): PaginatedData<Quest> {
     return this.#quests
   }

   #npcs: PaginatedData<NPC>
   public get npcs (): PaginatedData<NPC> {
     return this.#npcs
   }

   #objects: PaginatedData<GameObject>
   public get objects (): PaginatedData<GameObject> {
     return this.#objects
   }

   #suggestedLevel: number | undefined

   public get suggestedLevel (): number | undefined {
     if (this.#suggestedLevel !== 0) {
       return this.#suggestedLevel
     }

     if (
       this.#area?.recommendedMinLevel &&
       this.#area?.recommendedMinLevel !== 0) {
       return this.#area.recommendedMinLevel
     }

     if (this.#area?.minLevel) {
       return this.#area?.minLevel
     }

     return undefined
   }

   constructor (props: ZoneDto) {
     const {
       area,
       areaId,
       id,
       name,
       npcs,
       objects,
       quests,
       questRewards,
       subLocations,
       fishing,
       criteriaOf,
       suggestedLevel
     } = props

     this.#area = area ?? undefined
     this.#areaId = areaId
     this.#id = id
     this.#name = name
     this.#subLocations = subLocations
     this.#questRewards = questRewards
     this.#fishing = fishing
     this.#criteriaOf = criteriaOf
     this.#quests = quests
     this.#npcs = npcs
     this.#objects = objects
     this.#suggestedLevel = suggestedLevel
   }

   get relatedTableFilterOptions () {
     return [
       setRelatedTable(this.#subLocations, 'subLocations', '', RelatedTableFilterOption.subLocations),
       setRelatedTable(this.#quests, 'quests', '', RelatedTableFilterOption.quests),
       setRelatedTable(this.#npcs, 'npcs', '', RelatedTableFilterOption.npcs),
       setRelatedTable(this.#questRewards, 'questRewards', '', RelatedTableFilterOption.questRewards),
       setRelatedTable(this.#objects, 'objects', '', RelatedTableFilterOption.objects),
       setRelatedTable(this.#fishing, 'fishing', '', RelatedTableFilterOption.fishingZone),
       setRelatedTable(this.#criteriaOf, 'criteriaOf', '', RelatedTableFilterOption.achievements),
       new RelatedTableFilterOption({
         title: 'Sounds',
         key: 'sounds',
         isDisabled: true
       }),
       new RelatedTableFilterOption({
         title: 'Guides',
         key: 'guides',
         isDisabled: true
       }),
       new RelatedTableFilterOption({
         title: 'Comments',
         key: 'comments',
         isDisabled: true
       }),
       new RelatedTableFilterOption({
         title: 'Screenshots',
         key: 'screenshots',
         isDisabled: true
       })
     ].filter(Boolean)
   }
}
