module.exports = function (types, params, defaultColumns) {
  if (!params.first) return defaultColumns

  let columns = []
  let type = { children: types }
  for (const paramType of [params.first, params.second, params.third].filter(p => p)) {
    if (!type.children) break
    type = type.children.find(t => t.name.replace(/ /g, '-').toLowerCase() === paramType.toLowerCase())
    if (type && type.columns) columns = type.columns
  }

  if (!type) return defaultColumns
  else {
    return [
      ...defaultColumns,
      ...columns.map(col => {
        return {
          field: col.field + (col.nested ? '.' + col.nested : ''),
          label: col.label || formatName(col.field),
          sortable: !col.fn,
          format: col.fn,
          model: col.model
        }
      })
    ]
  }
}

const formatName = (name) => {
  return (name.charAt(0).toUpperCase() + name.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2')
}
