export const tbcNavItems = [
  {
    title: 'Database',
    links: [
      {
        href: '/items',
        name: 'Items'
      },
      {
        href: '/item-sets',
        name: 'Item Sets'
      },
      {
        href: '/quests',
        name: 'Quests'
      },
      {
        href: '/npcs',
        name: 'NPCs'
      },
      {
        href: '/zones',
        name: 'Zones'
      },
      {
        href: '/spells',
        name: 'Spells'
      }, {
        href: '/objects',
        name: 'Objects'
      },
      {
        href: '/factions',
        name: 'Factions'
      },
      {
        href: '/enchantments',
        name: 'Enchantments'
      },
      {
        href: '/races',
        name: 'Races'
      },
      {
        href: '/classes',
        name: 'Classes'
      },
      {
        href: '/events',
        name: 'Events'
      }
    ],
    expanded: true
  },
  {
    title: 'Tools',
    links: [
      {
        href: '/tools/tooltip',
        name: 'Tooltip'
      }

    ],
    expanded: true
  }
]

export const vanillaNavItems = [
  {
    title: 'Database',
    links: [
      {
        href: '/items',
        name: 'Items'
      },
      {
        href: '/item-sets',
        name: 'Item Sets'
      },
      {
        href: '/quests',
        name: 'Quests'
      },
      {
        href: '/npcs',
        name: 'NPCs'
      },
      {
        href: '/zones',
        name: 'Zones'
      },
      {
        href: '/spells',
        name: 'Spells'
      }, {
        href: '/objects',
        name: 'Objects'
      },
      {
        href: '/factions',
        name: 'Factions'
      },
      {
        href: '/races',
        name: 'Races'
      },
      {
        href: '/classes',
        name: 'Classes'
      },
      {
        href: '/events',
        name: 'Events'
      }
    ],
    expanded: true
  },
  {
    title: 'Tools',
    links: [
      {
        href: '/tools/tooltip',
        name: 'Tooltip'
      },
      {
        href: '/tools/gearplanner',
        name: 'Gear Planner'
      },
      {
        href: '/tools/atlas',
        name: 'Atlas'
      }
    ],
    expanded: true
  }
]

export const wotlkNavItems = [
  {
    title: 'Database',
    links: [
      {
        href: '/items',
        name: 'Items'
      },
      {
        href: '/item-sets',
        name: 'Item Sets'
      },
      {
        href: '/quests',
        name: 'Quests'
      },
      {
        href: '/npcs',
        name: 'NPCs'
      },
      {
        href: '/zones',
        name: 'Zones'
      },
      {
        href: '/spells',
        name: 'Spells'
      }, {
        href: '/objects',
        name: 'Objects'
      },
      {
        href: '/factions',
        name: 'Factions'
      },
      {
        href: '/achievements',
        name: 'Achievements'
      },
      {
        href: '/titles',
        name: 'Titles'
      },
      {
        href: '/icons',
        name: 'Icons'
      },
      {
        href: '/skills',
        name: 'Skills'
      },
      {
        href: '/currencies',
        name: 'Currencies'
      },
      {
        href: '/enchantments',
        name: 'Enchantments'
      },
      {
        href: '/races',
        name: 'Races'
      },
      {
        href: '/classes',
        name: 'Classes'
      },
      {
        href: '/pets',
        name: 'Pets'
      },
      {
        href: '/events',
        name: 'Events'
      }
    ],
    expanded: true
  },
  {
    title: 'Tools',
    links: [
      {
        href: '/tools/tooltip',
        name: 'Tooltip'
      }

    ],
    expanded: true
  }
]
