<template>
  <div
    id="background"
    class="flex justify-center"
  >
    <div class="block lg:hidden">
      <MobileNavbar />
    </div>
    <div class="lg:block hidden">
      <StaticSideNav />
    </div>
    <main
      id="main"
      class="focus:outline-none"
    >
      <TooltipContainer class="bg-picture bg-fixed max-w-7xl object-cover bg-no-repeat">
        <SearchAndFilterSection />
        <div class="min-h-screen w-full lg:flex items-start">
          <div class="relative w-full lg:min-w-[800px] p-2 sm:p-8">
            <hearth-loading v-if="showLoading" />
            <slot v-else />
            <div id="video" />
          </div>
        </div>
      </TooltipContainer>
    </main>
  </div>
</template>

<script >
import MobileNavbar from '@/components/MobileNavbar.vue'
import StaticSideNav from '@/components/StaticSideNav.vue'
import TooltipContainer from '@/components/TooltipContainer.vue'
import HearthLoading from '../../../../lib/src/components/HearthLoading.vue'
import SearchAndFilterSection from '../../../../lib/src/components/SearchAndFilterSection.vue'

export default {
  name: 'TemplateSinglePage',
  components: {
    TooltipContainer,
    StaticSideNav,
    MobileNavbar,
    SearchAndFilterSection,
    HearthLoading
  },
  props: {
    showLoading: {
      type: Boolean,
      default: () => false
    }
  },
  mounted () {
    this.$store.commit('clearTooltip')
  }
}
</script>
<style scoped lang="scss">
.bg-gradient-radial {
  background: radial-gradient(farthest-corner at 100% 100%, #2c2522, #211f20 100%);
}

.bg-picture {
  background: url('../assets/img/backgrounds/3.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}
#background {
  grid-template-columns: 1fr fit-content(56rem) 1fr;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
  }
}
#main {
  grid-column: 2 / 3;
}
</style>
