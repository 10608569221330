<template>
  <div class="list-view-table flex flex-col items-center w-full ">
    <div class="w-full overflow-auto">
      <div v-if="showFilters || (windowWidth > 767)">
        <ListViewFilters
          :type="singlePagePrefix"
          :additional-filters="additionalFilters"
          :expansion="expansion"
        />
      </div>
      <table
        id="list"
        class="w-full shadow-lg max-h-96 bg-bg-200"
      >
        <thead class="text-table-text bg-bg-900">
          <tr>
            <th
              v-for="(col, i) in columns"
              :key="`col-${i}`"
              class="sm:table-cell hidden py-4 pl-4 text-base text-left transition cursor-pointer first:table-cell text-footer-text hover:text-primary"
              @click="col.sortable ? changeSortColumn(`${col.model ? col.model + '.' : ''}${col.field}`) : null"
            >
              {{ col.label }}
              <img
                v-if="col.sortable && (`${col.model ? col.model + '.' : ''}${col.field}`) === sortField"
                src="@/assets/img/icons/arrow_dropdown.svg"
                alt="Dropdown Arrow"
                class="inline dropdown-icon"
                :class="{ reverse: !sortAscending }"
              >
            </th>
          </tr>
        </thead>
        <tbody class="block overflow-auto table-height">
          <!-- Skeleton Loaders -->
          <template v-if="loading">
            <tr
              v-for="i in 10"
              :key="`skeleton-${i}`"
            >
              <td
                :colspan="columns.length"
                class="h-20"
              >
                <div class="w-full skeleton-loader h-1/2" />
              </td>
            </tr>
          </template>

          <!-- Actual Content -->
          <template
            v-for="(row, i) in rows"
            v-else
          >
            <tr
              :key="`row-${i}`"
              class="transition-all border-b-2 group border-bg-400 bg-gradient-to-r from-table-1 to-table-2 odd:bg-gradient-to-r odd:from-table-3 odd:to-table-4 hover:from-table-1 hover:to-table-2"
            >
              <td
                v-for="(col, j) in columns"
                :key="`row-${i}-col-${j}`"
                v-tooltip="{ data: col.icon || col.primary ? row : null, type: singlePagePrefix }"
                class="text-base text-left first:table-cell md:table-cell text-table-text"
              >
                <router-link
                  :to="getRowUrl(row)"
                  class="relative flex items-center px-3 py-2"
                >
                  <!-- Icon -->
                  <img
                    v-if="col.icon"
                    class="inline float-left h-10 mr-3 rounded-sm"
                    :src="`https://cdn.wowclassicdb.com/icons/${row.icon?.name || row.icon || row.name}.jpg`"
                    :alt="getRowField(row, col)"
                  >
                  <img
                    v-if="col.classIcon"
                    class="inline float-left h-10 mr-3 rounded-sm"
                    :src="`${baseUrl}/img/Class_${row.name.replace(/\s/g, '')}.svg`"
                    :alt="getRowField(row, col)"
                  >
                  <!-- Text -->
                  <img
                    v-if="col.thumbnail"
                    v-lazy="`https://cdn.wowclassicdb.com/npcs/${row.id}.png`"
                    :alt="getRowField(row, col)"
                    class="object-contain w-16 h-16 pr-4"
                  >
                  <p
                    v-if="col.primary"
                    :class="hasCustomColor(row) ? 'font-normal' : 'flex-1 w-full font-bold text-table-text-light'"
                    :style="hasCustomColor(row) ? {'color': '#' + hasCustomColor(row) } : {}"
                  >
                    {{ getRowField(row, col) }}
                  </p>
                  <p
                    v-else
                    class="flex-1 w-full"
                  >
                    <span class="flex-1 w-full transition-all text-table-text group-hover:text-primary">
                      {{ getRowField(row, col) }}
                    </span>
                  </p>
                </router-link>
              </td>
            </tr>
          </template>

          <!-- No Results -->
          <template v-if="!loading && !rows.length">
            <tr>
              <td
                :colspan="columns.length"
                class="py-6"
              >
                <p class="text-md text-center">
                  We couldn't find anything containing your query. Try again.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <ListViewPagination
        v-if="!loading"
        class="py-4"
        :current-page="currentPage"
        :total-pages="totalPages"
      />
    </div>
  </div>
</template>

<script>
import { depluralize } from '../../../../lib/src/util';
import tooltipMixin from '../../mixins/tooltip-mixin';
import ListViewFilters from './ListViewFilters';
import ListViewPagination from './ListViewPagination';
export default {
  name: 'ListViewTable',
  components: { ListViewFilters, ListViewPagination },
  mixins: [tooltipMixin],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    apiPath: {
      type: String,
      required: true
    },
    singlePagePrefix: {
      type: String,
      required: true
    },
    showFilters: {
      type: Boolean,
      required: true
    },
    expansion: {
      type: String,
      default: 'vanilla'
    }
  },
  data () {
    return {
      additionalFilters: [],
      rows: [],
      loading: false,
      totalPages: 0,
      windowWidth: 0,
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    sortField () {
      return this.$store.state.listView.sortField
    },
    sortAscending () {
      return this.$store.state.listView.sortAscending
    },
    currentPage () {
      return this.$route.query.p ? parseInt(this.$route.query.p) : 1
    },
    baseTypes () {
      return Array.isArray(this.$store.getters.baseTypes) ? this.$store.getters.baseTypes : []
    }
  },
  watch: {
    '$store.state.listView.filters' () {
      this.fetchRows()
    }
  },
  created () {
    this.fetchRows()
  },
  mounted () {
    if (typeof window !== 'undefined') {
      this.windowWidth = window.innerWidth
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    }
    if (!this.baseTypes.length && Object.keys(this.baseTypes).length === 0) {
      this.$store.dispatch('fetchTypes', this.depluralize(this.$route.name))
    }
  },
  methods: {
    getRowField (row, col) {
      // Format function
      if (col.format) return col.format(row) || '-'

      // Otherwise take field value
      const fields = col.field.split('.')
      let value = row
      for (const field of fields) {
        value = value[field]
        if (!value) return '-'
      }
      return value
    },
    getRowUrl (row) {
      return `/${this.singlePagePrefix}/${row.id}`
    },
    hasCustomColor (row) {
      return row.itemQuality?.colorCode || row?.items?.[0]?.itemQuality?.colorCode
    },
    async changeSortColumn (col) {
      // Cycle through asc/desc/reset if same col, otherwise switch
      if (col === this.sortField) {
        if (this.sortAscending) {
          await this.$store.commit('setListViewSortAscending', false)
        } else {
          await Promise.all([
            this.$store.commit('setListViewSortField', 'id'),
            this.$store.commit('setListViewSortAscending', true)
          ])
        }
      } else {
        await Promise.all([
          this.$store.commit('setListViewSortField', col),
          this.$store.commit('setListViewSortAscending', true)
        ])
      }
      await this.fetchRows()
    },
    async fetchRows () {
      if (this.loading) return
      this.loading = true

      // Construct request
      const filters = this.$store.getters.listViewQueryParams
      const response = await this.$http.get(process.env.VUE_APP_API_URL + this.apiPath, {
        params: {
          p: this.currentPage,
          sort_by: this.sortField,
          sort_order: this.sortAscending ? 'ASC' : 'DESC',
          filter: filters
        }
      }).catch(() => {
        this.$router.push('/not-found')
      })
      this.additionalFilters = response.data.filters
      this.rows = response.data.items
      this.totalPages = response.data.totalPages
      this.loading = false
    },
    depluralize
  }
}
</script>

<style scoped lang="scss">
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

td,
th {
  width: 20rem;
}

td + td,
th + th {
  width: auto;
}

.table-height {
  max-height: 800px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

#list {
  /* Hide all columns but the first one,640 is the SM breakpoint for tailwind */
  @media (max-width: 640px) {
    th:not(:first-child),
    td:not(:first-child) {
      display: none;
    }
  }
}
</style>
