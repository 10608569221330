<template>
  <div
    class="flex justify-center space-x-2"
  >
    <button
      v-for="(p, index) in pageEntries"
      :key="index"
      class="flex items-center justify-center w-8 h-8 transition-all transform rounded-md hover:bg-primary hover:scale-125 hover:text-black "
      :class="{ 'pointer-events-none bg-transparent text-white': p === '...', 'bg-primary ring-2 ring-primary font-bold text-black': p === currentPage, 'bg-bg-500 text-white': p !== currentPage && p !== '...' }"
      @click="relatedTable ? setPageForRelatedTable(p) : setCurrentPage(p)"
    >
      {{ p }}
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { convertCamelCaseToKebabCase } from '../../../lib/src/util'
export default {
  name: 'Pagination',
  props: {
    totalEntries: Number,
    entriesPerPage: Number,
    currentPage: Number,
    relatedTable: { type: Boolean, required: false, default: false },
    maxRange: { // Maximum range which will be displayed around the current page before doing a "..."
      type: Number,
      default: 3
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.loading
    },
    pageCount () {
      return Math.ceil(this.totalEntries / this.entriesPerPage)
    },
    pageEntries () {
      // Generate [1, ..., totalEntries] and only show first and last page, and everything in the range around the current page
      const rawEntries = Array.from({ length: this.pageCount }, (_, i) => i + 1)
        .filter(p => p === 1 || p === this.pageCount || (p <= this.currentPage + this.maxRange && p >= this.currentPage - this.maxRange))

      // Insert "..." between page jumps
      const pageEntries = []
      let lastPage = 0
      for (const p of rawEntries) {
        if ((p - lastPage) > 1) pageEntries.push('...')
        pageEntries.push(p)
        lastPage = p
      }
      return pageEntries
    }
  },
  methods: {
    // Use mapActions to map this.updateRelatedTableData to this.$store.dispatch('updateRelatedTableData')
    ...mapActions(['updateRelatedTableData']),
    ...mapMutations(['setLoading', 'clearLoading']),
    async setCurrentPage (page) {
      if (this.currentPage === page) return
      await this.$store.commit('setPageInfo', { currentPage: page })
      const query = { ...this.$route.query, p: page }
      await this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
    },
    setPageForRelatedTable (pageNumber) {
      if (this.currentPage === pageNumber) return
      // Update the current page in the store
      this.$store.commit('setPageInfo', { currentPage: pageNumber })
      // Dispatch an action to update the related data in the store
      // If the route is /search, do not perform the update
      if (this.$store.state.route.name === 'Search') return
      this.updateRelatedTableData({
        // The name of the current route, e.g. 'Item', 'Spell'
        type: this.$store.state.route.name,
        id: this.$store.state.route.params.id,
        // The name of the function in the API service to call to update the related table data
        apiFunctionName: convertCamelCaseToKebabCase(this.$store.state.selectedRelatedTableIndex),
        // The name of the related table on the actual item, spell etc
        tableName: this.$store.state.selectedRelatedTableIndex,
        newPage: pageNumber
      })
    }
  }
}
</script>
