function moveTooltip (e) {
  const tooltip = document.getElementById('tooltip')
  if (tooltip) {
    try {
      tooltip.style.left = e.clientX + 100 + 'px'
      tooltip.style.top = e.clientY + 'px'
      tooltip.style.position = 'fixed'
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  update (el, binding, vnode) {
    el.__mousemove__ = e => {
      try {
        vnode.context.setTooltip(binding.value)
        moveTooltip(e)
      } catch (e) {}
    }
    el.__mouseleave__ = _ => vnode.context.clearTooltip()

    el.addEventListener('mousemove', el.__mousemove__, false)
    el.addEventListener('mouseleave', el.__mouseleave__)
  },

  inserted: (el, binding, vnode) => {
    el.__mousemove__ = e => {
      try {
        vnode.context.setTooltip(binding.value)
        moveTooltip(e)
      } catch (e) {}
    }
    el.__mouseleave__ = _ => vnode.context.clearTooltip()

    el.addEventListener('mousemove', el.__mousemove__, false)
    el.addEventListener('mouseleave', el.__mouseleave__)
  },

  unbind (el) {
    el.removeEventListener('mousemove', el.__mousemove__)
    el.removeEventListener('mouseenter', el.__mouseenter__)
    el.removeEventListener('mouseleave', el.__mouseleave__)
  }
}
