import { ItemQuality } from '../models/Item'

export const itemQualities: ItemQuality[] = [
  { id: 0, name: 'Poor', colorCode: '9d9d9d', colorName: 'Grey' },
  { id: 1, name: 'Common', colorCode: 'ffffff', colorName: 'White' },
  { id: 2, name: 'Uncommon', colorCode: '1eff00', colorName: 'Green' },
  { id: 3, name: 'Rare', colorCode: '0070dd', colorName: 'Blue' },
  { id: 4, name: 'Epic', colorCode: 'a335ee', colorName: 'Purple' },
  { id: 5, name: 'Legendary', colorCode: 'ff8000', colorName: 'Orange' },
  { id: 6, name: 'Artifact', colorCode: 'e6cc80', colorName: 'Red' }
]
