
import QuickFacts, { QuickFactsProps } from '@/components/QuickFacts.vue'
import Vue, { PropType } from 'vue'
import { GameObject } from '../../../../lib/src/models/GameObject'
import { getLocationsFromGameObject } from '../../../../lib/src/util'
export default Vue.extend({
  components: {
    QuickFacts
  },
  props: {
    object: {
      type: Object as PropType<GameObject>,
      required: true
    }
  },
  computed: {
    quickFactsData (): QuickFactsProps {
      return {
        type: this.object.gameObjectType.name,
        key: this.object.key[0],
        location: getLocationsFromGameObject(this.object)
      } as QuickFactsProps
    }
  }
})
