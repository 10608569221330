/**
 * Convert time from milliseconds to a human readable format
 */
module.exports = function (time) {
  // Convert time from milliseconds to a human readable format
  const seconds = Math.floor(time / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)
  if (years > 0) {
    if (years === 1) {
      return `${years} year`
    } else {
      return `${years} years`
    }
  }
  if (months > 0) {
    if (months === 1) {
      return `${months} month`
    } else {
      return `${months} months`
    }
  }
  if (days > 0) {
    if (days === 1) {
      return `${days} day`
    } else {
      return `${days} days`
    }
  }
  if (hours > 0) {
    if (hours === 1) {
      return `${hours} hour`
    } else {
      return `${hours} hours`
    }
  }
  if (minutes > 0) {
    if (minutes === 1) {
      return `${minutes} minute`
    } else {
      return `${minutes} minutes`
    }
  }
  if (seconds > 0) {
    if (seconds === 1) {
      return `${seconds} second`
    } else {
      return `${seconds} seconds`
    }
  }
  return null
}
