<template>
  <TemplateSinglePage :show-loading="!spell">
    <div
      v-if="spell"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <div class="object-cover w-16 h-16 p-0 mb-5 mr-5 text-base">
          <img
            :src="`https://cdn.wowclassicdb.com/icons/${createdItem.icon ? createdItem.icon.name : spell.icon.name}.jpg`"
            :alt="spell.name"
            class="object-cover w-16 h-16 p-0 m-0 text-black border border-solid border-bg-900"
          >
        </div>
        <h1 class="mb-5 font-sans text-3xl font-bold text-primary">
          {{ spell.name }}
        </h1>
        <div class="flex flex-col w-full md:justify-between md:flex-row">
          <div
            class="p-3.5 m-0 md:max-w-md text-base rounded-sm bg-bg-900 text-white "
          >
            <div
              v-if="tooltip"
              class="w-full"
              v-html="tooltip.tooltip"
            />
          </div>
        </div>
        <section
          v-if="spell.auraDescription"
          id="buff"
        >
          <div class="font-bold my-3 pt-5 w-full text-2xl text-primary">
            Buff Details
          </div>
          <div class="bg-bg-900 max-w-sm p-3.5 text-white">
            <h1 class="text-tooltip-misc mr-5 text-lg">
              {{ spell.name }}
            </h1>
            <p>
              {{ spell.auraDescription }}
            </p>
            <p
              v-if="spell.durationBase"
              class="text-tooltip-misc"
            >
              {{ spell.durationBase }} remaining
            </p>
          </div>
        </section>
      </div>
      <div class="font-bold mt-3 pt-5 w-full text-2xl text-primary">
        Spell Details
      </div>
      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />
      <div v-if="spell.reagents.length || spell.totems.length">
        <h3
          class="font-bold  w-full text-2xl text-white border-bg-300"
        >
          Spell Tools & Reagents
        </h3>
        <ul
          class="mb-10 flex flex-row mt-5 flex-wrap w-full gap-5 space-y-5 text-lg md:space-y-0 md:flex-row md:justify-start"
        >
          <QuickFactsCard
            v-for="(item, i) in spell.reagents"
            :key="i"
            :header="'Reagent ' + (i + 1)"
          >
            <router-link
              :to="`/item/${item.id}`"
              class="flex pt-3 space-x-2 items-center"
            >
              <img
                class="w-7"
                :src="`https://cdn.wowclassicdb.com/icons/${item.icon.name}.jpg`"
                :alt="item.name"
              >
              <span
                :class="'q-' + item.itemQuality.name.toLowerCase()"
              >{{ item.name }} ({{ item.SpellReagents.amount || 1 }})</span>
            </router-link>
          </QuickFactsCard>
          <QuickFactsCard
            v-for="(item, i) in spell.totems"
            :key="i"
            :header="'Tool ' + (i + 1)"
          >
            <router-link
              :to="`/item/${item.id}`"
              class="flex pt-3 space-x-2 items-center"
            >
              <img
                class="w-7"
                :src="`https://cdn.wowclassicdb.com/icons/${item.icon.name}.jpg`"
                :alt="item.name"
              >
              <span
                :class="'q-' + item.itemQuality.name.toLowerCase()"
              >{{ item.name }} ({{ item.SpellTotems.amount || 1 }})</span>
            </router-link>
          </QuickFactsCard>
        </ul>
      </div>
      <div
        class="flex flex-col justify-between font-sans text-base md:flex-row md:flex-wrap "
      >
        <div class="py-2.5 px-5 m-0 w-under-half text-base bg-bg-900 ">
          <div
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              Cost
            </div>
            <div
              class="p-0 m-0 text-lg text-white"
            >
              {{ spell.resourceCost }}{{ spell.resourceCostIsPercentage ? '% of Base' : '' }} {{ spell.resourceType }}
            </div>
          </div>
          <div
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              Range
            </div>
            <div
              v-if="spell.spellRange"
              class="p-0 m-0 text-lg text-white "
            >
              {{ spell.spellRange.name }} - {{ spell.spellRange.minRange }} yards - {{ spell.spellRange.maxRange }} yards
            </div>
          </div>
          <div
            v-if="spell.spellCastTime"
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              Cast Time
            </div>
            <div
              v-if="spell.spellCastTime.time && spell.spellCastTime.time > 0"
              class="p-0 m-0 text-lg text-white "
            >
              {{ spell.spellCastTime.time / 1000 }} second(s)
            </div>
            <div
              v-else
              class="p-0 m-0 text-lg text-white "
            >
              Instant
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              Cooldown
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              <p v-if="spell.cooldown / 1000 <= 60">
                {{ spell.cooldown / 1000 }} second(s)
              </p>

              <p
                v-else-if="
                  spell.cooldown / 1000 > 60 && spell.cooldown / 1000 <= 3599
                "
              >
                {{ spell.cooldown / 1000 / 60 }} minute(s)
              </p>
              <p v-else>
                {{ spell.cooldown / 1000 / 60 / 60 }} hour(s)
              </p>
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              Duration
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.durationBase ? spell.durationBase : 'n/a' }}
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              GCD Time
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.gcdTime ? spell.gcdTime : 'n/a' }}
            </div>
          </div>
        </div>
        <div class="py-2.5 px-5 m-0 w-under-half text-base bg-bg-900 ">
          <div
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              Channeled
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.chanelled ? 'Yes' : 'No' }}
            </div>
          </div>
          <div
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              School
            </div>
            <div
              v-if="spell.spellSchool"
              class="p-0 m-0 text-lg text-white "
            >
              {{ spell.spellSchool.name }}
            </div>
          </div>
          <div
            class="flex justify-between py-1.5 px-0 m-0 text-black border-b border-solid border-bg-300 "
          >
            <div class="p-0 m-0 text-lg text-white ">
              Mechanic
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              n/a
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              Resource Type
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.resourceType }}
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              Dispel Type
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.dispelType }}
            </div>
          </div>
          <div class="flex justify-between py-1.5 px-0 m-0 text-black ">
            <div class="p-0 m-0 text-lg text-white ">
              GCD Category
            </div>
            <div class="p-0 m-0 text-lg text-white ">
              {{ spell.gcdCategory ? spell.gcdCategory : 'n/a' }}
            </div>
          </div>
        </div>
        <div
          v-if="spell.SpellEffects.length"
          class="py-2.5 px-5 mx-0 md:mt-5 mb-0 w-full text-base bg-bg-900 "
        >
          <div
            v-for="(effect, i) in spell.SpellEffects"
            :key="i"
            class="flex justify-between py-1.5 px-0 m-0 text-black"
            :class="{
              'border-b border-solid border-bg-300': i !== spell.SpellEffects.length - 1,
            }"
          >
            <div class="p-0 m-0 text-lg text-white ">
              Effect #{{ i + 1 }} - {{ effect.effect.name ? effect.effect.name : null }} {{ effect.auraEffect && effect.auraEffect.name ? effect.auraEffect.name : null }}
            </div>
            <div>
              <div
                class="p-0 m-0 flex flex-row text-lg text-white "
              >
                <div
                  v-if="effect.min && !effect.item"
                >
                  Value: {{ effect.min }}
                </div>
              </div>

              <div
                v-if="effect.spellTrigger"
              >
                <img
                  v-if="effect.spellTrigger.icon"
                  v-tooltip="{data: effect.spellTrigger, type: 'spell'}"
                  class="inline w-8 h-auto mr-3 border-2 border-bg-200 -left-16"
                  :src="`https://cdn.wowclassicdb.com/icons/${effect.spellTrigger.icon.name}.jpg`"
                  :alt="effect.spellTrigger.name"
                >
                <router-link
                  v-tooltip="{data: effect.spellTrigger, type: 'spell'}"
                  class="inline-flex"
                  :to="`/spell/${effect.spellTrigger.id}`"
                >
                  {{ effect.spellTrigger.name }}
                </router-link>
              </div>
              <div
                v-if="effect.item"
              >
                <img
                  v-if="effect.item.icon"
                  v-tooltip="{data: effect.item, type: 'item'}"
                  class="inline w-8 h-auto mr-3 border-2 border-bg-200 -left-16"
                  :src="`https://cdn.wowclassicdb.com/icons/${effect.item.icon.name}.jpg`"
                  :alt="effect.item.name"
                >
                <router-link
                  v-tooltip="{data: effect.item, type: 'item'}"
                  class="inline-flex"
                  :class="'q-' + effect.item.itemQuality.name.toLowerCase()"
                  :to="`/item/${effect.item.id}`"
                >
                  {{ effect.item.name }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-6 ">
        <RelatedTableFilterButtonBase :filter-options="filterOptions" />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import QuickFactsCard from '@/components/QuickFactsCard.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'
export default {
  name: 'Spell',
  components: {
    TemplateSinglePage,
    QuickFacts,
    RelatedTableFilterButtonBase,
    Breadcrumbs,
    QuickFactsCard
  },
  mixins: [tooltipMixin],
  metaInfo () {
    const imageUrl = `https://cdn.wowclassicdb.com/icons/${this.createdItem && this.createdItem?.icon ? this.createdItem?.icon?.name : this.spell?.icon?.name}.jpg`

    return {
      title: this.spell?.name,
      image: imageUrl,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: this.spell?.name },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` },
        { property: 'og:description', vmid: 'og:description', content: this.metaDescription },
        { property: 'description', vmid: 'description', content: this.metaDescription },
        { property: 'og:image', vmid: 'og:image', content: imageUrl }
      ]
    }
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    metaDescription () {
      return `View Spell ${this.spell?.name} from The Burning Crusade Classic.`
    },
    quickFactsData () {
      return {
        icon: this.spell.icon?.name,
        requiredSkillValue: this.spell.requiredSkillValue,
        requiresObject: this.spell.requiresObject,
        spellSkill: this.spell.skills && this.spell.skills.length ? this.spell.skills[0] : null,
        race: this.spell?.race?.name
      }
    },
    createdItem () {
      let item = {}
      if (this.spell?.SpellEffects && this.spell?.SpellEffects.length > 0) {
        this.spell.SpellEffects.forEach((effect) => {
          if (effect.item) { item = effect.item }
        }
        )
      }
      return item
    },
    spell () {
      return this.$store.state.spells[this.$route.params.id]
    },

    tooltip () {
      return this.$store.state.tooltips.spell[this.$route.params.id]
    },
    filterOptions () {
      return [
        setRelatedTable(this.spell?.races, 'races', '', RelatedTableFilterOption.races),
        setRelatedTable(this.spell?.classes, 'classes', '', RelatedTableFilterOption.classes),
        setRelatedTable(this.spell?.castedBy, 'castedBy', '', RelatedTableFilterOption.castedBy),
        setRelatedTable(this.spell?.taughtBy, 'taughtBy', '', RelatedTableFilterOption.taughtBy),
        setRelatedTable(this.spell?.ranks, 'ranks', '', RelatedTableFilterOption.ranks),
        setRelatedTable(this.spell?.usedByItem, 'usedByItem', '', RelatedTableFilterOption.usedByItem),
        setRelatedTable(this.spell?.usedBy, 'usedBy', '', RelatedTableFilterOption.usedBy),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    }
  },
  serverPrefetch () {
    // Server side fetching
    return this.fetchSpell()
  },
  mounted () {
    // Client side fetching
    if (!this.spell) this.fetchSpell()
  },
  methods: {
    fetchSpell () {
      const page = this.$store.state.pageInfo?.currentPage
      return Promise.all([
        this.$store.dispatch('fetchSpell', { id: this.$route.params.id, page: page }),
        this.$store.dispatch('fetchTooltip', {
          id: this.$route.params.id,
          type: 'spell'
        })
      ])
    }
  }
}
</script>

<style lang="postcss" scoped>
.w-under-half {
  @media only screen and (min-width: 768px) {
    width: calc(50% - 10px);
  }
}
</style>
