
import QuickFactsCard from '@/components/QuickFactsCard.vue';
import Vue, { PropType } from 'vue';
import Money from '../../../../lib/src/components/Money.vue';
import { NPC } from '../../../../lib/src/models/NPC';
import convertDuration from '../../../../lib/src/util/convert-duration';

export interface QuickFactsProps {
  icon?: string;
  type?: string;
  slot?: string;
  race?: string;
  side?: string;
  expansion?: string;
  faction?: {
    name: string;
    side: string;
    expansion: string;
    id: number;
  };
  buyPrice?: number;
  sellPrice?: number;
  phase?: number
  source?: string;
  droppedGold?: number;
  minHealth?: number;
  maxHealth?: number;
  healthPoints?: number;
  location?: string;
  startsQuestNpc?: NPC;
  endsQuestNpc?: NPC;
  startsQuestGameObject?: any;
  requiresObject?: string;
  endsQuestGameObject?: any;
  requiredLevel?: string;
  costToTrain?: number;
  requiredDisenchantingSkill?: number;
  recLevel?: string;
  mana?: number;
  duration: number;
  interval: number;
  startTime: number;
  endTime: number;
}

export default Vue.extend({
  components: {
    Money,
    QuickFactsCard
  },
  props: {
    data: {
      type: Object as PropType<QuickFactsProps>,
      required: true
    }
  },
  methods: {
    convertDuration
  }
})
