<template>
  <div id="home">
    <section
      class="home-section relative p-0 m-0 font-sans text-base text-black"
    >
      <img
        class="inset-0 h-full object-cover brightness-50 absolute z-0 w-full max-h-full"
        :src="backgroundImage.url"
      >
      <div
        class="home-section relative flex items-center justify-center mx-auto text-base min-h-[775px]"
      >
        <div class="w-full text-center mt-16 mx-4">
          <img
            src="@/assets/img/title_img.png"
            alt=""
            class="hue-rotate-60 absolute z-0 p-0 m-0 mx-auto transform -translate-x-1/2 -translate-y-1/2 border-0 top-72 left-1/2 right-1/2 "
          >

          <HomeSearchBar
            id="search-bar"
            class="z-50"
          />
          <div class="flex h-18 justify-center mx-auto max-w-6xl z-50">
            <ul class="flex-wrap justify-center text-base   hidden p-0 space-x-2 sm:flex ">
              <client-only placeholder="Loading...">
                <Dropdown
                  v-for="cat in categories"
                  :key="cat.key"
                  :name="cat.label"
                  :dropdown-items="types[cat.key]"
                />
              </client-only>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section
      class="home-section px-0 pt-20 m-0 font-sans text-base text-black border-t border-solid bg-bg-700 border-bg-200"
    >
      <!--// Todo - Componentize some of this-->
      <ul
        id="news"
        class="flex flex-row flex-wrap justify-center gap-10 p-0 my-0 text-base align-middle transform scale-90 -translate-y-12 lg:mt-0 lg:space-y-0"
      >
        <li
          v-for="(post, i) in newsFormatted"
          :key="`news-${i}`"
          class="relative z-10 px-6 mx-4 outline-none cursor-pointer lg:max-w-[24rem] lg:mt-0 pt-44"
        >
          <a :href="post.link">
            <img
              :src="post.enclosure.url"
              :alt="post.title + ' Thumbnail'"
              class="absolute top-0 left-0 z-0 object-cover w-full max-w-full p-0 m-0 text-black border-0 h-80 rounded-t-2xl fade-bottom"
            >
            <span
              class="flex justify-center z-20 items-center mb-3.5 w-[104px] h-7 text-base text-red-50 rounded-xl border border-red-50 border-solid opacity-50 "
            >{{ formatDate(post.pubDate) }}</span>
            <!--Todo - Need to create a better solution than this for converting the HTML entities in the post titles-->
            <span
              class="relative block p-0 line-clamp-2 mx-0 mt-0 mb-2.5 z-20 text-3xl font-bold text-primary"
              v-html="post.title"
            />
            <span class="p-0 m-0 text-lg line-clamp-5 font-sans leading-[30px] text-white font-bold">
              {{ post.contentSnippet }}
            </span>
          </a>
        </li>
      </ul>
    </section>
    <TooltipContainer />
  </div>
</template>

<script>
import '@/assets/img/og.png'
import Dropdown from '@/components/Dropdown'
import HomeSearchBar from '@/components/HomeSearchBar'
import TooltipContainer from '@/components/TooltipContainer.vue'
import api from '@/store/api-client'
import ClientOnly from 'vue-client-only'
export default {
  name: 'Home',
  components: {
    HomeSearchBar,
    ClientOnly,
    Dropdown,
    TooltipContainer
  },

  data () {
    return {
      slideShow: null,
      imageIndex: 0,
      searchInput: '',
      searchSuggestions: [],
      currentImage: {},
      types: {
        items: [],
        itemSets: [],
        quests: [],
        npcs: [],
        zones: [],
        spells: [],
        objects: [],
        factions: []
      },
      news: [],
      backgroundImage:
        {
          url: require('@/assets/img/backgrounds/black-temple.jpg'),
          alt: 'onyxia_background'
        },
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    categories () {
      const types = []
      for (const k of Object.keys(this.types)) {
        let label = k.charAt(0).toUpperCase() + k.slice(1)
        if (k === 'itemSets') label = 'Item Sets'
        else if (k === 'npcs') label = 'NPCs'

        types.push({ id: `dropdown-${k}`, key: k, label })
      }
      return types
    },
    newsFormatted () {
      return this.$store.state.news[this.$route.params.id]?.slice(0, 3)
    }
  },
  serverPrefetch () { // Server side fetching
    this.fetchTypes()
    return this.fetchNews()
  },
  beforeDestroy () {
    clearInterval(this.slideShow)
  },

  async created () {
    this.fetchTypes()
    if (!this.quest) this.fetchNews()
  },

  methods: {
    async getSuggestions ($event) {
      this.searchSuggestions = []
      if ($event.target.value.length < 3) {
        return
      }

      this.searchSuggestions = await api.getSearchSuggestions(
        $event.target.value
      )
    },
    formatDate (isoDate) {
      const d = new Date(isoDate)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d)
      return `${da}/${mo}/${ye}`
    },
    async fetchTypes () {
      this.types.items = await api.getTypes('item')
      this.types.itemSets = await api.getTypes('item-set')
      this.types.quests = await api.getTypes('quest')
      this.types.spells = await api.getTypes('spell')
      this.types.npcs = await api.getTypes('npc')
      this.types.zones = await api.getTypes('zone')
      this.types.objects = await api.getTypes('object')
      this.types.factions = await api.getTypes('faction')
    },
    async fetchNews () {
      return this.$store.dispatch('fetchNews', this.$route.params.id)
    }

  }
}
</script>

<style lang="postcss" scoped>

.fade-bottom {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

</style>
