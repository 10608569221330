export default {
  metaInfo () {
    return this.meta
  },
  computed: {
    meta () {
      return this.$store.getters.meta
    }
  }
}
