<template>
  <article
    v-if="quest"
    class="font-sans flex flex-col justify-center text-left"
  >
    <header class="flex flex-row items-center w-full pb-2.5">
      <h1 class="mb-1 font-sans text-3xl font-bold text-primary">
        {{ quest.name }}
      </h1>
    </header>
    <section>
      <div
        class="flex flex-col-reverse justify-between m-0 text-base font-semibold text-left border-collapse md:mb-0 md:flex-row"
      >
        <div class="mt-5 text-base text-left border-collapse md:mt-0">
          <p
            class="block mt-0 mb-3 text-item-poor"
            v-html="quest.objectives"
          />

          <ul
            v-if="quest.requiredItems.length"
            class="p-2 space-y-2 max-w-max"
          >
            <li
              v-for="(item, i) in quest.requiredItems"
              :key="`req-item-${i}`"
              v-tooltip="{data: item, type: 'item'}"
              class="flex"
            >
              <router-link
                :to="`/item/${item.id}`"
                class="flex space-x-2 items-center"
              >
                <img
                  class="w-7"
                  :src="`https://cdn.wowclassicdb.com/icons/${item.icon.name}.jpg`"
                  :alt="item.name"
                >
                <span>{{ item.QuestRequiredItems.amount > 1 ? `${item.QuestRequiredItems.amount}x ` : '' }}</span>
                <span
                  class="hover:text-primary"
                  :style="{'color': '#' + itemQualities[item.itemQualityId].colorCode }"
                >{{ item.name }}</span>
              </router-link>
            </li>
          </ul>
          <ul
            v-if="quest.requiredNPCs.length"
            class="p-2 space-y-2 max-w-max"
          >
            <li
              v-for="(npc, i) in quest.requiredNPCs"
              :key="`req-npc-${i}`"
              v-tooltip="{data: npc, type: 'npc'}"
              class="flex"
            >
              <router-link

                :to="`/npc/${npc.id}`"
                class="flex space-x-2 items-center"
              >
                <img
                  v-lazy="`https://cdn.wowclassicdb.com/npcs/${npc.id}.png`"
                  :style="{ height: npc.thumbnailHeight }"
                  :alt="npc.name"
                  class="object-contain w-16 h-16"
                >
                <span
                  v-if="npc.QuestRequiredNPCs.amount"
                  class="text-item-poor hover:text-primary"
                >{{ npc.QuestRequiredNPCs.amount }}x {{ npc.name }} slain</span>
                <span
                  v-else
                  class="text-item-poor hover:text-primary"
                >{{ quest.objectiveText1 }}</span>
              </router-link>
            </li>
          </ul>
          <div class="mt-4 text-item-poor">
            <p
              class="leading-2"
              v-html="quest.description"
            />
          </div>
          <quick-facts
            :data="quickFactsData"
          />
          <hr
            v-if="quest.locationId || getLocationsFromNpcsAndObjects.length"
            class="h-px my-5 font-semibold bg-bg-300 opacity-10"
          >
          <div
            v-if="quest.locationId || getLocationsFromNpcsAndObjects.length"
            class="mt-0 mb-5 text-2xl text-white "
          >
            Locations
          </div>
          <div
            v-if="quest.locationId || getLocationsFromNpcsAndObjects.length"
            class="max-w-3xl "
          >
            <WorldMapContainer
              :locations="[quest.location] || getLocationsFromNpcsAndObjects"
              :markers="markers"
            />
          </div>
          <hr
            v-if="quest.progressText"
            class="h-px my-5 font-semibold bg-bg-300 opacity-10"
          >
          <div
            v-if="quest.progressText"

            id="subblock_hv_1"
            class="ml-1 font-semibold"
          >
            <div

              class="items-center block text-2xl text-white bg-no-repeat cursor-pointer w-7 h-7"
            >
              Progress
            </div>
            <div class="text-left ">
              <div
                class="mt-4 text-item-poor"
                v-html="this.quest.progressText"
              />
            </div>
          </div>
          <hr class="h-px my-5 font-semibold bg-bg-300 opacity-10">
          <div
            id="subblock_hv_2"
            class="font-semibold "
          >
            <div
              class="items-center block ml-1 text-2xl bg-no-repeat cursor-pointer w-7 h-7"
            >
              Completion
            </div>
            <div
              class="my-4 text-left text-item-poor"
              v-html="quest.completionText"
            />
          </div>

          <hr
            v-if="quest.rewards.length"
            class="h-px my-5 font-semibold bg-bg-300 opacity-10"
          >
          <div
            v-if="quest.rewards.length"
            class="mt-0 mb-5 text-2xl text-white "
          >
            Rewards
          </div>
          <div v-if="rewardsWithChoice.length > 0">
            <p
              v-if="quest.rewards.length"
              class="mt-0 mb-5 font-semibold text-item-poor"
            >
              You will be able to choose one of these rewards:
            </p>

            <ul class="flex flex-wrap font-semibold ">
              <li
                v-for="(reward, i) in rewardsWithChoice"
                :key="`reward-${i}`"
                v-tooltip="{data: reward, type: 'item'}"
                class="flex items-center mb-5 ml-0 mr-5 text-sm rounded text-item-poor bg-bg-900 "
              >
                <router-link
                  :to="`/item/${reward.id}`"
                  class="flex items-center"
                >
                  <img
                    class="h-full mr-1 rounded-l"
                    :src="`https://cdn.wowclassicdb.com/icons/${reward?.icon.name}.jpg`"
                    :alt="reward.name"
                  >
                  <div class="px-4">
                    <span>
                      {{ reward.QuestItemRewards.amount > 1 ? `${reward.QuestItemRewards.amount}x ` : '' }}
                    </span>
                    <span :style="{'color': '#' + itemQualities[reward.itemQualityId].colorCode }">{{ reward.name }}
                    </span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div v-if="rewardsWithoutChoice.length > 0">
            <p
              v-if="quest.rewards.length"
              class="mt-0 mb-5 font-semibold text-item-poor"
            >
              You will also get these rewards:
            </p>

            <ul class="flex flex-wrap font-semibold ">
              <li
                v-for="(reward, i) in rewardsWithoutChoice"
                :key="`reward-${i}`"
                v-tooltip="{data: reward, type: 'item'}"
                class="flex items-center mb-5 ml-0 mr-5 text-sm rounded text-item-poor bg-bg-900 "
              >
                <router-link
                  :to="`/item/${reward.id}`"
                  class="flex items-center"
                >
                  <img
                    class="h-full mr-1 rounded-l"
                    :src="`https://cdn.wowclassicdb.com/icons/${reward?.icon.name}.jpg`"
                    :alt="reward.name"
                  >
                  <div class="px-4">
                    <span>
                      {{ reward.QuestItemRewards.amount > 1 ? `${reward.QuestItemRewards.amount}x ` : '' }}
                    </span>
                    <span :style="{'color': '#' + itemQualities[reward.itemQualityId].colorCode }">{{ reward.name }}
                    </span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>

          <p
            v-if="quest.rewardMoney"
            class="mb-5 font-semibold text-item-poor"
          >
            You will {{ quest.rewards.length ? 'also ' : '' }}receive: <money :price="quest.rewardMoney" />
            (or <money :price="quest.rewardMoney + quest.rewardMoneyAtMaxLevel" /> at max level)
          </p>
          <hr class="h-px my-5 font-semibold bg-bg-300 opacity-10">
          <div class="mt-0 mb-5 text-2xl text-white ">
            Gains
          </div>
          <p class="mt-0 mb-5 font-semibold text-item-poor">
            Upon completion of this quest you will gain:
          </p>
          <ul class="font-semibold ">
            <li
              v-if="quest.rewardExperience"
              class="relative mb-2 text-item-poor "
            >
              {{ quest.rewardExperience }} experience
            </li>
            <li
              v-for="faction in quest.reputationRewards"
              :key="faction.id"
              class="relative mb-2 text-item-poor "
            >
              {{ faction.QuestReputationRewards.amount }} reputation with <strong>{{ faction.name }}</strong>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="space-y-4 ">
      <div
        v-if="quest.questChain"
        class="qcbox"
      >
        <QuestChain
          v-if="quest.questChain.length"
          :quest-chain="quest.questChain"
          :active-quest="quest.id"
          :width="graphWidth"
          :height="250"
          :text-width="quest.questChain.length > 15 ? 75 : 100"
        />
      </div>
      <div
        v-if="itemDropTable.data && itemDropTable.data.length"
        class="mt-5 mb-2 text-2xl font-semibold text-white "
      >
        Quest Item Drops
      </div>
      <Table
        v-if="itemDropTable.data && itemDropTable.data.length"
        :show-pagination="false"
        :columns="itemDropTable.columns"
        :data="itemDropTable.data"
        :row-accessor="itemDropTable.rowAccessor"
        related-table-prefix="npc"
      />
    </section>
  </article>
</template>

<script>
import WorldMapContainer from '@/components/MapContainer.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import Money from '../../../../lib/src/components/Money.vue'
import QuestChain from '../../../../lib/src/components/QuestChain.vue'
import Table from '../../../../lib/src/components/Table.vue'
import { itemQualities } from '../../../../lib/src/constants/item-constants'
import tooltipMixin from '../../../../lib/src/mixins/tooltip-mixin'
import { parseDollarVariables } from '../../../../lib/src/util'
export default {
  name: 'SingleQuest',
  components: {
    WorldMapContainer,
    Table,
    QuestChain,
    Money,
    QuickFacts
  },
  mixins: [
    tooltipMixin
  ],
  props: {
    quest: {
      default: null,
      type: Object
    }
  },
  data () {
    return {
      itemQualities: itemQualities,
      graphWidth: 0
    }
  },
  computed: {
    getLocationsFromNpcsAndObjects () {
      const locations = []
      for (const npc of this.quest.startedBy) {
        for (const location of npc.locations) {
          if (!locations.find(l => l.id === location.id)) {
            locations.push(location)
          }
        }
      }

      for (const npc of this.quest.endedBy) {
        for (const location of npc.locations) {
          if (!locations.find(l => l.id === location.id)) {
            locations.push(location)
          }
        }
      }

      for (const object of this.quest.startedByGameObject) {
        for (const spawn of object.gameObjectSpawns) {
          if (!locations.find(l => l.id === spawn.locationId)) {
            locations.push(spawn.location)
          }
        }
      }
      for (const object of this.quest.endedByGameObject) {
        for (const spawn of object.gameObjectSpawns) {
          if (!locations.find(l => l.id === spawn.locationId)) {
            locations.push(spawn.location)
          }
        }
      }
      return locations
    },

    itemDropTable () {
      const columns = [
        { label: 'Name', sortable: true },
        { label: 'Quest Item', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Drop Chance', sortable: true }
      ]

      const rowAccessor = (npc) => {
        const level = `${npc.minLevel}${npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`}`
        const dropChance = npc.drops[0].LootTableNPC.dropChance
        const dropChanceFormatted = `${Math.round(dropChance * 10000) / 100}%`

        return [
          { value: npc.name, thumbnail: `https://cdn.wowclassicdb.com/npcs/${npc.id}.png`, thumbnailHeight: '30px' },
          { value: npc.droppedItem.name },
          { value: npc.minLevel, rendered: level },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      }
      if (this.quest.requiredItems.length) {
        const data = [].concat.apply([], this.quest.requiredItems?.map(reqItem => reqItem?.droppedBy?.data.map(dB => {
          return {
            ...dB,
            droppedItem: {
              id: reqItem.id,
              name: reqItem.name
            }
          }
        })))

        return { columns, rowAccessor, data }
      } else {
        return {

        }
      }
    },
    markers () {
      const markers = []
      const mapQuestRelations = (npc) => {
        return {
          id: npc.id,
          label: npc.name,
          x: npc.npcSpawns[0]?.posX,
          y: npc.npcSpawns[0]?.posY,
          location: npc.npcSpawns[0]?.locationId
        }
      }
      const mapQuestObjectRelations = (object) => {
        return {
          id: object.id,
          label: object.name,
          x: object.gameObjectSpawns[0]?.posX,
          y: object.gameObjectSpawns[0]?.posY,
          location: object.gameObjectSpawns[0]?.locationId
        }
      }
      const questGivers = this.quest.startedBy.map(mapQuestRelations)
      const questEnders = this.quest.endedBy.map(mapQuestRelations)
      const questObjectGivers = this.quest.startedByGameObject.map(mapQuestObjectRelations)
      const questObjectEnders = this.quest.endedByGameObject.map(mapQuestObjectRelations)
      for (const questGiver of questGivers) {
        markers.push({
          ...questGiver,
          description: 'Starts Quest',
          type: 'quest_start'
        })
      }
      for (const questEnder of questEnders) {
        markers.push({
          ...questEnder,
          description: 'Ends Quest',
          type: 'quest_end',
          offset: questGivers.find(qG => qG.id === questEnder.id) !== undefined
        })
      }

      for (const questGiver of questObjectGivers) {
        markers.push({
          ...questGiver,
          description: 'Starts Quest',
          type: 'quest_start'
        })
      }
      for (const questEnder of questObjectEnders) {
        markers.push({
          ...questEnder,
          description: 'Ends Quest',
          type: 'quest_end',
          offset: questGivers.find(qG => qG.id === questEnder.id) !== undefined
        })
      }

      // Get spawn points of creatures that drop required items
      for (const item of this.quest.requiredItems) {
        for (const droppedBy of item.droppedBy.data) {
          for (const spawn of droppedBy.npcSpawns.filter(n => n.locationId === this.quest.locationId)) {
            markers.push({
              id: droppedBy.id,
              location: spawn.locationId,
              label: droppedBy.name,
              x: spawn.posX,
              y: spawn.posY,
              description: `Drops ${item.name}`,
              highlight: droppedBy.id === this.$store.state.highlightedId
            })
          }
        }
      }

      for (const npc of this.quest.requiredNPCs) {
        for (const spawn of npc.npcSpawns.filter(n => n.locationId === this.quest.locationId)) {
          markers.push({
            id: npc.id,
            label: npc.name,
            location: spawn.locationId,
            x: spawn.posX,
            y: spawn.posY,
            description: 'Quest Objective',
            highlight: npc.id === this.$store.state.highlightedId
          })
        }
      }
      return markers
    },
    rewardsWithChoice () {
      return this.quest.rewards?.filter(x => x.QuestItemRewards.choice === true)
    },
    rewardsWithoutChoice () {
      return this.quest.rewards?.filter(x => x.QuestItemRewards.choice === false)
    },
    quickFactsData () {
      return {
        recLevel: this.quest.level > 0 ? this.quest.level : this.quest.minLevel,
        type: this.quest.questSort ? `${this.quest.questSort.type} ${this.quest.questSort.name ? '- ' + this.quest.questSort.name : ''} ` : null,
        requiredLevel: this.quest.minLevel,
        startsQuestNpc: this.quest.startedBy[0],
        endsQuestNpc: this.quest.endedBy[0],
        startsQuestGameObject: this.quest.startedByGameObject[0],
        endsQuestGameObject: this.quest.endedByGameObject[0],
        side: this.quest.side?.name,
        race: this.quest.race,
        class: this.quest.class
      }
    }
  },
  mounted () {
    this.render()

    if (typeof window !== 'undefined') window.addEventListener('resize', this.render)
    // If the user is authenticated in the future - replace Adventurer with the authenticated user's username
    this.quest.description = parseDollarVariables(this.quest.description)
    this.quest.objectives = parseDollarVariables(this.quest.objectives)
    this.quest.progressText = parseDollarVariables(this.quest.progressText)
    this.quest.completionText = parseDollarVariables(this.quest.completionText)
  },

  beforeDestroy () {
    if (typeof window !== 'undefined') window.removeEventListener('resize', this.render)
  },
  methods: {
    render () {
      this.graphWidth = this.quest.questChain.length * 70 > 762 ? this.quest.questChain.length * 70 : 762
    }
  }
}
</script>

<style lang="scss" scoped>
div, p {
  white-space: pre-line;
}
</style>
