module.exports = function (models, expansion) {
  const types = []
  const depluralize = (input) => {
    if (input.slice(-1) === 's') {
      return input.slice(0, input.length - 1)
    }
    return input
  }
  // Armor category
  const armor = {
    name: 'Armor',
    meta: 'Armor',
    filter: { table: 'ItemType', id: 4 },
    columns: [
      { field: 'armor' },
      {
        field: 'itemSlot',
        nested: 'name',
        label: 'Slot'
      }
    ],
    children: []
  }
  const armorChildren = [
    { name: 'Head', meta: 'Head Armor', id: 1 },
    { name: 'Neck', meta: 'Necklaces', id: 2 },
    { name: 'Shoulders', meta: 'Shoulder Armor', id: 3 },
    { name: 'Back', meta: 'Cloaks', id: 16 },
    { name: 'Chest', meta: 'Chest Armor', id: 5 },
    { name: 'Shirt', meta: 'Shirts', id: 4 },
    { name: 'Tabard', meta: 'Tabards', id: 19 },
    { name: 'Wrists', meta: 'Bracers', id: 9 },
    { name: 'Hands', meta: 'Hand Armor', id: 10 },
    { name: 'Waist', meta: 'Waist Armor', id: 6 },
    { name: 'Legs', meta: 'Leg Armor', id: 7 },
    { name: 'Feet', meta: 'Boots', id: 8 },
    { name: 'Finger', meta: 'Rings', id: 11 },
    { name: 'Trinket', meta: 'Trinkets', id: 12 },
    { name: 'Relic', meta: 'Relics', id: 28 }
  ]
  for (const ch of armorChildren) {
    armor.children.push({
      name: ch.name,
      meta: ch.meta,
      filter: { table: 'ItemSlot', id: ch.id },
      children: [2, 16, 4, 19, 11, 12, 13, 28].includes(ch.id)
        ? undefined
        : [
          {
            name: 'Cloth',
            meta: `Cloth ${depluralize(ch.name)} Armor`,
            filter: [{ table: 'ItemSubType', id: 54 }]
          },
          {
            name: 'Leather',
            meta: `Leather ${depluralize(ch.name)} Armor`,
            filter: [{ table: 'ItemSubType', id: 55 }]
          },
          {
            name: 'Mail',
            meta: `Mail ${depluralize(ch.name)} Armor`,
            filter: [{ table: 'ItemSubType', id: 56 }]
          },
          {
            name: 'Plate',
            meta: `Plate ${depluralize(ch.name)} Armor`,
            filter: [{ table: 'ItemSubType', id: 57 }]
          }
        ]
    })
  }
  types.push(armor)

  // Weapons category
  const weapons = {
    name: 'Weapons',
    meta: 'Weapons',
    filter: {
      table: 'ItemType',
      id: 2
    },
    columns: [
      {
        label: 'DPS',
        fn: i =>
          (
            ((i.dmg1Min + i.dmg1Max) / 2 + (i.dmg2Min + i.dmg2Max) / 2) /
            (i.weaponSpeed / 1000)
          ).toFixed(2)
      },
      { label: 'Speed', fn: i => (i.weaponSpeed / 1000).toFixed(2) },
      { field: 'dmg1Min', label: 'Min. Dmg' },
      { field: 'dmg1Max', label: 'Max. Dmg' },
      { field: 'itemSubType', nested: 'name', label: 'Type' }
    ],
    children: [
      {
        name: 'One Handed',
        meta: 'One Handed Weapons',
        filter: { table: 'ItemSlot', id: [13, 21] },
        children: [
          { name: 'Daggers', meta: 'Daggers', filter: { table: 'ItemSubType', id: 38 } },
          { name: 'Fist Weapons', meta: 'Fist Weapons', filter: { table: 'ItemSubType', id: 36 } },
          { name: 'One Handed Axes', meta: 'One Handed Axes', filter: { table: 'ItemSubType', id: 23 } },
          {
            name: 'One Handed Maces',
            meta: 'One Handed Maces',
            filter: [{ table: 'ItemSubType', id: 27 }]
          },
          {
            name: 'One Handed Swords',
            meta: 'One Handed Swords',
            filter: [{ table: 'ItemSubType', id: 30 }]
          }
        ]
      },
      {
        name: 'Two Handed',
        meta: 'Two Handed Weapons',
        filter: { table: 'ItemSlot', id: 17 },
        children: [
          { name: 'Polearms', meta: 'Polearms', filter: { table: 'ItemSubType', id: 29 } },
          { name: 'Staves', meta: 'Staves', filter: { table: 'ItemSubType', id: 33 } },
          { name: 'Two Handed Axes', meta: 'Two Handed Axes', filter: { table: 'ItemSubType', id: 24 } },
          {
            name: 'Two Handed Maces',
            meta: 'Two Handed Maces',
            filter: { table: 'ItemSubType', id: 28 }
          },
          {
            name: 'Two Handed Swords',
            meta: 'Two Handed Swords',
            filter: { table: 'ItemSubType', id: 31 }
          }
        ]
      },
      {
        name: 'Off Hand',
        meta: 'Off Hand Items',
        filter: [{ table: 'ItemSlot', id: 22 }],
        columns: [{ field: 'armor' }, { field: 'block' }],
        children: [
          {
            name: 'Shields',
            meta: 'Shields',
            filter: [
              { table: 'ItemSlot', id: 14 }
            ]
          },
          {
            name: 'Off Hands',
            meta: 'Off Hands',
            filter: [
              { table: 'ItemSlot', id: 22 }
            ]
          }
        ]
      },
      {
        name: 'Other Weapons',
        meta: 'Other Weapons',
        filter: { table: 'ItemSubType', id: 43 },
        children: [
          { name: 'Fishing Poles', meta: 'Fishing Poles', filter: { table: 'ItemSubType', id: 43 } },
          { name: 'Miscellaneous', meta: 'Miscellaneous', filter: { table: 'ItemSubType', id: 37 } }
        ]
      }
    ]
  }
  types.push(weapons)

  // Ranged category
  const ranged = {
    name: 'Ranged',
    meta: 'Ranged Items',
    filter: [{ table: 'ItemSubType', id: [26, 66, 67] }],
    columns: [
      {
        label: 'DPS',
        fn: i =>
          (
            ((i.dmg1Min + i.dmg1Max) / 2 + (i.dmg2Min + i.dmg2Max) / 2) /
            (i.weaponSpeed / 1000)
          ).toFixed(2)
      },
      { label: 'Speed', fn: i => (i.weaponSpeed / 1000).toFixed(2) },
      { field: 'dmg1Min', label: 'Min. Dmg' },
      { field: 'dmg1Max', label: 'Max. Dmg' },
      { field: 'itemSubType', nested: 'name', label: 'Type' }
    ],
    children: [
      {
        name: 'Ranged Weapons',
        meta: 'Ranged Weapons',
        filter: { table: 'ItemSubType', id: 26 },
        children: [
          { name: 'Bows', meta: 'Bows', filter: { table: 'ItemSubType', id: 25 } },
          { name: 'Crossbows', meta: 'Crossbows', filter: { table: 'ItemSubType', id: 41 } },
          { name: 'Guns', meta: 'Guns', filter: { table: 'ItemSubType', id: 26 } },
          { name: 'Wands', meta: 'Wands', filter: { table: 'ItemSubType', id: 42 } }
        ]
      },
      {
        name: 'Projectiles',
        meta: 'Projectiles',
        filter: { table: 'ItemType', id: 6 },
        children: [
          { name: 'Arrows', meta: 'Arrows', filter: { table: 'ItemSubType', id: 66 } },
          { name: 'Bullets', meta: 'Bullets', filter: { table: 'ItemSubType', id: 67 } },
          {
            name: 'Thrown',
            meta: 'Thrown',
            filter: [
              { table: 'ItemSlot', id: 25 }
            ]
          }
        ]
      }
    ]
  }
  types.push(ranged)

  const consumableTypes = [
    { name: 'Flasks', meta: 'Flasks', filter: { table: 'ItemSubType', id: 4 } },
    { name: 'Elixirs', meta: 'Elixirs', filter: { table: 'ItemSubType', id: 3 } },
    { name: 'Scrolls', meta: 'Scrolls', filter: { table: 'ItemSubType', id: 5 } },
    { name: 'Consumable', meta: 'Consumable', filter: { table: 'ItemSubType', id: 1 } },
    { name: 'Potions', meta: 'Potions', filter: { table: 'ItemSubType', id: 2 } },
    { name: 'Explosives', meta: 'Explosives', filter: { table: 'ItemSubType', id: 71 } },
    { name: 'Bandages', meta: 'Bandages', filter: { table: 'ItemSubType', id: 8 } }
  ]

  if (expansion === 'vanilla') {
    consumableTypes.push(
      { name: 'Food', meta: 'Food', filter: { table: 'ItemSubType', id: 11 } },
      { name: 'Drink', meta: 'Drink', filter: { table: 'ItemSubType', id: 12 } },
      { name: 'Combat Companions', meta: 'Combat Companions', filter: { table: 'ItemSubType', id: 72 } },
      { name: 'Item Enhancements', meta: 'Item Enhancement Items', filter: { table: 'ItemSubType', id: 7 } },
      { name: 'Enchants', meta: 'Enchants', filter: { table: 'ItemSubType', id: 13 } },
      { name: 'Other Consumables', meta: 'Other Consumables', filter: { table: 'ItemSubType', id: 9 } }
    )
  } else {
    consumableTypes.push(
      { name: 'Food & Drink', meta: 'Food & Drink', filter: { table: 'ItemSubType', id: 6 } },
      { name: 'Item Enhancements', meta: 'Item Enhancement Items', filter: { table: 'ItemSubType', id: 7 } },
      { name: 'Other Consumables', meta: 'Other Consumables', filter: { table: 'ItemSubType', id: 9 } }
    )
  }

  // Consumables category
  const consumables = {
    name: 'Consumables',
    meta: 'Consumables',
    filter: { table: 'ItemType', id: 0 },
    columns: [
      { field: 'duration' }
    ],
    children: consumableTypes
  }
  types.push(consumables)

  // Container category
  const containers = {
    name: 'Containers',
    meta: 'Containers',
    filter: { table: 'ItemSlot', id: 18 },
    columns: [{ field: 'containerSlots', label: 'Slots' }],
    children: [
      {
        name: 'Bags',
        meta: 'Bags',
        filter: { table: 'ItemType', id: 1 },
        children: [
          { name: 'Storage Bags', meta: 'Storage Bags', filter: { table: 'ItemSubType', id: 15 } },
          { name: 'Soul Bags', meta: 'Soul Bags', filter: { table: 'ItemSubType', id: 16 } },
          { name: 'Enchanting Bags', meta: 'Enchanting Bags', filter: { table: 'ItemSubType', id: 18 } },
          { name: 'Herb Bags', meta: 'Herb Bags', filter: { table: 'ItemSubType', id: 17 } }
        ]
      },
      {
        name: 'Quivers',
        meta: 'Quivers',
        filter: { table: 'ItemType', id: 11 },
        children: [
          { name: 'Ammo Pouches', meta: 'Ammo Pouches', filter: { table: 'ItemSubType', id: 100 } },
          { name: 'Quivers', meta: 'Quivers', filter: { table: 'ItemSubType', id: 99 } }
        ]
      }
    ]
  }
  types.push(containers)

  const tradeGoodsTypes = [
    { name: 'Cloth Goods', meta: 'Cloth Goods', filter: { table: 'ItemSubType', id: 74 } },
    { name: 'Leather Goods', meta: 'Leather Goods', filter: { table: 'ItemSubType', id: 75 } },
    { name: 'Elemental', meta: 'Elemental Goods', filter: { table: 'ItemSubType', id: 79 } },
    { name: 'Herbs', meta: 'Herbs', filter: { table: 'ItemSubType', id: 78 } },
    { name: 'Meat', meta: 'Meat', filter: { table: 'ItemSubType', id: 77 } },
    { name: 'Enchanting Goods', meta: 'Enchanting Items', filter: { table: 'ItemSubType', id: 81 } },
    { name: 'Metal & Stone', meta: 'Metal & Stone Goods', filter: { table: 'ItemSubType', id: 76 } },
    { name: 'Parts', meta: 'Parts', filter: { table: 'ItemSubType', id: 70 } },
    { name: 'Devices', meta: 'Devices', filter: { table: 'ItemSubType', id: 72 } }
  ]
  if (expansion === 'vanilla') {
    tradeGoodsTypes.push(
      { name: 'Materials', meta: 'Materials', filter: { table: 'ItemSubType', id: 83 } },
      { name: 'Gems', meta: 'Gems', filter: { table: 'ItemSubType', id: 82 } },
      { name: 'Other Goods', meta: 'Other Goods', filter: { table: 'ItemSubType', id: 80 } }
    )
  } else {
    tradeGoodsTypes.push(
      { name: 'Gems', meta: 'Gems', filter: { table: 'ItemType', id: 3 } },
      { name: 'Other Goods', meta: 'Other Goods', filter: { table: 'ItemSubType', id: 80 } }
    )
  }

  // Trade Goods category
  const tradeGoods = {
    name: 'Trade Goods',
    meta: 'Trade Goods',
    filter: { table: 'ItemType', id: expansion === 'vanilla' ? 7 : [3, 7] },
    children: tradeGoodsTypes
  }

  types.push(tradeGoods)

  const miscellaneousTypes = [
    { name: 'Companions', meta: 'Companions', filter: { table: 'ItemSubType', id: 107 } },
    { name: 'Mounts', meta: 'Mounts', filter: { table: 'ItemSubType', id: 110 } },
    { name: 'Reagents', meta: 'Reagents', filter: { table: 'ItemSubType', id: 106 } },
    { name: 'Junk', meta: 'Junk', filter: { table: 'ItemSubType', id: 105 } }
  ]
  if (expansion === 'vanilla') {
    miscellaneousTypes.push(
      { name: 'Containers', meta: 'Containers', filter: { table: 'ItemSubType', id: 111 } },
      { name: 'Other', meta: 'Other', filter: { table: 'ItemSubType', id: 109 } }
    )
  } else {
    miscellaneousTypes.push(
      { name: 'Other', meta: 'Other', filter: { table: 'ItemSubType', id: 109 } }
    )
  }
  // Misc category
  const misc = {
    name: 'Miscellaneous',
    meta: 'Miscellaneous Items',
    filter: { table: 'ItemType', id: 15 },
    children: miscellaneousTypes
  }
  types.push(misc)

  // Recipes category
  const recipes = {
    name: 'Recipes',
    meta: 'Recipes',
    filter: { table: 'ItemType', id: 9 },
    children: [
      { name: 'Books', meta: 'Recipe Books', filter: { table: 'ItemSubType', id: 85 } },
      { name: 'Alchemy', meta: 'Alchemy Recipes', filter: { table: 'ItemSubType', id: 91 } },
      { name: 'Blacksmithing', meta: 'Blacksmithing Recipes', filter: { table: 'ItemSubType', id: 89 } },
      { name: 'Cooking', meta: 'Cooking Recipes', filter: { table: 'ItemSubType', id: 90 } },
      { name: 'Enchanting', meta: 'Enchanting Recipes', filter: { table: 'ItemSubType', id: 93 } },
      { name: 'Engineering', meta: 'Engineering Recipes', filter: { table: 'ItemSubType', id: 88 } },
      { name: 'First Aid', meta: 'First Aid Recipes', filter: { table: 'ItemSubType', id: 92 } },
      { name: 'Leatherworking', meta: 'Leatherworking Recipes', filter: { table: 'ItemSubType', id: 86 } },
      { name: 'Tailoring', meta: 'Tailoring Recipes', filter: { table: 'ItemSubType', id: 87 } }
    ]
  }
  types.push(recipes)

  // Quest and keys
  types.push({
    name: 'Quest Items',
    meta: 'Quest Items',
    filter: { table: 'ItemType', id: 12 }
  })
  types.push({
    name: 'Keys',
    meta: 'Keys',
    filter: { table: 'ItemType', id: 13 }
  })

  return types
}
