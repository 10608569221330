export default {
  props: {
    price: {
      type: Number,
      default: 0
    }
  },
  computed: {
    priceToMoney () {
      const gold = Math.floor(this.price / 10000)
      const silver = Math.floor((this.price - gold * 10000) / 100)
      const copper = this.price - gold * 10000 - silver * 100

      return {
        gold,
        silver,
        copper
      }
    }
  }
}
