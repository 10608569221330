module.exports = {
  state: () => ({
    sortField: 'id',
    sortAscending: true,
    filters: []
  }),
  mutations: {
    setListViewSortField (state, field) {
      state.sortField = field
    },
    setListViewSortAscending (state, ascending) {
      state.sortAscending = ascending
    },
    setListViewFilter (state, { name, model, field, value, operator, through }) {
      if (!model) model = '_b'

      // Push or update filter object
      const filterIdx = state.filters.findIndex(f => f.name === name)
      if (filterIdx === -1) state.filters.splice(state.filters.length, 0, { name, model, field, operator, value, through })
      else {
        const filter = state.filters[filterIdx]
        filter.value = value
        filter.field = field
        filter.operator = operator
        filter.model = model
        filter.through = through
        state.filters.splice(filterIdx, 1, filter)
      }
    },
    resetListViewFilter (state) {
      state.filters = []
    }
  },
  actions: {
    resetListView ({ commit }) {
      commit('setListViewSortField', 'id')
      commit('setListViewSortAscending', true)
    }
  },
  getters: {
    listViewFilter: (state) => (name) => {
      return state.filters.find(f => f.name === name)
    },
    listViewQueryParams: (state) => {
      // Generate query params for API request
      const queries = []

      const filters = state.filters
      for (const filter of filters) {
        if (!filter.value || (Array.isArray(filter) && !filter.value.length)) continue

        // Convert array
        const value = Array.isArray(filter.value)
          ? filter.value.map(f => f.value || f).join(',')
          : filter.value

        const query = `${filter.model}:${filter.field}:${filter.operator}:${value}`
        queries.push(filter.through ? `${query}:true` : query)
      }

      return queries
    }
  }
}
