import loadingImage from '@/assets/img/icons/spinner.svg'
import errorImage from '@/assets/logo.svg'
// import VTooltip from 'v-tooltip'
import Vue from 'vue'
import VueCodeHighlight from 'vue-code-highlight'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  loading: loadingImage,
  error: errorImage
})

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueCodeHighlight)
Vue.use(VueAxios, axios)

// Vue.use(VTooltip)

export const createApp = context => {
  // create router and store instances

  const store = createStore()
  const router = createRouter()

  // sync so that route state is available as part of the store
  sync(store, router)

  const app = new Vue({
    data: { url: context ? context.url : '' },
    router,
    store,
    render: h => h(App)
  })

  return { app, router, store }
}
