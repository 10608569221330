<template>
  <div class="relative items-center justify-start hidden mr-4 text-base font-bold md:flex dropdown">
    <img
      :src="require(`../assets/img/${currentExpansion}.png`)"
      alt="Expansion Icon"
      class="mr-2"
    >
    <div>
      <span class="h-7 pt-1 uppercase hidden md:flex transition-all">{{ currentExpansion === 'vanilla' ? 'Classic Era' : currentExpansion }}</span>
    </div>
    <div>
      <img
        src="@/assets/img/icons/arrow_dropdown.svg"
        alt="Dropdown Arrow"
        class="dropdown-icon mr-4"
      >
    </div>
    <div class="absolute p-3 border rounded-md shadow-lg dropdown-container border-primary border-opacity-20 ring-1 ring-black ring-opacity-5 focus:outline-none bg-bg-500">
      <a
        v-for="(activeExpansion, i) in expansions.filter(x => x.name.toLowerCase() !== currentExpansion)"
        :id="activeExpansion.name"
        :key="'activeExpansion' + i"
        :href="`${activeExpansion.path}`"
        class="font-normal dropdown-item"
        :class="{ 'dropdown-item-disabled': activeExpansion.disabled }"
      >
        <div
          style="display: flex;"
        >
          <img
            :src="require(`../assets/img/${activeExpansion.name.toLowerCase()}.png`)"
            alt="Expansion Icon"
            class="dropdown-icon"
          >
          <span class="hidden md:block ml-2"> {{ activeExpansion.name === 'vanilla' ? 'Classic Era' : activeExpansion.name }} </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpansionDropdown',
  props: {
    currentExpansion: {
      type: String,
      default: 'vanilla'
    },
    nav: {
      type: String,
      default: 'top'
    }
  },
  data () {
    return {
      name: 'Expansion',
      expansions: [
        { name: 'Vanilla', path: '/' },
        { name: 'TBC', path: '/tbc' },
        { name: 'WotLK', path: '/wotlk' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
@import '~@/scss/mixins';

.dropdown {
  display:flex;
  cursor: pointer;
  white-space: nowrap;
  z-index: 50;
  &:hover {
    > .label {
      color: $color-primary;

      .dropdown-icon {
        transform: rotate(-90deg);
      }
    }

    > .dropdown-container {
      display: flex;
    }
  }

  .label {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-container {
    top: 100%;
    left: 0;
    position: absolute;
    display: none;
    flex-direction: column;
    z-index: 1;

    .dropdown-item {
      color: white;
      position: relative;
      padding: 5px 10px;
      display: flex;

      margin-right: 16px;
      @media only screen and (max-width: 768px) {
        margin-right: 0px !important;
      }

      .dropdown-container {
        top: 0;
        left: 100%;
      }

      &:hover {
        span {
          color: $color-primary;
        }

        > .dropdown-container {
          display: flex;
        }
      }
    }

    .dropdown-item-disabled {
      opacity: 0.25;
      &:hover {
        span {
          color: white;
        }
      }
    }
  }

  .dropdown-icon {
    width: 24px;
    @media only screen and (max-width: 768px) {
       width:48px !important;
    }
  }

}
</style>
