import { GameObject } from '../models/GameObject'
import { SpellEffect } from '../models/Item'
import { NPC, NPCSpawn } from '../models/NPC'

export const kebabCase = (input: string) => input?.split(' ').join('-').toLowerCase()
export const camelCase = (input: string) => input?.split(' ').map((word, index) => index === 0 ? `${word[0].toLowerCase()}${word.substring(1)}` : `${word[0].toUpperCase()}${word.substring(1)}`).join('')
export const lowerCase = (input: string) => input?.toLowerCase()
export const convertCamelCaseToKebabCase = (input: string) => input?.split(/(?=[A-Z])/).join('-').toLowerCase()
export const splitOnSpaceOrHyphen = (input: string) => input.split(/[ -]+/)
export const capitalizeFirst = (input: string) => `${input[0].toUpperCase()}${input.substring(1)}`

export const pluralize = (input: string) => {
  // until we find a better solution for class pluralization
  if (input === 'class') return input + 'es'
  if (input.endsWith('y')) return input.slice(0, -1) + 'ies'
  if (input.slice(-1) !== 's') return input + 's'
  return input
}
export const depluralize = (input: string) => {
  // until we find a better solution for class depluralization
  if (input === 'classes') return 'class'
  if (input === 'class') return 'class'

  if (input.endsWith('ies')) return input.slice(0, -3) + 'y'
  if (input.slice(-1) === 's') return input.slice(0, input.length - 1)
  return input
}

export const singlePageParser = (route: string) => {
  const singlePaths = ['item', 'item-set', 'zone', 'quest', 'npc', 'spell', 'achievement', 'faction', 'title', 'icon', 'skill', 'currency', 'pet']
  // If I'm on a single page return the baseTypes for that particular category
  for (const path of singlePaths) {
    if (route === path) {
      return path.endsWith('y') ? path.slice(0, -1) + 'ies' : path + 's'
    }
  }
  return route
}

export const removeWhiteSpaceAndApostrophes = (value: string) => {
  if (value.length > 0) {
    const updated = value.replace(/\s/g, '-')
    return updated.replace(/'/g, '-')
  }
}
export const getLocationsFromGameObject = (object: GameObject) => {
  const locations: string[] = []
  object.gameObjectSpawns.forEach((spawn) => {
    if (spawn.location?.name.length && !locations.includes(spawn.location?.name)) {
      locations.push(spawn.location?.name)
    }
  })
  return locations.join(', ')
}

export const getLocationsFromNPC = (npc: NPC) => {
  // console.log(npc)
  const locations: string[] = []
  npc.npcSpawns.forEach((spawn: NPCSpawn) => {
    if (spawn.location?.name.length && !locations.includes(spawn.location?.name)) {
      locations.push(spawn.location?.name)
    }
  })
  // console.log(locations)
  return locations.join(', ')
}

export const getReagentsIconNamesFromSpell = (spell: any) => {
  const reagentIconNames: number[] = []
  spell.reagents.forEach((reagent: any) => {
    if (reagent.icon) {
      reagentIconNames.push(reagent.icon.name)
    }
  })
  return reagentIconNames
}
export const getReagentsNamesFromSpell = (spell: any) => {
  const reagentNames: number[] = []
  spell.reagents.forEach((reagent: any) => {
    if (reagent.icon) {
      reagentNames.push(reagent.icon.name)
    }
  })
  return reagentNames
}

export const removeApostrophes = (value: string): string => {
  if (value.length > 0) { return value.replace(/'/g, value.includes('onyxia') || value.includes('goro') ? '' : ' ') } else return ''
}

export const prettifyRouteName = (input: string) => {
  const newInput = input?.toLowerCase()

  if (newInput === 'npc') {
    return 'NPC'
  } else if (newInput === 'npcs') {
    return 'NPCs'
  }

  return splitOnSpaceOrHyphen(newInput).map(capitalizeFirst)
    .join(' ')
}

export const getSafeRouteName = (input: string): string => {
  if (input) { return splitOnSpaceOrHyphen(input).filter(x => x.length > 0).map(camelCase).join(' ') } else return ''
}

export const parseSpellEffectDescription = (input: SpellEffect) => {
  if (input.description) {
    return input.description
  }
  if (input.name.length && input.name.includes('Attack Power Ranged')) {
    const num = input.name.match(/\d+/)![0]
    return `+${num} Ranged Attack Power`
  } else if (input.name.length && input.name.includes('Attack Power') && !input.name.includes('Ranged')) {
    const num = input.name.match(/\d+/)![0]
    return `+${num} Attack Power`
  } else if (input.name.length && input.name.includes('Increased Armor') && !input.name.includes('Ranged')) {
    const num = input.name.match(/\d+/)![0]
    return `+${num} Armor`
  } else if (input.name.length && input.name.includes('Increased Stamina') && !input.name.includes('Ranged')) {
    const num = input.name.match(/\d+/)![0]
    return `+${num} Stamina`
  } else if (input.name.length && input.name.includes('Resist') && !input.name.includes('Ranged')) {
    const num = input.name.replace(/Increased (.*) (\d+)/g, '+$2 $1')
    return num
  } else return input.description
}

export const parseDollarVariables = (input: string): string => {
  if (input && input.length) {
    const newLined = input?.replace('$B$B', '<br/>')
    const newLined2 = newLined?.replace('$b$b', '<br/>')
    const newLined3 = newLined2?.replace('\r\n', '<br/>')
    const newLined4 = newLined3.replace(/\s*\$B\s*/g, '<br/>');
    const playerNamed = newLined4?.replace('$N', 'Adventurer')
    const playerNamed2 = playerNamed?.replace('$n', 'Adventurer')
    const raceConverted = playerNamed2?.replace('$r', '*race*')
    return raceConverted
  } else {
    return ''
  }
}

export function dynamicSort (property: string) {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  }
}

export function isInputInRoute (input: string, route: string) {
  if (route.includes(input.toLowerCase())) {
    return true
  } else {
    return false
  }
}
