import itemSetTypes from '@/store/modules/item-set/item-set-types'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('itemSet', [...Object.keys(itemSetTypes.actions)]),
    ...mapMutations('itemSet', [...Object.keys(itemSetTypes.mutations)])
  },
  computed: {
    ...mapGetters('itemSet',
      [...Object.keys(itemSetTypes.getters)]
    )
  }
}
