import { NPCDto, NPCSpawn } from './NPC'

export type SpawnPoint = {
  id: number
  label: string
  x: number
  y: number
}

export type SpawnPoints = {
  locationId: number,
  points: SpawnPoint[]
}

export class Spawn {
  #npc: NPCDto

  constructor (props: NPCDto) {
    this.#npc = props
  }

  getSpawnPoints () {
    if (!this.#npc.npcSpawns || !this.#npc.npcSpawns.length) {
      return []
    }

    // Group by location id
    const locationMap: {
      [key: number]: NPCSpawn[]
    } = {}

    for (const spawn of this.#npc.npcSpawns) {
      const key = spawn.locationId
      if (!locationMap[key]) locationMap[key] = [spawn]
      else locationMap[key].push(spawn)
    }

    // Return the one with the largest spawn count
    const locationArray = Object.entries(locationMap).sort(
      (a, b) => b[1].length - a[1].length
    )
    const points = locationArray[0][1].map(spawn => {
      return {
        id: this.#npc.id,
        label: this.#npc.name,
        x: spawn.posX,
        y: spawn.posY,
        locationId: spawn.locationId,
        location: spawn.location
      }
    })

    return {
      locationId: parseInt(locationArray[0][0]),
      points
    }
  }
}
