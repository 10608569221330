<template>
  <div class="flex flex-col">
    <div class="flex flex-col flex-wrap w-full gap-5 mb-5 text-left md:flex-row px-1">
      <FilterInput
        name="name"
        label="Name"
        filter-field="name"
      />

      <!-- Item filters -->
      <FilterSelect
        v-if="type === 'item'"
        name="class"
        label="Class"
        filter-field="id"
        filter-model="Class"
        :values="classes"
      />
      <FilterSelect
        v-if="type === 'item'"
        name="slot"
        label="Slot"
        filter-field="id"
        filter-model="ItemSlot"
        :values="itemSlots"
      />
      <FilterSelect
        v-if="type === 'item'"
        name="type"
        label="Type"
        filter-field="id"
        filter-model="ItemType"
        :values="itemTypes"
      />
      <FilterMultiSelect
        v-if="type === 'item'"
        name="quality"
        label="Quality"
        filter-field="id"
        filter-model="ItemQuality"
        :values="Object.entries(itemQualities).map(([key, value]) => ({value: key, name: value }))"
      />
      <FilterRange
        v-if="type === 'item'"
        name="level"
        label="Item Level"
        filter-field="itemLevel"
        :max="999"
      />

      <!-- Quest filters -->
      <FilterRange
        v-if="type === 'quest'"
        name="level"
        label="Required Level"
        filter-field="minLevel"
        :max="100"
      />

      <!-- NPC filters -->
      <FilterRange
        v-if="type === 'npc'"
        name="level"
        label="Level"
        filter-field="maxLevel"
        :max="100"
      />

      <!-- Spell filters -->
      <FilterSelect
        v-if="type === 'spell'"
        name="resourceType"
        label="Resource Type"
        filter-field="resourceType"
        :values="spellResourceTypes"
      />
    </div>

    <ListViewFilterAdditional
      :filters="additionalFilters"
    />
  </div>
</template>

<script>
import FilterInput from './filters/FilterInput'
import FilterSelect from './filters/FilterSelect'
import FilterMultiSelect from './filters/FilterMultiSelect'
import FilterRange from './filters/FilterRange'
import ListViewFilterAdditional from './ListViewFiltersAdditional'

export default {
  name: 'ListViewFilters',
  components: { FilterRange, FilterMultiSelect, FilterSelect, FilterInput, ListViewFilterAdditional },
  props: {
    additionalFilters: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    expansion: {
      type: String,
      default: 'vanilla'
    }
  },
  data () {
    // Normally we would request this from the API but who cares at this point...

    const classes = [
      { label: 'Druid', value: 11 },
      { label: 'Hunter', value: 3 },
      { label: 'Mage', value: 8 },
      { label: 'Paladin', value: 2 },
      { label: 'Priest', value: 5 },
      { label: 'Rogue', value: 4 },
      { label: 'Shaman', value: 7 },
      { label: 'Warlock', value: 9 },
      { label: 'Warrior', value: 1 }
    ]
    if (this.expansion === 'wotlk') classes.push({ label: 'Death Knight', value: 6 })

    const spellResourceTypes = [
      { value: 'Mana', label: 'Mana' },
      { value: 'Energy', label: 'Energy' },
      { value: 'Rage', label: 'Rage' },
      { value: 'Focus', label: 'Focus' },
      { value: 'Health', label: 'Health' }
    ]
    if (this.expansion === 'wotlk') {
      spellResourceTypes.push({ value: 'Rune', label: 'Rune' })
      spellResourceTypes.push({ value: 'Runic Power', label: 'Runic Power' })
    }

    const itemQualities = {
      0: 'Poor',
      1: 'Common',
      2: 'Uncommon',
      3: 'Rare',
      4: 'Epic',
      5: 'Legendary',
      6: 'Artifact'
    }
    if (this.expansion === 'wotlk') {
      itemQualities[7] = 'Heirloom'
    }

    return {
      itemQualities,
      classes,
      itemSlots: [
        { value: 1, label: 'Head' },
        { value: 2, label: 'Neck' },
        { value: 3, label: 'Shoulder' },
        { value: 4, label: 'Shirt' },
        { value: 5, label: 'Chest' },
        { value: 6, label: 'Waist' },
        { value: 7, label: 'Legs' },
        { value: 8, label: 'Feet' },
        { value: 9, label: 'Wrists' },
        { value: 10, label: 'Hands' },
        { value: 11, label: 'Finger' },
        { value: 12, label: 'Trinket' },
        { value: 13, label: 'Weapon' },
        { value: 14, label: 'Shield' },
        { value: 15, label: 'Ranged' },
        { value: 16, label: 'Back' },
        { value: 17, label: 'Two-Hand' },
        { value: 18, label: 'Bag' },
        { value: 19, label: 'Tabard' },
        { value: 21, label: 'Main Hand' },
        { value: 22, label: 'Off Hand' },
        { value: 23, label: 'Holdable (Tome)' },
        { value: 24, label: 'Ammo' },
        { value: 25, label: 'Thrown' },
        { value: 27, label: 'Quiver' },
        { value: 28, label: 'Relic' }
      ],
      itemTypes: [
        { value: 0, label: 'Consumable' },
        { value: 1, label: 'Container' },
        { value: 2, label: 'Weapon' },
        { value: 3, label: 'Gem' },
        { value: 4, label: 'Armor' },
        { value: 5, label: 'Reagent' },
        { value: 6, label: 'Projectile' },
        { value: 7, label: 'Trade Goods' },
        { value: 9, label: 'Recipe' },
        { value: 11, label: 'Quiver' },
        { value: 12, label: 'Quest' },
        { value: 13, label: 'Key' },
        { value: 15, label: 'Miscellaneous' },
        { value: 16, label: 'Glyph' }
      ],
      spellResourceTypes
    }
  }
}
</script>

<style scoped lang="scss">

</style>
