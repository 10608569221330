<template>
  <TemplateSinglePage :show-loading="!enchantment">
    <div
      v-if="enchantment"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <h1 class="mb-2 font-sans text-3xl font-bold text-primary">
        {{ enchantment.name }}
      </h1>
      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />
      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'
export default {
  name: 'Enchantment',
  components: {
    TemplateSinglePage,
    QuickFacts,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.enchantment?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.enchantment?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.enchantment?.usedByGem, 'items', '', RelatedTableFilterOption.items),
        setRelatedTable(this.enchantment?.usedByUseItem, 'items', '', RelatedTableFilterOption.items),
        setRelatedTable(this.enchantment?.usedBySpell, 'spells', '', RelatedTableFilterOption.spells),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {
      }
    },
    metaDescription () {
      return `View Enchantment ${this.enchantment?.name} from The Burning Crusade Classic.`
    },
    enchantment () {
      return this.$store.state.enchantments[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchEnchantment().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.enchantment) this.fetchEnchantment()
  },
  mounted () { // Client side fetching
    this.fetchEnchantment()
  },
  methods: {
    fetchEnchantment () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchEnchantment', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>
