<template>
  <div class="space-y-2">
    <label
        :for="name"
        class="text-left text-gray-300"
    >
      {{ label }}
    </label>
    <select
        :name="name"
        class="default-input"
        v-model="input"
    >
      <optgroup label="Default">
        <option :value="null">
          {{ all }}
        </option>
      </optgroup>
      <optgroup label="Choose One">
        <option
            v-for="(value, i) in values"
            :key="`${name}-${i}`"
            :value="value.value"
        >
          {{ value.label }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  name: 'FilterSelect',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    filterField: {
      type: String,
      required: true
    },
    filterModel: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      required: true
    }
  },
  computed: {
    all () {
      const pluralize = (input) => {
        if (input.slice(-1) === 'y') return `${input.slice(0, -1)}ies`
        if (input.slice(-2) === 'ss') return `${input}es`
        if (input.slice(-1) !== 's') return input + 's'
        return input
      }
      return `All ${pluralize(this.label)}`
    },
    input: {
      get () {
        return this.$store.getters.listViewFilter(this.name)?.value || null
      },
      set (value) {
        this.$store.commit('setListViewFilter', {
          name: this.name,
          field: this.filterField,
          operator: 'eq',
          model: this.filterModel ? this.filterModel : undefined,
          value
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
