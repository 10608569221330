<template>
  <TemplateSinglePage :show-loading="!classData">
    <div
      v-if="classData"
      class="flex flex-col"
    >
      <Breadcrumbs />
      <div
        class="flex flex-wrap items-center justify-center p-0 m-0 font-sans text-base text-black md:justify-start "
      >
        <div class="object-cover w-16 h-16 p-0 mb-5 mr-5 text-base">
          <img
            :src="`${baseUrl}/img/Class_${classData.name.replace(/\s/g, '')}.svg`"
            :alt="classData.name"
            class="object-cover w-16 h-16 p-0 m-0 text-black"
          >
        </div>
        <h1 class="mb-5 font-sans text-3xl font-bold text-primary">
          {{ classData.name }}
        </h1>
      </div>
      <quick-facts
        class="text-white mb-4"
        :data="quickFactsData"
      />
      <div
        class="flex flex-wrap w-full gap-2 p-0 mx-0 mt-0 mb-6 font-sans text-base text-black "
      >
        <RelatedTableFilterButtonBase
          class="w-full"
          :filter-options="filterOptions"
        />
      </div>
    </div>
  </TemplateSinglePage>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import QuickFacts from '@/components/QuickFacts.vue'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'

export default {
  name: 'Class',
  components: {
    TemplateSinglePage,
    QuickFacts,
    RelatedTableFilterButtonBase,
    Breadcrumbs
  },
  metaInfo () {
    const info = {}
    info.meta = []

    info.title = this.classData?.name
    info.meta.push({ property: 'og:title', vmid: 'og:title', content: this.classData?.name })
    info.meta.push({ property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` })
    info.meta.push({ property: 'og:description', vmid: 'og:description', content: this.metaDescription })
    info.meta.push({ property: 'description', vmid: 'description', content: this.metaDescription })

    return info
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    filterOptions () {
      return [
        setRelatedTable(this.classData?.spells, 'spells', '', RelatedTableFilterOption.spells),
        setRelatedTable(this.classData?.items, 'items', '', RelatedTableFilterOption.items),
        setRelatedTable(this.classData?.quests, 'quests', '', RelatedTableFilterOption.quests),
        setRelatedTable(this.classData?.itemSets, 'itemSets', '', RelatedTableFilterOption.itemSets),
        setRelatedTable(this.classData?.races, 'races', '', RelatedTableFilterOption.races),
        new RelatedTableFilterOption({
          title: 'Comments',
          isDisabled: true
        }),
        new RelatedTableFilterOption({
          title: 'Screenshots',
          isDisabled: true
        })
      ].filter(Boolean)
    },
    quickFactsData () {
      return {}
    },
    metaDescription () {
      return `View Class ${this.classData?.name} from The Burning Crusade Classic`
    },
    classData () {
      return this.$store.state.classes[this.$route.params.id]
    }
  },

  serverPrefetch () { // Server side fetching
    return this.fetchClassData().catch(error => {
      console.error(error)
    })
  },
  updated () {
    if (!this.classData) this.fetchClassData()
  },
  mounted () { // Client side fetching
    this.fetchClassData()
  },
  methods: {
    fetchClassData () {
      const page = this.$store.state.pageInfo?.currentPage
      this.$store.commit('setLoading')
      return Promise.all([
        this.$store.dispatch('fetchClassData', { id: this.$route.params.id, page: page })
      ])
        .then(() => {
          this.$store.commit('clearLoading')
        })
        .catch(err => {
          console.error(err)
          this.$store.commit('clearLoading')
        })
    }
  }
}

</script>
