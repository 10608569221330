

import Vue, { PropType } from 'vue'
import { Spell } from '../../../../lib/src/models/Item'
export default Vue.extend({
  name: 'SpellTooltip',
  props: {
    spell: {
      type: Object as PropType<Spell>,
      required: true
    }
  },

  computed: {
    parseSpellDescriptionNewlines () {
      const data: string = this.spell.description
      if (data) {
        const value = data.replace(/(?:\\[rn]|[\r\n]+)+/g, '\n')
        return value
      } else return ''
    }
  },

  methods: {
    getCooldownSecondsMinutesAndHours (cooldown: number) {
      const seconds = cooldown / 1000
      const minutes = seconds >= 60 ? seconds / 60 : null
      const hours = minutes && minutes >= 60 ? minutes / 60 : null
      const label = hours ? 'hour' : minutes ? 'min' : 'sec'
      return `${hours || minutes || seconds} ${label} cooldown`
    }
  }

})
