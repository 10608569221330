<template>
  <TemplateSinglePage>
    <div
      id="items"
      class="flex flex-col"
    >
      <div class="flex items-center justify-between">
        <h1 class="font-sans text-3xl font-bold text-primary">
          Results for "{{ query }}"
        </h1>
        <button
          class="flex items-center justify-center w-10 h-10 text-lg rounded-full outline-none md:hidden bg-bg-900 text-primary focus:outline-none"
          @click="openItemFilterModal()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
        </button>
      </div>

      <RelatedTableFilterButtonBase
        :filter-options="searchOptions"
      />
    </div>

    <ItemFilterModal
      v-if="showItemFilterModal"
      :config="filterConfig"
      @close="closeItemFilterModal"
    />
  </TemplateSinglePage>
</template>

<script>
import ItemFilterModal from '@/components/ItemFilterModal'
import TemplateSinglePage from '@/components/TemplateSinglePage'
import api from '@/store/api-client'
import RelatedTableFilterButtonBase from '../../../../lib/src/components/RelatedTableFilterButtonBase.vue'
import coreMixin from '../../../../lib/src/mixins/core-mixin'
import filterMixin from '../../../../lib/src/mixins/filter-mixin'
import locationMixin from '../../../../lib/src/mixins/location-mixin'
import RelatedTableFilterOption, { setRelatedTable } from '../../../../lib/src/models/RelatedTableFilterOption'
import { convertCamelCaseToKebabCase } from '../../../../lib/src/util'

export default {
  name: 'Search',
  components: {
    TemplateSinglePage,
    ItemFilterModal,
    RelatedTableFilterButtonBase

  },
  mixins: [
    coreMixin,
    locationMixin,
    filterMixin
  ],
  data () {
    return {
      showItemFilterModal: false,
      filterConfig: {
        name: true
      },
      searchData: {
        items: {},
        itemSets: {},
        quests: {},
        npcs: {},
        spells: {},
        creatureSpells: {},
        objects: {},
        zones: {},
        factions: {}
      },
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    searchOptions () {
      // For each key in the searchData object, run setRelatedTable()
      const options = [
        setRelatedTable(this.searchData.items, 'items', '', RelatedTableFilterOption.items),
        setRelatedTable(this.searchData.itemSets, 'itemSets', '', RelatedTableFilterOption.itemSets),
        setRelatedTable(this.searchData.quests, 'quests', '', RelatedTableFilterOption.quests),
        setRelatedTable(this.searchData.npcs, 'npcs', '', RelatedTableFilterOption.npcs),
        setRelatedTable(this.searchData.spells, 'spells', '', RelatedTableFilterOption.searchSpells),
        setRelatedTable(this.searchData.creatureSpells, 'creatureSpells', '', RelatedTableFilterOption.searchCreatureSpells),
        setRelatedTable(this.searchData.objects, 'objects', '', RelatedTableFilterOption.objects),
        setRelatedTable(this.searchData.zones, 'zones', '', RelatedTableFilterOption.zones),
        setRelatedTable(this.searchData.factions, 'factions', '', RelatedTableFilterOption.factions)
      ].filter(Boolean)
      return options
    },
    columns () {
      return []
    },
    query () {
      return this.$route.query.name
    }
  },
  watch: {
    '$store.state.pageInfo.currentPage' () {
      this.search(this.$store.state.selectedRelatedTableIndex)
      this.$store.commit('clearLoading')
    },
    '$store.state.selectedRelatedTableIndex' () {
      if (this.searchOptions.find(option => option.key === this.$store.state.selectedRelatedTableIndex)?.data.currentPage !== 1) {
        this.search(this.$store.state.selectedRelatedTableIndex)
      }
      this.search(this.$store.state.selectedRelatedTableIndex)
    }
  },
  metaInfo () {
    let metaTitle = 'Search'
    let metaUrl = `${this.baseUrl}/search`
    if (this.query.toString().length > 0) {
      metaTitle += ` - ${this.query}`
      metaUrl += `?name=${this.query}`
    }
    return {
      title: metaTitle,
      meta: [
        { property: 'og:title', vmid: 'og:title', content: metaTitle },
        { property: 'og:url', vmid: 'og:url', content: metaUrl },
        { property: 'og:description', vmid: 'og:description', content: `Search "${this.query.toString() || 'Items, Quests and more'}" from WotLK Classic.` },
        { property: 'description', vmid: 'description', content: `Search "${this.query.toString() || 'Items, Quests and more'}" from WotLK Classic.` }
      ]
    }
  },

  async created () {
    this.$store.commit('setPageInfo', { currentPage: 1 })
    for (const key in this.searchData) {
      this.search(key)
    }
    this.$store.commit('setRelatedTableIndex', 'items')
  },

  methods: {
    async search (key) {
      const page = this.$store.state.pageInfo.currentPage || 1
      const results = await api.getSearchResultsData(convertCamelCaseToKebabCase(key), page, 50, this.query)
      this.searchData[key] = results
    },
    openItemFilterModal () {
      this.showItemFilterModal = true
    },
    closeItemFilterModal () {
      this.showItemFilterModal = false
    }
  }
}
</script>
