export default {
  methods: {
    like (val) {
      return val != null ? { like: val } : {}
    },
    substring (val) {
      return val != null ? { substring: val } : {}
    },
    between (vals) {
      return vals != null ? { between: vals } : {}
    },
    in (vals) {
      return vals != null ? { in: vals } : {}
    },
    async changeFilters (fieldName) {
      this.$store.dispatch('handleFilterChange', this.filters)
      const routeName = await this.$store.dispatch(
        'updateRouteWithFilters',
        fieldName
      )
      if (routeName) {
        this.$router.replace({ path: routeName }).catch((err) => {
          console.log(`filter-mixn error: ${err}`)
        })
      }
    }
  },
  computed: {
    filters () {
      return this.$store.state.filters
    },
    questTypes () {
      return this.$store.state.types.quest
    },
    subTypesForRoute () {
      return this.$store.getters.subTypesForRoute
    },
    searchFilters () {
      return {
        name: this.substring(this.filters.name)
      }
    },
    itemSetFilters () {
      return {
        name: this.substring(this.filters.name),
        // itemSetTypeId: this.substring(this.filters.itemSetType),
        'class.name': this.substring(this.filters.itemSetClass?.name)
      }
    },
    questFilters () {
      return {
        name: this.substring(this.filters.name),
        locationId: Array.isArray(this.filters.location)
          ? this.in(this.filters.location)
          : this.like(this.filters.location),
        level: this.between([
          this.filters.minimumLevel,
          this.filters.maximumLevel
        ]),
        questSortId: Array.isArray(this.filters.questSort)
          ? this.in(this.filters.questSort)
          : this.like(this.filters.questSort)
      }
    },
    npcFilters () {
      return {
        name: this.substring(this.filters.name),
        'location.id': Array.isArray(this.filters.location)
          ? this.in(this.filters.location)
          : this.like(this.filters.location),
        minLevel: this.between([
          this.filters.minimumLevel,
          this.filters.maximumLevel
        ]),
        npcTypeId: this.like(this.filters?.npcType)
      }
    },
    zoneFilters () {
      return {
        name: this.substring(this.filters.name),
        ...(Array.isArray(this.filters.location) && { id: this.in(this.filters.location) }),
        ...(Array.isArray(this.filters.location) || { continent: this.like(this.filters.location) })
      }
    },
    spellFilters () {
      return {
        name: this.substring(this.filters?.name),
        'class.id': this.like(this.filters?.class?.id)
      }
    },

    locationFilters () {
      if (this.filters?.location?.length > 1) {
        return { in: this.filters.location.map(location => location) }
      } else {
        return this.like(this.filters?.location)
      }
    },
    checkedSourceFilters () {
      if (
        this.filters.checkedSources &&
        this.filters.checkedSources.length > 0
      ) {
        return this.filters.checkedSources.map(source => source.id)
      } else {
        return this.allSourceFilters.map(source => source.id)
      }
    },
    allSourceFilters () {
      return [
        {
          id: 0,
          name: 'Starting Item'
        },
        {
          id: 1,
          name: 'Vendor'
        },
        {
          id: 2,
          name: 'Quest'
        },
        {
          id: 3,
          name: 'World Drop'
        },
        {
          id: 4,
          name: 'Gathered'
        },
        {
          id: 5,
          name: 'Pickpocketed'
        },
        {
          id: 6,
          name: 'Profession'
        },
        {
          id: 7,
          name: 'Battleground'
        },
        {
          id: 8,
          name: 'Dungeon'
        },
        {
          id: 9,
          name: 'Raid'
        },
        {
          id: 10,
          name: 'Conjured'
        },
        {
          id: 11,
          name: 'Crafted'
        },
        {
          id: 12,
          name: 'Reputation'
        },
        {
          id: 13,
          name: 'PvP Reward'
        },
        {
          id: 14,
          name: 'Unavailable'
        },
        {
          id: 15,
          name: 'Eastern Kingdoms'
        },
        {
          id: 16,
          name: 'Kalimdor'
        },
        {
          id: 17,
          name: 'Darkmoon Faire'
        },
        {
          id: 18,
          name: 'Dragons of Nightmare'
        },
        {
          id: 19,
          name: 'Scourge Invasion'
        },
        {
          id: 20,
          name: 'Holiday'
        }
      ]
    },
    allSourceFilterIds () {
      return this.allSourceFilters.map(f => f.id)
    },
    itemFilters () {
      return {
        name: this.substring(this.filters.name),
        'itemSlot.id': Array.isArray(this.filters.itemSlot)
          ? this.in(this.filters.itemSlot)
          : this.like(this.filters.itemSlot),
        'itemQuality.id': this.between([
          this.filters.minimumQuality,
          this.filters.maximumQuality
        ]),
        'itemSubType.id': this.like(this.filters.itemSubType),
        itemTypeId: this.like(this.filters.itemType),
        requiredLevel: this.between([
          this.filters.minimumLevel,
          this.filters.maximumLevel
        ]),
        itemLevel: this.between([
          this.filters.minimumItemLevel,
          this.filters.maximumItemLevel
        ]),
        // phase: this.like(this.filters.phase),
        'class.name': this.substring(this.filters.class?.name)
        // itemSourceId: this.filters.checkedSources
        //  ? this.in(this.checkedSourceFilters)
        //  : this.in(this.allSourceFilterIds)
      }
    },
    objectFilters () {
      return {
        name: this.substring(this.filters.name)
        // gameObjectTypeId: this.like(this.filters.gameObjectType)
      }
    }
  }
}
