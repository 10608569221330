<template>
  <div class="hidden w-full bg-bg-500 lg:block ">
    <div class="flex flex-col items-end">
      <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
        <router-link to="/">
          <div class="flex items-start flex-shrink-0 px-4">
            <img
              class="h-14"
              :src="require('@/assets/logo.svg')"
              alt="Logo"
            >
          </div>
        </router-link>
        <div class="flex flex-col flex-1 w-56 p-4 mt-8">
          <VerticalNav expansion="tbc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalNav from '../../../../lib/src/components/VerticalNav.vue';

export default {
  components: {
    VerticalNav
  }
}
</script>
