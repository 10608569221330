<template>
  <div class="flex flex-col items-center h-[500px] justify-center">
    <div class="text-center space-y-3">
      <h1 class="text-6xl text-primary font-bold">
        404
      </h1>
      <h2 class="text-3xl text-primary font-bold">
        Page not found
      </h2>
      <p class="text-xl text-white">
        The page you are looking for does not exist.
      </p>
      <router-link
        to="/"
        class="text-primary transition-all "
      >
        <button class="text-primary bg-transparent border border-primary rounded px-2 py-1 mt-3 hover:text-black hover:bg-primary transition-all">
          Go Home
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  metaInfo () {
    return {
      title: 'Page Not Found',
      meta: [
        { property: 'og:title', vmid: 'og:title', content: 'Page Not Found' }
      ]
    }
  }
}
</script>
