import { mapActions, mapGetters, mapMutations } from 'vuex'
import npcTypes from '../store/npc/npc-types'

export default {

  methods: {
    ...mapActions([
      ...Object.values(npcTypes.actions)
    ]),
    ...mapMutations([
      ...Object.values(npcTypes.mutations)
    ])
  },

  computed: {
    ...mapGetters([
      ...Object.values(npcTypes.getters)
    ])
  }
}
