<template>
  <footer
    class="z-10 pt-12 pb-32 px-5 font-sans text-base border-t border-opacity-50 border-solid border-bg-200 bg-bg-500"
  >
    <div
      class="flex flex-col flex-wrap items-center justify-between w-full mx-auto space-y-10 text-base lg:min-w-[1000px] lg:space-y-0 lg:flex-row max-w-7xl"
    >
      <div class="text-sm text-center text-item-poor">
        <img
          src="@/assets/logo.svg"
          alt=""
          class="block w-20 max-w-full mx-auto mb-8 border-0"
        >
        <p>© {{ new Date().getFullYear() }} WoWClassicDB</p>
        <p>All rights reserved</p>
      </div>
      <div
        class="flex flex-col justify-center w-full max-w-screen-sm px-2 space-y-5 text-center md:space-y-0 md:flex-row lg:text-left"
      >
        <div class="md:mr-20">
          <div class="text-lg font-bold text-white">
            Navigation
          </div>
          <ul class="flex flex-row pt-3 md:flex-col ">
            <li
              v-for="link in navigation"
              :key="link.id"
              class="w-full mt-4 mb-0 text-center md:text-left "
            >
              <router-link
                :id="link.id"
                :to="link.href"
                class="relative font-semibold cursor-pointer text-item-poor hover:text-primary"
              >
                {{ link.name }}
              </router-link>
            </li>
          </ul>
        </div>
        <div>
          <div class="text-lg font-bold text-white">
            Our Communities
          </div>
          <ul class="flex flex-wrap pt-3">
            <li
              v-for="link in ourCommunities"
              :key="link.id"
              class="w-1/2 mt-4 mb-0 text-center list-none md:text-left"
            >
              <a
                :id="link.id"
                :href="link.href"
                class="relative py-0 pl-2 pr-0 font-semibold cursor-pointer text-item-poor hover:text-primary"
              >{{ link.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <a
        href="https://lexicon.gg/"
        alt="Lexicon's Site"
        class="flex-shrink-0 order-1"
      >
        <img
          src="@/assets/lexicon-logo.png"
          alt="Lexicon Logo"
          class="border-0 w-60"
        >
      </a>
    </div>
  </footer>
</template>

<script>

export default {
  data () {
    return {
      navigation: [
        { id: 'privacy', name: 'Privacy', href: '/privacy' },
        { id: 'terms', name: 'Terms', href: '/terms' },
        { id: 'contact', name: 'Contact Us', href: '/contact-us' }
      ],
      ourCommunities: [
        { id: 'community-warcraft-tavern', name: 'Warcraft Tavern', href: 'https://www.warcrafttavern.com/' },
        { id: 'community-eip', name: 'EIP Gaming', href: 'https://eip.gg/' },
        { id: 'community-diablo-tavern', name: 'Diablo Tavern', href: 'https://www.diablotavern.com/' },
        { id: 'community-riotwatch', name: 'RiotWatch', href: 'https://riotwatch.gg/' },
        { id: 'community-wow-classic-bis', name: 'WoW Classic BiS', href: 'https://www.wowclassicbis.com/' },
        { id: 'community-beyond-hogwarts', name: 'Beyond Hogwarts', href: 'https://beyondhogwarts.com/' },
        { id: 'community-warcraft-db', name: 'Warcraft DB', href: 'https://www.warcraftdb.com/' }
      ]

    }
  }
}
</script>
