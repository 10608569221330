<template>
  <div class="space-y-2">
    <label
        :for="name"
        class="text-left text-gray-300"
    >
      {{ label }}
    </label>
    <input
        type="text"
        :name="name"
        class="default-input"
        v-model="input"
    >
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'FilterInput',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    filterField: {
      type: String,
      required: true
    }
  },
  computed: {
    input: {
      get () {
        return this.$store.getters.listViewFilter(this.name)?.value || ''
      },
      set: debounce(function (value) {
        this.$store.commit('setListViewFilter', {
          name: this.name,
          field: this.filterField,
          operator: 'startsWith',
          value
        })
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
