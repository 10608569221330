<template>
  <div
    class="tooltip"
    :style="style"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    x: String,
    y: String,
    alignY: {
      type: String,
      default: 'top'
    },
    offsetX: {
      type: Number,
      default: 10
    },
    offsetY: {
      type: Number,
      default: 15
    }
  },
  computed: {
    style () {
      const styleObj = {}
      styleObj[this.alignY] = `calc(${this.y} + ${this.offsetY}px)`
      styleObj.left = `calc(${this.x} + ${this.offsetX}px)`
      return styleObj
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/colors';
@import '~@/scss/mixins';

.tooltip {
  z-index: 99999;
  position: absolute;
  white-space: nowrap;
  padding: 7px;
  background-color: rgba($color-bg-light, 0.95);

  @include drop-shadow-0;

  max-width: 500px;
}
</style>
