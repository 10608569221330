var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map"},[(_vm.locationId && _vm.isError === false)?_c('div',[(_vm.locationId)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded === true && _vm.isError === false),expression:"isLoaded === true && isError === false"}],class:!_vm.showFullscreenButton ? 'w-full h-auto max-h-[80vh]' : '',attrs:{"src":`https://cdn.wowclassicdb.com/maps/${_vm.locationId}.jpg`,"alt":"Map"},on:{"load":function($event){_vm.isLoaded = true}}}):_vm._e(),(_vm.showFullscreenButton)?_c('button',{staticClass:"absolute items-center justify-center hidden w-16 h-16 p-1 transition-all border md:flex focus:outline-none focus:ring-4 ring-primary bottom-5 right-5 text-bg-200 border-primary bg-bg-500 hover:bg-bg-400 hover:text-primary",on:{"click":_vm.open}},[_c('svg',{staticClass:"w-12 h-12",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"}})])]):_vm._e(),_vm._l((_vm.markersFormatted),function(marker,i){return [_c('router-link',{key:`marker-${i}`,attrs:{"to":`/npc/${marker.id}`,"target":"_blank"}},[(_vm.isLoaded === true)?_c('img',{staticClass:"map-marker",class:{ highlight: marker.highlight },style:({
            top: `calc(${marker.y}% - ${_vm.markerSize / 2}px)`,
            left: `calc(${marker.x}% - ${_vm.markerSize / 2}px${
              marker.offset ? ` + ${_vm.markerSize / 2}px` : ''
            })`,
            height: `${_vm.markerSize}px`
          }),attrs:{"src":marker.file,"alt":"Map Marker"},on:{"mouseover":function($event){return _vm.onMouseOver(i)},"mouseleave":_vm.onMouseLeave}}):_vm._e()])]}),(_vm.hoveredMarker)?_c('Tooltip',{staticClass:"h-",attrs:{"x":`${_vm.hoveredMarker.x}%`,"y":`${100 - _vm.hoveredMarker.y}%`,"align-y":"bottom"}},[_c('div',{staticStyle:{"display":"inline-grid","grid-template-columns":"fit-content fit-content"}},[_c('p',{staticStyle:{"grid-column":"1 / span 2","margin":"2px","display":"flex"}},[_c('span',{staticClass:"item-name misc"},[_vm._v(_vm._s(_vm.hoveredMarker.label))])]),_c('p',{staticStyle:{"grid-column":"1 / span 2","margin":"2px","display":"flex"}},[_c('span',[_vm._v("("+_vm._s(Math.round(_vm.hoveredMarker.x * 100) / 100)+", "+_vm._s(Math.round(_vm.hoveredMarker.y * 100) / 100)+")")])]),(_vm.hoveredMarker.description)?_c('p',{staticStyle:{"grid-column":"1 / span 2","margin":"2px","margin-top":"-2px","display":"flex"}},[_c('span',[_vm._v(_vm._s(_vm.hoveredMarker.description))])]):_vm._e()])]):_vm._e()],2):_vm._e(),(_vm.isError)?_c('div',[_c('h2',{staticClass:"text-3xl text-primary"},[_vm._v(" No Map Found ")])]):_vm._e(),(_vm.isLoaded === false && _vm.isError === false)?_c('div',[_c('hearth-loading')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }