<template>
  <article class="inline">
    <div
      v-if="priceToMoney.gold > 0"
      class="inline-flex items-center justify-center align-middle"
    >
      <span class="px-1 text-md">
        {{ priceToMoney.gold }}
      </span>
      <img
        class="block w-5 h-5"
        src="@/assets/img/icons/Gold.png"
        alt="Gold"
      >
    </div>
    <div
      v-if="priceToMoney.silver > 0"
      class="inline-flex items-center justify-center col-span-1 align-middle"
    >
      <span class="px-1 text-md">
        {{ priceToMoney.silver }}
      </span>
      <img
        class="w-5 h-5"
        src="@/assets/img/icons/Silver.png"
        alt="Silver"
      >
    </div>
    <div
      v-if="priceToMoney.copper > 0"
      class="inline-flex items-center justify-center col-span-1 align-middle"
    >
      <span class="px-1 text-md">
        {{ priceToMoney.copper }}
      </span>
      <img
        class="w-5 h-auto"
        src="@/assets/img/icons/Copper.png"
        alt="Copper"
      >
    </div>
  </article>
</template>

<script>
import moneyMixin from '../mixins/money-mixin'

export default {
  mixins: [moneyMixin]
}
</script>
