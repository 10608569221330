<template>
  <nav>
    <ExpansionDropdown :current-expansion="expansion" />
    <div class="space-y-4 mt-8">
      <ul
        v-for="nav of navItems"
        :key="nav.title"
        class="font-sans text-left text-base font-bold transition-all cursor-pointer"
      >
        <div
          v-if="nav.links"
          class="flex mr-2"
          @click="toggleNavSection(nav)"
        >
          <span>{{ nav.title }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex ml-4 w-4 h-4 my-auto transition-transform"
            :class="nav.expanded ? 'transform rotate-90' : 'text-gray-400'"
            fill="none"
            viewBox="0 0 24 24"
            alt="Navigation Dropdown"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
        <router-link
          v-else
          :to="nav.href"
        >
          {{ nav.title }}
        </router-link>
        <transition name="slide-fade">
          <ul
            v-if="nav.expanded === true"
            class="py-2 space-y-2"
          >
            <li
              v-for="link in nav.links"
              :key="link.href"
              class="pl-4 font-normal"
            >
              <router-link
                :to="link.href"
              >
                <button
                  :class="{'text-primary border-l-2 border-primary pl-2 ': active(link.href) }"
                  @click="navigate()"
                >
                  {{ link.name }}
                </button>
              </router-link>
            </li>
          </ul>
        </transition>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapMutations } from 'vuex'
import { tbcNavItems, vanillaNavItems, wotlkNavItems } from '../constants/navigation'
import ExpansionDropdown from './ExpansionDropdown.vue'
export default {
  name: 'VerticalNav',
  components: { ExpansionDropdown },
  props: {
    expansion: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      vanillaNav: vanillaNavItems,
      tbcNav: tbcNavItems,
      wotlkNav: wotlkNavItems
    }
  },
  computed: {
    navItems () {
      switch (this.expansion) {
        case 'vanilla':
          return this.vanillaNav
        case 'tbc':
          return this.tbcNav
        case 'wotlk':
          return this.wotlkNav
        default:
          return this.vanillaNav
      }
    }

  },
  methods: {
    active (href) {
      // Check if the current route contains the href
      return this.$route.path.includes(href)
    },
    navigate () {
      this.$store.commit('resetListViewFilter')
      this.toggleMobileSideNav()
    },
    toggleNavSection (nav) {
      nav.expanded = !nav.expanded
    },
    ...mapMutations(['toggleMobileSideNav'])
  }
}
</script>

<style lang="postcss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
