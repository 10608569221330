
import Vue, { PropType } from 'vue';
import { GameObject } from '../../../../lib/src/models/GameObject';
export default Vue.extend({
  name: 'ObjectTooltip',
  props: {
    gameObject: {
      type: Object as PropType<GameObject>,
      required: true
    }
  }

})
