import { render, staticRenderFns } from "./SpellTooltip.vue?vue&type=template&id=fd878b20&scoped=true&"
import script from "./SpellTooltip.vue?vue&type=script&lang=ts&"
export * from "./SpellTooltip.vue?vue&type=script&lang=ts&"
import style0 from "./SpellTooltip.vue?vue&type=style&index=0&id=fd878b20&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd878b20",
  null
  
)

export default component.exports