import api from '@/store/api-client'

import itemSetTypes from '@/store/modules/item-set/item-set-types'
const { state, actions, mutations, getters } = itemSetTypes

const itemState = () => ({
  [state.itemSets]: {},
  [state.selectedItemSet]: {}
})

const itemActions = {
  async [actions.fetchItemSet] ({ dispatch, commit }, id) {
    try {
      await dispatch(actions.fetchItemSetTypes)

      const itemSet = await api.getItemSet(id)
      commit(mutations.setItemSet, { id, ...itemSet })
      commit(mutations.setSelectedItemSet, itemSet)
    } catch (e) {
      console.error(`Couldn't retrieve Item Set with ID ${id}`)
      console.error(e)
    }
  },
  async [actions.fetchItemSetTypes] ({ dispatch }) {
    try {
      await dispatch('fetchTypes', 'item-set', { root: true })
    } catch (e) {
      console.error('Couldn\'t retrieve Item Set types')
    }
  }
}
const itemMutations = {
  [mutations.setItemSet] (state, data) {
    const { id } = data
    state[itemSetTypes.state.itemSets][id] = data
  },
  [mutations.setSelectedItemSet] (state, data) {
    state[itemSetTypes.state.selectedItemSet] = data
  }
}

const itemGetters = {
  [getters.itemSets] (state) {
    return state[itemSetTypes.state.itemSets]
  },
  [getters.selectedItemSet] (state) {
    return state[itemSetTypes.state.selectedItemSet]
  }
}

export default {
  namespaced: true,
  state: itemState,
  actions: itemActions,
  mutations: itemMutations,
  getters: itemGetters
}
