<template>
  <div class="p-2 sm:p-8">
    <h1
      class="font-sans text-lg cursor-pointer font-bold text-primary"
      @click="toggleCategories()"
    >
      <div class="flex items-center">
        <span>Categories</span>
        <div class="right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            class="ml-2"
            :class="{'active_chevron': showCategories }"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
    </h1>
    <ul
      v-if="showCategories"
      class="p-2 cursor-pointer"
    >
      <li
        v-for="(category, i) in types"
        :key="`col-${i}`"
        class="border border-bg-100 bg-bg-600"
      >
        <div class="row">
          <router-link
            class="left"
            :to="`/${singlePageParser($route.name)}/${removeWhiteSpaceAndApostrophes(lowerCase(category.name))}`"
          >
            {{ category.name }}
          </router-link>
          <div
            v-if="category.children"
            class="right"
            :class="{'active_chevron': category.name === activeCategory }"
            @click="expandCategory(category)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        <div class="">
          <div
            class="hidden"
            :class="{ 'visible': category.name === activeCategory}"
          >
            <div
              v-for="(subcategory, j) in category.children"
              :key="j"
            >
              <div class="row ml-2">
                <router-link
                  class="left"
                  :to="`/${singlePageParser($route.name)}/${link(category.name)}/${link(subcategory.name)}`"
                >
                  {{ subcategory.name }}
                </router-link>
                <div
                  v-if="subcategory.children"
                  class="right"
                  :class="{'active_chevron': subcategory.name === activeSubCategory }"
                  @click="expandSubCategory(subcategory)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-. 708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="hidden"
                :class="{ 'visible': subcategory.name === activeSubCategory}"
              >
                <div
                  v-for="(item, k) in subcategory.children"
                  :key="k"
                >
                  <div
                    class="row ml-4"
                  >
                    <router-link
                      class="left"
                      :to="`/${singlePageParser($route.name)}/${link(category.name)}/${link(subcategory.name)}/${link(item.name)}`"
                    >
                      {{ item.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { isInputInRoute, lowerCase, removeWhiteSpaceAndApostrophes, singlePageParser } from '../../../../lib/src/util/stringUtils'

export default {
  props: {
    types: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showCategories: false,
      activeCategory: '',
      activeSubCategory: '',
      activeLayer: -1
    }
  },
  methods: {
    isInputInRoute,
    removeWhiteSpaceAndApostrophes,
    lowerCase,
    singlePageParser,
    link (label) {
      // Replace any spaces or apostrophes with hyphens
      return label?.replace(/\s+|'/g, '-').toLowerCase()
    },
    toggleCategories () {
      this.showCategories = !this.showCategories
    },
    expandCategory (category) {
      this.activeCategory = category.name === this.activeCategory ? '' : category.name
    },
    expandSubCategory (subCategory) {
      this.activeSubCategory = subCategory.name === this.activeSubCategory ? '' : subCategory.name
    }
  }
}
</script>

<style lang="postcss" scoped>

  .active_chevron {
    transition: transform .4s ease-in-out;
    transform: rotate(90deg);
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .row .left {
    @apply font-sans text-lg text-primary;
    font-size: 1rem;

    &:hover {
      @apply font-bold;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .right svg {
    stroke: currentColor;
  }

  .hidden {
    display: hidden;
  }

  .visible {
    display: block;
  }

</style>
