<template>
  <div class="space-y-2">
    <label
        :for="name"
        class="text-left text-gray-300"
    >
      {{ label }}
    </label>
    <fieldset class="flex justify-between">
      <input
          v-model.number="inputMin"
          :name="name"
          type="number"
          min="0"
          required
          :max="max"
          class="default-input w-20"
      >
      <span class="px-2 py-1 text-2xl text-gray-500">-</span>
      <input
          v-model.number="inputMax"
          type="number"
          min="1"
          required
          :max="max"
          class="default-input w-20"
      >
    </fieldset>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'FilterRange',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    filterField: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      inputMin: 0,
      inputMax: this.max
    }
  },
  watch: {
    rangeData: debounce(function ({ inputMin, inputMax }, { oldInputMin, oldInputMax }) {
      // Check for string values
      if (!inputMin) inputMin = 0
      if (!inputMax) inputMax = this.max
      // Only process non default values
      if (inputMin === oldInputMin && inputMin === 0 && inputMax === oldInputMax && inputMax === this.max) return

      const options = {}
      if (inputMin === 0) {
        // Only max value set
        options.operator = 'lte'
        options.value = inputMax
      } else if (inputMax === this.max) {
        // Only min value set
        options.operator = 'gte'
        options.value = inputMin
      } else {
        // Both set
        options.operator = 'between'
        options.value = [inputMin, inputMax]
      }

      this.$store.commit('setListViewFilter', {
        name: this.name,
        field: this.filterField,
        ...options
      })
    }, 500)
  },
  computed: {
    rangeData () {
      const { inputMin, inputMax } = this
      return { inputMin, inputMax }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
