
import { Race } from '@/models/Race'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'RaceTooltip',
  props: {
    race: {
      type: Object as PropType<Race>,
      required: true
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    hasAllowedClass () {
      return this.race?.allowedClasses?.length > 0
    }
  }
})
