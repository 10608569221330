
export default {
  actions: {
    fetchNpc: 'fetchNPC',
    fetchNpcTypes: 'fetchNpcTypes'
  },
  mutations: {
    setNpc: 'setNPC',
    setSelectedNpcId: 'setSelectedNpcId',
    resetSelectedNpc: 'resetSelectedNpc'
  },
  getters: {
    npcs: 'npcs',
    selectedNpc: 'selectedNpc',
    primarySpawnLocation: 'primarySpawnLocation'
  }
}
