import { Achievement } from './Achievement'
import { ItemNPCLootTable, ItemNPCVendor } from './Item'
import { Quest } from './Quest'
import RelatedTableFilterOption, { setRelatedTable } from './RelatedTableFilterOption'
import { Spawn } from './Spawn'
import { Location } from './Zone'

type NPCLocation = {
  locationId: number
  npcId: number
}

export type NPCLocations = Location & NPCLocation

export type NPCType = {
  id: number
  name: string
}

export type NPCSpawn = {
  areaId: number
  id: number
  locationId: number
  location: Location
  npcId: number
  posX: number
  posY: number
}

export type PaginatedData<T> = {
  currentPage: number
  data: Array<T>
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export type NPCDto = {
  boss: boolean
  sells: PaginatedData<ItemNPCVendor>
  drops: PaginatedData<ItemNPCLootTable>
  dropsNormal10: PaginatedData<ItemNPCLootTable>
  dropsNormal25: PaginatedData<ItemNPCLootTable>
  dropsHeroic10: PaginatedData<ItemNPCLootTable>
  dropsHeroic25: PaginatedData<ItemNPCLootTable>
  pickpocketing: PaginatedData<ItemNPCLootTable>
  gathering: PaginatedData<ItemNPCLootTable>
  mining: PaginatedData<ItemNPCLootTable>
  skinning: PaginatedData<ItemNPCLootTable>
  salvage: PaginatedData<ItemNPCLootTable>
  civilian: boolean
  factionId: number
  id: number
  locations: NPCLocations[]
  maxLevel: number
  spells: PaginatedData<any>
  trains: PaginatedData<any>
  maxLootGold: number
  startsQuests: PaginatedData<Quest>
  endsQuests: PaginatedData<Quest>
  objectiveOf: PaginatedData<Quest>
  minLevel: number
  minLootGold: number
  name: string
  npcSpawns: NPCSpawn[]
  npcFamilyId: number
  npcRankId: number
  npcRank: {
    id: number
    name: string
  }
  npcTypeId: number
  npcType: NPCType
  title: string
  typeAI: string
  criteriaOf: PaginatedData<Achievement>
  events: PaginatedData<any>
}

export type LootTableNPC = {
  dropChance: number
  itemId: number
  maxCount: number
  minCount: number
  npcId: number
}

export type QuestNPCRelations = {
   npcId: number
   questId: number
}

export type NPCWithLootTable = {
  LootTableNPC: LootTableNPC
} & NPCDto

export type QuestNPC = {
  QuestNPCRelations: QuestNPCRelations
} & NPCDto

export type NPCProps = {
  npc: NPCDto,
}

export class NPC {
  #entity: NPCDto

  constructor (props: NPCProps) {
    const { npc } = props

    this.#entity = npc
  }

  get id () {
    return this.#entity.id
  }

  get imageUrl () {
    return `https://cdn.wowclassicdb.com/npcs/${this.#entity.id}.png`
  }

  get name () {
    return this.#entity.name
  }

  get title () {
    return this.#entity.title
  }

  get maxLevel () {
    return this.#entity.maxLevel
  }

  get minLevel () {
    return this.#entity.minLevel
  }

  get locations () {
    return this.#entity.locations
  }

  get npcSpawns () {
    return this.#entity.npcSpawns
  }

  get spawnPoints () {
    return new Spawn(this.#entity).getSpawnPoints()
  }

  get primarySpawnLocation () {
    return this.#entity.locations ? this.#entity.locations[0] : null
  }

  get sells () {
    return this.#entity.sells
  }

  get drops () {
    return this.#entity.drops
  }

  get dropsNormal10 () {
    return this.#entity.dropsNormal10
  }

  get dropsNormal25 () {
    return this.#entity.dropsNormal25
  }

  get dropsHeroic10 () {
    return this.#entity.dropsHeroic10
  }

  get dropsHeroic25 () {
    return this.#entity.dropsHeroic25
  }

  get skinning () {
    return this.#entity.skinning
  }

  get salvage () {
    return this.#entity.salvage
  }

  get pickpocketing () {
    return this.#entity.pickpocketing
  }

  get mining () {
    return this.#entity.mining
  }

  get gathering () {
    return this.#entity.gathering
  }

  get spells () {
    return this.#entity.spells
  }

  get trains () {
    return this.#entity.trains
  }

  get npcType () {
    return this.#entity.npcType
  }

  get npcRank () {
    return this.#entity.npcRank
  }

  get boss () {
    return this.#entity.boss
  }

  get civilian () {
    return this.#entity.civilian
  }

  get criteriaOf () {
    return this.#entity.criteriaOf
  }

  get events () {
    return this.#entity.events
  }

  // Maybe remove the none option
  get relatedTableFilterOptions () {
    return [
      setRelatedTable(this.#entity?.startsQuests, 'startsQuests', '', RelatedTableFilterOption.startsQuests),
      setRelatedTable(this.#entity?.endsQuests, 'endsQuests', '', RelatedTableFilterOption.endsQuests),
      setRelatedTable(this.#entity?.sells, 'sells', '', RelatedTableFilterOption.sells),
      setRelatedTable(this.#entity?.drops, 'drops', '', RelatedTableFilterOption.drops),
      setRelatedTable(this.#entity?.dropsNormal10, 'dropsNormal10', 'Normal-10', RelatedTableFilterOption.drops),
      setRelatedTable(this.#entity?.dropsNormal25, 'dropsNormal25', 'Normal-25', RelatedTableFilterOption.drops),
      setRelatedTable(this.#entity?.dropsHeroic10, 'dropsHeroic10', 'Heroic-10', RelatedTableFilterOption.drops),
      setRelatedTable(this.#entity?.dropsHeroic25, 'dropsHeroic25', 'Heroic-25', RelatedTableFilterOption.drops),
      setRelatedTable(this.#entity?.objectiveOf, 'objectiveOf', '', RelatedTableFilterOption.objectiveOf),
      setRelatedTable(this.#entity?.spells, 'spells', '', RelatedTableFilterOption.spells),
      setRelatedTable(this.#entity?.trains, 'trains', '', RelatedTableFilterOption.teaches),
      setRelatedTable(this.#entity?.pickpocketing, 'pickpocketing', '', RelatedTableFilterOption.pickpocketing),
      setRelatedTable(this.#entity?.mining, 'mining', '', RelatedTableFilterOption.mining),
      setRelatedTable(this.#entity?.gathering, 'gathering', '', RelatedTableFilterOption.gathering),
      setRelatedTable(this.#entity?.skinning, 'skinning', '', RelatedTableFilterOption.skinning),
      setRelatedTable(this.#entity?.salvage, 'salvage', '', RelatedTableFilterOption.salvage),
      setRelatedTable(this.#entity?.criteriaOf, 'criteriaOf', '', RelatedTableFilterOption.criteriaOf),
      setRelatedTable(this.#entity?.events, 'events', '', RelatedTableFilterOption.events),
      new RelatedTableFilterOption({
        key: 'comments',
        title: 'Comments',
        isDisabled: true,
        urlPrefix: ''
      }),
      new RelatedTableFilterOption({
        key: 'screenshots',
        title: 'Screenshots',
        isDisabled: true,
        urlPrefix: ''

      })
    ].filter(Boolean)
  }
}
