const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatTimestampsIntoDate = (timestamp1: EpochTimeStamp, timestamp2: EpochTimeStamp) => {
  if (timestamp1 === 0 || timestamp2 === 0) return null;
  const date1 = new Date(timestamp1 * 1000);
  const date2 = new Date(timestamp2 * 1000);
  const formattedDate1 = formatDate(date1);
  const formattedDate2 = formatDate(date2);
  return `${formattedDate1} - ${formattedDate2}`;
}

export const formatTimestampIntoDateWithTime = (timestamp: EpochTimeStamp) => {
  if (timestamp === 0) return null;
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
