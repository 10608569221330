
import Vue, { PropType } from 'vue';
import { NPC, NPCDto } from '../../../../lib/src/models/NPC';
export default Vue.extend({
  name: 'NPCTooltip',
  props: {
    npc: {
      type: Object as PropType<NPCDto>,
      required: true
    }
  },
  data () {
    return {
      data: new NPC({ npc: this.npc })
    }
  }
})
