<template>
  <div>
    <div class="hidden lg:block items-center flex-grow min-w-0 px-8 py-6 space-y-6">
      <div class="flex flex-grow w-full space-x-4 max-w-7xl">
        <div class="flex-grow w-full ">
          <SearchBar />
        </div>
        <router-link
          to="/login"
          class="relative flex items-center justify-center  w-24 py-2 text-sm font-bold text-center  text-gray-300 opacity-10 transition border   border-transparent rounded-md shadow-sm   pointer-events-none hover:bg-primary  focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-offset-gray-800   hover:text-black focus:ring-primary"
        >
          <span>Log in</span>
        </router-link>
        <router-link
          to="/register"
          class="relative flex items-center justify-center  w-24 py-2 text-sm font-bold text-center  text-gray-300 opacity-10 transition border   border-transparent rounded-md shadow-sm   pointer-events-none hover:bg-primary  focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-offset-gray-800   hover:text-black focus:ring-primary"
        >
          <span>Sign Up</span>
        </router-link>
      </div>
      <div
        class="flex flex-wrap items-start max-w-full gap-3"
      >
        <div
          v-for="dropdown in baseTypes"
          :key="dropdown.name"
        >
          <router-link :to="`/${singlePageParser($route.name)}/${removeWhiteSpaceAndApostrophes(lowerCase(dropdown.name))}`">
            <DropdownButton
              :name="dropdown.name"
              :data="dropdown.children"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div
      v-if="baseTypes.length > 0"
      class="lg:hidden"
    >
      <MobileCategories :types="baseTypes" />
    </div>
  </div>
</template>

<script>
import DropdownButton from '@/components/DropdownButton.vue'
import MobileCategories from '@/components/MobileCategories.vue'
import SearchBar from '@/components/SearchBar.vue'
import { isInputInRoute, lowerCase, removeWhiteSpaceAndApostrophes, singlePageParser } from '../util/stringUtils'

export default {
  components: {
    DropdownButton,
    SearchBar,
    MobileCategories
  },
  computed: {
    baseTypes () {
      return Array.isArray(this.$store.getters.baseTypes) ? this.$store.getters.baseTypes : []
    }
  },
  methods: {
    isInputInRoute,
    removeWhiteSpaceAndApostrophes,
    lowerCase,
    singlePageParser
  }

}
</script>
