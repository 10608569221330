<template>
  <nav class="absolute z-50 flex items-center w-full h-20 px-5 text-white bg-opacity-75 lg:px-20 lg:px-30 xl:px-40 bg-bg-600 ">
    <div class="flex relative items-center w-full h-full">
      <OffCanvasNav :expansion="currentExpansion" />
      <button
        type="button"
        class=" text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
        @click="toggleSideMenu"
      >
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg
          class="w-8 h-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <router-link to="/">
        <img
          class="w-16 h-auto ml-4"
          alt="WoW Classic DB Logo"
          src="@/assets/logo.svg"
        >
      </router-link>
      <div
        class="items-center justify-center hidden w-full h-full text-base font-bold md:flex nav-links"
        :class="{ bordervanilla: currentExpansion === 'vanilla', bordertbc: currentExpansion === 'tbc', borderwotlk: currentExpansion === 'wotlk'}"
      >
        <router-link
          v-for="link in navbarLinks"
          :id="link.id"
          :key="link.id"
          class="h-7 pt-1 uppercase border-opacity-0  hover:border-opacity-100"
          :to="link.route"
        >
          {{ link.title }}
        </router-link>
      </div>
      <div class="absolute right-0 expansionDropdownWrapper">
        <ExpansionDropdown :current-expansion="currentExpansion" />
      </div>
      <div class="absolute right-0 hidden lg:flex socialMediaIcons">
        <a
          href="https://twitter.com/warcrafttavern?lang=en"
          target="_blank"
          class="nav-social rounded-md"
        >
          <img
            class="nav-social w-8 p-1 bg-footer-text"
            src="@/assets/img/icons/twitter.svg"
            alt="Twitter Icon"
          >
        </a>
        <a
          href="https://www.facebook.com/wctavern/"
          target="_blank"
          class="nav-social rounded-md"
        >
          <img
            class="nav-social w-8 p-1 bg-footer-text"
            src="@/assets/img/icons/facebook.svg"
            alt="Facebook Icon"
          >
        </a>
        <a
          href="https://discord.com/invite/YXC35My"
          target="_blank"
          class="nav-social rounded-md"
        >
          <img
            class="nav-social w-8 p-1 bg-footer-text"
            src="@/assets/img/icons/discord.svg"
            alt="Discord Icon"
          >
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import ExpansionDropdown from './ExpansionDropdown.vue'
import OffCanvasNav from './OffCanvasNav'

export default {
  name: 'Navbar',
  components: { OffCanvasNav, ExpansionDropdown },
  data () {
    return {
      currentExpansion: 'vanilla',
      navbarLinks: [
        { id: 'nav-database', title: 'Database', route: '/items' },
        { id: 'nav-items', title: 'Items', route: '/items' },
        // { id: 'nav-atlas', title: 'Atlas', route: '/atlas' },
        { id: 'nav-tools', title: 'Tools', route: '/tools/tooltip' }
      ],
      sideNavLinks: [
        { title: 'Database', icon: require('@/assets/img/icons/database-fill.svg'), route: '/' },
        { title: 'Items', icon: require('@/assets/img/icons/sword-fill.svg'), route: '/items' },
        // { title: 'Atlas', icon: require('@/assets/img/icons/earth-fill.svg'), route: '/atlas' },
        { title: 'Tools', icon: require('@/assets/img/icons/tools-fill.svg'), route: '/tools' }
      ]
    }
  },
  mounted () {
    const base = this.$router.options.base.toString()
    if (base.includes('tbc')) {
      this.currentExpansion = 'tbc'
    } else if (base.includes('wotlk')) {
      this.currentExpansion = 'wotlk'
    } else if (base === '/') {
      this.currentExpansion = 'vanilla'
    }
  },
  methods: {
    toggleSideMenu () {
      this.$store.commit('toggleMobileSideNav')
    }
  }
}

</script>

<style lang="scss" scoped>

.socialMediaIcons {
  a {
    background-color: currentColor;
    opacity: 0.5;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.nav-links {
  a {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .router-link-exact-active {
    border-bottom-width: 2px
  }

  margin-right: 128px;
  @media only screen and (max-width: 1023px) {
    margin-right: 0px !important;
  }
}

.bordervanilla {
  a {
    border-color: rgba(255, 162, 0, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(255, 162, 0, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.bordertbc {
  a {
    border-color: rgba(31, 166, 68, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(31, 166, 68, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.borderwotlk {
  a {
    border-color: rgba(11, 24, 38, var(--tw-border-opacity));
  }
  a:hover {
    color: rgba(11, 24, 38, var(--tw-border-opacity));
    border-bottom-width: 2px
  }
}

.expansionDropdownWrapper {
  margin-right: 128px;
  @media only screen and (max-width: 1023px) {
    margin-right: 0px !important;
  }
}

</style>
