import { Route } from 'vue-router'
import { camelCase, depluralize, pipe } from '../util'
import { BaseType, BaseTypeChild, BaseTypeFilter, ChildChild } from './../models/Filter'
import { getSafeRouteName, kebabCase, removeApostrophes } from './stringUtils'

const buildRouteForType = ({ route, type }: { route: Route; type: BaseType }) => {
  const routeHasFirstParam = route.params.first !== undefined
  const routeHasSecondParam = route.params.second !== undefined
  const routeHasThirdParam = route.params.third !== undefined

  const routeHasParams = (routeHasFirstParam || routeHasSecondParam || routeHasThirdParam)
  // TODO: Use .split(/) here for a better determination of params
  if (routeHasParams) {
    if (routeHasFirstParam) {
    }
    // Must be first only
    if (!routeHasSecondParam) {
    }
    // Must be third
  } else {
    const routeName = route.name
    const paramName = kebabCase(type.name)
    return `/${routeName}/${paramName}`
  }

  // Instead, let's build the route and not care about any prior types.

  return route
}

export const onTypeChanged = (payload: any) => {
  const { fieldName, filters, route, baseTypes } = payload
  // When the TableFilter Type changes from <Armor> to <Ranged>
  // Find the correct type(s) from the baseTypes
  const matcher = baseTypes?.filter((baseType: BaseType) => {
    if (baseType.filter) {
      const filterName = camelCase(baseType.filter.table)
      const filterId = baseType?.filter.id

      const isBaseFilter = filterName === fieldName && filterId === filters[fieldName]

      return isBaseFilter
    }
  })[0]

  if (matcher) {
    return buildRouteForType({ route, type: matcher })
  }
}

export const filtersFromTypes = (types: any) => {
  let firstFilter = {}

  if (types) {
    const { first } = types
    if (first?.filter) {
      const _type = pipe(camelCase)(first.filter.table)
      const id = first.filter.id

      firstFilter = {
        [_type]: id
      }
    }
  }
  return firstFilter
}

export const baseTypes = (types: any, routeName: any) => {
  return types[depluralize(routeName)] ?? []
}

/**
 * This should return us a list of current filterable types for a route.
 *
 * An example here would be:
 *
 * A user navigates to /items. The filterable types here include things like:
 *
 * - Armor
 * - Weapons
 * - Ranged Items
 * @param types
 * @param route
 * @returns
 */
export const extractTypes = (types: BaseType[] | BaseTypeChild[] | undefined, name: string): BaseType | BaseTypeChild | undefined => {
  if (Array.isArray(types) && name) {
    for (const type of types) {
      if (getSafeRouteName(removeApostrophes(type.name.toLowerCase())) === name) {
        return type
      }
      if (type.children) {
        const didMatch = extractTypes(type.children, name?.toLowerCase())
        if (didMatch) {
          return didMatch
        }
      }
    }
  }

  return undefined
}

export const parseFilter = (filter: BaseTypeFilter | BaseTypeFilter[] | undefined) => {
  if (filter) {
    if (Array.isArray(filter)) {
      let filters = {}
      for (const f of filter) {
        filters = {
          ...filters,
          [camelCase(f.table)]: f.id
        }
      }
      return filters
    } else {
      return {
        [camelCase(filter.table)]: filter.id
      }
    }
  }

  return {}
}

export const getFiltersForRoute = (baseTypes: BaseType[] | BaseTypeChild[] | undefined, path: string) => {
  let filters = {}
  const routePaths = path.split('/').filter(p => p.length > 0).filter(Boolean)
  const filteredPaths = routePaths.map(path => path.replace(/-/gi, ' '))
  for (const path of filteredPaths) {
    filters = { ...filters, ...parseFilter(extractTypes(baseTypes, path)?.filter) }
  }
  // console.log(extractTypes(baseTypes, filteredPaths[0])?.filter)
  return filters
}

export const getMetaForRoute = (baseTypes: BaseType[] | BaseTypeChild[] | undefined, path: string) => {
  const meta = []
  const routePaths = path.split('/').filter(p => p.length > 0).filter(Boolean)
  if (routePaths.includes('item-sets')) {
    meta.push('Item Sets')
  }

  if (routePaths.includes('spells')) {
    meta.push('Spells')
  }
  if (routePaths.includes('quests')) {
    meta.push('Quests')
  }
  if (routePaths.includes('npcs')) {
    meta.push('NPCs')
  }
  routePaths.shift()

  // console.log(routePaths)
  if (routePaths.length === 3 && !routePaths.includes('armor')) {
    routePaths.splice(1, 1)
  }
  if (routePaths.includes('armor')) {
    routePaths.reverse()
  }
  if (routePaths.length === 2 && meta.includes('NPCs')) {
    routePaths.splice(0, 1)
  }

  if (routePaths.length === 2 && meta.includes('Quests')) {
    routePaths.splice(0, 1)
  }
  // console.log(routePaths)

  const joinedRoutePaths = routePaths.map(path => path.replace(/-/gi, ' '))
  for (const path of joinedRoutePaths) {
    meta.push(extractTypes(baseTypes, path)?.name)
  }

  if (meta.includes('Spells') || meta.includes('NPCs') || meta.includes('Quests') || meta.includes('Item Sets')) {
    meta.reverse()
  }
  return meta
}

export const extractFiltersForDropdown = (items: BaseTypeChild[] | ChildChild[] | undefined) => {
  if (items) {
    let filters: any[] = []
    for (const item of items) {
      filters = [
        ...filters,
        {
          name: item.name,
          ...{ ...parseFilter(item.filter) }
        }]
    }

    return filters
  }
}

export const getSubTypesForRoute = (baseTypes: BaseType[] | BaseTypeChild[] | undefined, path: string) => {
  const routePaths = path.split('/').filter(p => p.length > 0).filter(Boolean)

  const lastPath = routePaths[routePaths.length - 1] || ''
  let filters = extractFiltersForDropdown(extractTypes(baseTypes, lastPath)?.children)

  if (!filters || filters.length === 0) {
    // Check behind one
    const lastPath = routePaths[routePaths.length - 2] || ''
    filters = extractFiltersForDropdown(extractTypes(baseTypes, lastPath)?.children)
  }

  return filters
}

export const getMetaTitleForRoute = (baseTypes: BaseType[] | BaseTypeChild[] | undefined, path: string) => {
  const routePaths = path.split('/').filter(p => p.length > 0).filter(x => !isNumber(x)).filter(Boolean)
  const lastPath = getSafeRouteName(routePaths[routePaths.length - 1]) || ''
  const metaTitle = extractTypes(baseTypes, lastPath)?.meta
  return metaTitle
}

export const isNumber = (input: any): boolean => {
  return typeof (input * 1) === 'number' && isFinite(input);
}
