<template>
  <div
    id="tooltip"
    class="p-2 space-y-2 text-left min-w-max"
  >
    <div>
      <h3
        v-if="item.name"
        class="font-bold"
        :style="{'color': '#' + item.items[0]?.itemQuality?.colorCode }"
      >
        {{ item.name }}
      </h3>
      <div class="flex justify-between">
        <h4
          v-if="item.itemSetTier && item.itemSetTier.name"
          class="text-item-artifact"
        >
          {{ item.itemSetTier.name }}
        </h4>
        <h4 v-if="item.itemSetType && item.itemSetType.name">
          {{ item.itemSetType.name }}
        </h4>
      </div>
    </div>
    <div v-if="item.allowedClasses.length > 0">
      <span>Classes</span>
      <ul>
        <li
          v-for="(itemClass, index) in item.allowedClasses"
          :key="index"
          class=""
        >
          <span v-if="itemClass.name">
            <span :style="{ color: '#' + itemClass.colorCode }">{{
              itemClass.name
            }}</span>
            <span v-if="index + 1 < item.allowedClasses.length">,</span>
          </span>
        </li>
      </ul>
    </div>
    <div>
      <div class="font-sans text-lg font-bold text-white ">
        Set Bonuses
      </div>
      <ul
        v-if="item.spells"
        class="max-w-xs"
      >
        <div
          v-for="(itemSetBonus, i) in itemSetBonuses"
          :key="i"
        >
          <a
            v-if="itemSetBonus.description"
            class="flex m-1 text-left"
            style="grid-column: 1 / span 2;"
          >
            <div><span class="mr-2 text-gray-300">{{ itemSetBonus.ItemSetBoni.amountOfPieces }} Pieces:</span><span class="transition-colors cursor-pointer text-item-uncommon hover:text-white">{{ itemSetBonus.description }}</span></div></a>
          <a
            v-if="!itemSetBonus.description && itemSetBonus.name.length"
            class="flex m-1 text-left"
            style="grid-column: 1 / span 2;"
          >
            <div><span class="mr-2 text-gray-300">{{ itemSetBonus.ItemSetBoni.amountOfPieces }} Pieces:</span><span class="transition-colors cursor-pointer text-item-uncommon hover:text-white">{{ parseSpellEffectDescription(itemSetBonus) }}</span></div></a>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { parseSpellEffectDescription } from '../../../../lib/src/util/stringUtils'
export default Vue.extend({
  name: 'ItemSetTooltip',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemSetBonuses: []
    }
  },
  mounted () {
    this.sortItemSetBonuses()
  },
  methods: {
    parseSpellEffectDescription,
    sortItemSetBonuses () {
      this.itemSetBonuses = this.item.spells.sort((a, b) => {
        return a.ItemSetBoni.amountOfPieces - b.ItemSetBoni.amountOfPieces
      })
    }
  }
})
</script>

<style lang="postcss" scoped>

#tooltip {
  top: 5000px;
  left: 5000px;
}
</style>
