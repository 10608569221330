import tooltip from '../directives/tooltip.js'

export default {
  directives: {
    tooltip
  },
  computed: {
    shouldShowTooltip () {
      return this.$store.getters.shouldShowTooltip && this.tooltipItem
    },
    tooltipItem () {
      return this.$store.state.tooltips.current.data
    },
    tooltipType () {
      return this.$store.state.tooltips.current.type
    }
  },
  methods: {
    setTooltip (tooltipInfo) {
      this.$store.commit('setCurrentTooltip', tooltipInfo, { root: true })
      this.$store.commit('setHighlightedId', tooltipInfo.data.id, { root: true })
    },
    clearTooltip () {
      this.$store.commit('clearTooltip')
      this.$store.commit('setHighlightedId', null, { root: true })
    }
  }
}
