<template>
  <div
    class="fixed inset-0 p-5 z-50 transition-opacity bg-opacity-75 bg-bg-300"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      v-click-outside="close"
      class="flex p-10 max-h-[95vh] overflow-hidden text-left transform rounded-lg drop-shadow-md bg-bg-500"
    >
      <button
        type="button"
        class="rounded-md absolute top-4 right-4 text-primary focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary"
        @click="close"
      >
        <span class="sr-only">Close</span>
        <svg
          class="w-10 h-10"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div
        class="flex items-center justify-center w-12 h-12 rounded-full bg-bg-900 sm:h-10 sm:w-10"
      >
        <!-- Hero Icons: Adjustments -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          />
        </svg>
      </div>
      <div class="flex flex-col ml-4">
        <h3
          id="modal-title"
          class="text-xl flex font-medium leading-6 text-gray-100"
        >
          Map
        </h3>
        <WorldMap
          v-if="locationId"
          class="mt-2"
          :markers="markers ? markers : []"
          :show-fullscreen-button="false"
          :location-id="locationId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WorldMap from '@/components/Map.vue';
import vClickOutside from 'v-click-outside';
export default {
  components: { WorldMap },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    markers: {
      type: Array
    },
    locationId: {
      type: Number,
      default: 0
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
