<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 z-50 flex lg:hidden"
    role="dialog"
    aria-modal="true"
  >
    <transition
      enter-class="opacity-0"
      enter-active-class="transition-opacity duration-300 ease-linear"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="transition-opacity duration-300 ease-linear"
      leave-to-class="opacity-0"
    >
      <div
        class="fixed inset-0 bg-gray-600 bg-opacity-75"
        aria-hidden="true"
        @click="toggleSideNav()"
      />
    </transition>

    <transition
      enter-class="transition duration-300 ease-in-out transform"
      enter-active-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="transition duration-300 ease-in-out transform"
      leave-from="translate-x-0"
      leave-to="-translate-x-full"
    >
      <div
        v-click-outside="toggleSideNav"
        class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-bg-600"
      >
        <transition
          enter-class="duration-300 ease-in-out"
          enter-active-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="duration-300 ease-in-out"
          leave-active-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div class="absolute top-0 right-0 pt-2 -mr-12">
            <button
              type="button"
              class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              @click="toggleSideNav"
            >
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="w-6 h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </transition>
        <router-link to="/">
          <div class="flex items-center flex-shrink-0 px-4 mt-6">
            <img
              class="w-auto h-16 mx-auto"
              src="@/assets/logo.svg"
              alt="WoW Classic DB Logo"
            >
          </div>
        </router-link>
        <div class="flex-1 h-0 px-6 py-4 mt-5 overflow-y-auto">
          <VerticalNav :expansion="expansion" />
        </div>
      </div>
    </transition>

    <div
      class="flex-shrink-0 w-14"
      aria-hidden="true"
    >
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import VerticalNav from './VerticalNav.vue'
export default {
  components: {
    VerticalNav
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    expansion: {
      type: String,
      required: true
    }
  },
  computed: {
    isOpen () {
      return this.$store.state.ui.isMobileSideNavOpen
    }
  },
  methods: {
    toggleSideNav () {
      this.$store.commit('toggleMobileSideNav')
    }
  }
}
</script>
