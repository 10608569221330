
import { Class } from '@/models/Class'
import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'ClassTooltip',
  props: {
    classData: {
      type: Object as PropType<Class>,
      required: true
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  }
})
