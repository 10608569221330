export default {
  state: {
    itemSets: 'itemSets',
    selectedItemSet: 'selectedItemSet'
  },
  actions: {
    fetchItemSet: 'fetchItemSet',
    fetchItemSetTypes: 'fetchItemSetTypes'
  },
  mutations: {
    setItemSet: 'setItemSet',
    setSelectedItemSet: 'setSelectedItemSet'
  },
  getters: {
    itemSets: 'itemSets',
    selectedItemSet: 'selectedItemSet'
  }
}
