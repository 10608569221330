<template>
  <div class="flex flex-col gap-5 mb-5 text-left px-1">
    <!-- Master selection -->
    <div class="space-y-2 master-selection">
      <label
          for="additional-filters-master"
          class="text-left text-gray-300"
      >
        Additional Filters
      </label>
      <div
        v-for="(selection, i) in selectedFields"
        class="flex flex-row"
        :key="i"
      >
        <!-- Main Field -->
        <select
            name="additional-filters-master"
            class="default-input main-field"
            v-model="selectedFields[i].field"
            @change="change(i)"
        >
          <option :value="null">Select Additional Filter</option>
          <optgroup
              v-for="filter in filters"
              :key="filter.model"
              :label="filter.model === '_b' ? 'General' : generateLabel(filter.model)"
          >
            <option
                v-for="field in filter.fields"
                :key="`${i}-${filter.model}-${field.name}`"
                :value="{ ...field, model: filter.model }"
            >
              {{ isSelected(selectedFields[i].field, filter, field) }} {{ generateLabel(field.name) }}
            </option>
          </optgroup>
        </select>

        <!-- Operator -->
        <select
          v-if="selection.field"
          class="default-input operator ml-2"
          v-model="selectedFields[i].operator"
        >
          <option
              v-for="operator in getOperators(selection.field.type)"
              :key="`${i}-${operator.value}`"
              :value="operator.value"
          >
            {{ operator.label }}
          </option>
        </select>

        <!-- Value -->
        <input
          v-if="selection.field && selection.field.type !== 'BOOLEAN'"
          :type="selection.field && selection.field.type === 'INTEGER' ? 'number' : 'text'"
          class="default-input value ml-2"
          :class="{ number: selection.field.type === 'INTEGER' }"
          min="0"
          v-model="selectedFields[i].value"
          @keydown="setValue(i)"
          @change="setValue(i)"
        />
        <select
          v-else-if="selection.field"
          class="default-input value boolean ml-2"
          v-model="selectedFields[i].value"
          @change="setValue(i)"
        >
          <option :value="true">True</option>
          <option :value="false">False</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ListViewFiltersAdditional',
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedFields: [{ field: null, operator: 'eq', value: null }]
    }
  },
  methods: {
    generateLabel (name) {
      name = name.charAt(0).toUpperCase() + name.slice(1)
      return name.replace(/([a-z])([A-Z0-9])/g, '$1 $2')
    },
    change (idx) {
      let selectedFields = [...this.selectedFields]

      // Reset value and operator on changed field
      if (selectedFields[idx].value) {
        selectedFields[idx].value = null
        selectedFields[idx].operator = 'eq'
        this.setValue(idx)
      }

      // Delete all empty fields except one
      selectedFields = selectedFields.filter(f => f.field)
      selectedFields.push({ field: null, operator: 'eq', value: null })
      this.selectedFields = selectedFields
    },
    getOperators (type) {
      const options = [{ value: 'eq', label: 'Equals' }]

      if (type === 'INTEGER') {
        options.push({ value: 'gt', label: 'Greater Than' })
        options.push({ value: 'lt', label: 'Less Than' })
      } else if (type === 'STRING') {
        options.push({ value: 'startsWith', label: 'Starts With' })
        options.push({ value: 'endsWith', label: 'Ends With' })
        options.push({ value: 'substring', label: 'Contains' })
        options.push({ value: 'regexp', label: 'RegExp' })
      }

      return options
    },
    isSelected (selectedField, filter, field) {
      if (selectedField?.model === '_b') return ''
      if (selectedField?.model === filter.model && selectedField?.name === field.name) return filter.model
      return ''
    },
    setValue: debounce(function (idx) {
      const name = `additional-filter-${idx}`
      const model = this.selectedFields[idx].field?.model
      const field = this.selectedFields[idx].field?.name
      const through = this.selectedFields[idx].field?.through
      const value = this.selectedFields[idx].value
      const operator = this.selectedFields[idx].operator
      this.$store.commit('setListViewFilter', {
        name,
        model,
        field,
        value,
        operator,
        through
      })
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.main-field {
  max-width: 250px;
}
.operator {
  max-width: 100px;
}
.value {
  max-width: 200px;

  &.number {
    max-width: 100px;
  }

  &.boolean {
    max-width: 100px;
  }
}
</style>
