
import Vue from 'vue';
import { formatTimestampIntoDateWithTime } from '../../../../lib/src/util';
export default Vue.extend({
  name: 'EventTooltip',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    formatTimestampIntoDateWithTime
  }
})
