<template>
  <div>
    <transition name="fade">
      <div
        v-if="tooltip"
        id="tooltip"
        class="fixed z-50 transition-opacity bg-bg-dark border-2 border-tooltip-border p-1 box-border bg-opacity-95 min-w-max"
        :style="{ top: `${tooltip?.posY}px`, left: `${tooltip?.posX}px` }"
      >
        <p
          class="m-0.5 flex"
        >
          <span class="item-name text-primary">{{ tooltip?.data.name }}</span>
        </p>
        <p
          class="m-0.5 flex"
        >
          <span>{{ tooltip?.data.exclusiveGroup ? 'Only one required' : 'Required quest' }}</span>
        </p>
        <p
          class="m-0.5 flex"
        >
          <span>{{ `Takes place in ${tooltip?.data.location}` }}</span>
        </p>
        <p
          v-if="tooltip?.data.startedBy"
          class="m-0.5 flex"
        >
          <span>{{ `Started by ${tooltip?.data.startedBy}` }}</span>
        </p>
      </div>
    </transition>

    <div class="relative max-w-3xl overflow-auto">
      <svg
        :width="width"
        :height="height + nodeSize * 2"
      >
        <g
          v-if="dag"
          class="flex flex-col"
        >
          <!-- Links -->
          <path
            v-for="(link, i) in dag.links()"
            :key="`link-${i}`"
            :d="line(link.points)"
            :stroke-width="nodeSize - 2"
            fill="none"
            class="flex flex-row connector"
            :class="{ before: link.target.data.type === 'beforeQuest' }"
            :stroke-dasharray="link.source.data.exclusiveGroup ? `${nodeSize * 2} ${nodeSize / 2}` : 'none'"
          />

          <!-- Nodes -->
          <router-link
            v-for="(node, i) in dag.descendants()"
            :key="`node-${i}`"
            :to="`/quest/${node.data.id}`"
          >
            <rect
              :x="node.y - (parseInt(node.data.id) === activeQuest ? nodeSize * 2.5 : nodeSize) / 2"
              :y="node.x - (parseInt(node.data.id) === activeQuest ? nodeSize * 2 : nodeSize) / 2"
              :width="parseInt(node.data.id) === activeQuest ? nodeSize * 2 : nodeSize"
              :height="parseInt(node.data.id) === activeQuest ? nodeSize * 2 : nodeSize"
              class="node"
              @mouseover="e => onMouseMove(e, node)"
              @mouseleave="onMouseLeave"
            />
          </router-link>

          <!-- Node labels -->
          <foreignObject
            v-for="(node, i) in dag.descendants()"
            :key="`text-${i}`"
            :height="50"
            :width="textWidth"
            :x="node.y - textWidth / 2"
            :y="node.x + (parseInt(node.data.id) === activeQuest ? nodeSize * 2 : nodeSize)"
          >
            <span class="node-label">{{ node.data.name }}</span>
          </foreignObject>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { coordVert, dagStratify, decrossOpt, layeringLongestPath, sugiyama } from 'd3-dag'
import { curveCatmullRom, line } from 'd3-shape'

export default {
  name: 'QuestChain',
  props: {
    activeQuest: {
      type: Number,
      default: 0
    },
    questChain: {
      type: Array,
      default: () => []
    },
    nodeSize: {
      type: Number,
      default: 9
    },
    textWidth: {
      type: Number,
      default: 100
    },
    width: {
      type: Number,
      default: 1000
    },
    height: {
      type: Number,
      default: 200
    }
  },
  data () {
    return {
      dag: null,
      line: null,
      tooltip: null
    }
  },
  watch: {
    width () {
      this.render()
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    render () {
      const layout = sugiyama()
        .size([this.height, this.width])
        .layering(layeringLongestPath())
        .decross(decrossOpt())
        .coord(coordVert())

      this.dag = dagStratify()(this.questChain)
      layout(this.dag)

      this.line = line()
        .curve(curveCatmullRom)
        .x(d => d.y)
        .y(d => d.x)
    },
    onMouseMove (e, hoveredElement) {
      // Pass data from the data node on the graph to the tooltip
      this.tooltip = hoveredElement

      this.tooltip.posY = e.clientY; // tooltip appears above the center of the element, adjust as needed
      this.tooltip.posX = e.clientX + 50; // tooltip is horizontally centered on the element, adjust as needed
    },
    onMouseLeave () {
      this.tooltip = null
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

  @import '~@/scss/colors';
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track-piece {
      background: #1d1d1d;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 24px;
      background-color: $color-primary;
      width: 50px;
    }

  svg {
    .node {
      fill: white;
      transform-box: fill-box;
      transform-origin: center;
      transform: rotate(45deg);
      cursor: pointer;

      &:hover {
        fill: $color-bg-highlight;
        stroke: $color-primary;
        stroke-width: 2px;
      }
    }

    .connector {
      stroke: $color-bg-highlight;

      &.before {
        stroke: $color-primary;
      }

      &.exclusive {
        stroke: red;
      }
    }

    .node-label {
      font-size: 0.7em;
      display: inline-block;
      width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

</style>

