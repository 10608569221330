<template>
  <nav
    class="flex mb-3 mr-auto"
    aria-label="Breadcrumb"
    itemscope="itemscope"
    itemtype="http://schema.org/Breadcrumb"
  >
    <ol
      itemscope="itemscope"
      itemtype="http://schema.org/BreadcrumbList"
      class="flex px-6 mb-2 space-x-4 border-2 rounded-md shadow-lg border-bg-200 bg-bg-900"
    >
      <li class="flex">
        <div class="flex items-center">
          <router-link
            id="breadcrumb-0"
            to="/"
            class="text-gray-300 hover:text-white"
          >
            <!-- Icon: https://github.com/HearthSim/hs-icons -->
            <svg
              class="w-5 h-5 text-indigo-200 transition duration-500 transform hover:text-blue-500 hover:scale-110 hover:shadow-2xl"
              version="1.1"
              viewBox="0 0 128 128"
              xml:space="preserve"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m55.09 50.96s18.116-1.506 18.587 15.481c0.47 16.987-16.235 19.339-26.587 18.869-10.352-0.471-32.232-14.822-30.585-37.644l1.277-8.128 3.639-4.185s8.369-16.921 27.474-22.015c0 0 1.637-2.729 3.639-2.729 2.001 0 3.639 2.183 6.55 2.547s4.003-2.729 6.732-2.547 2.911 2.183 5.822 2.729 17.103 2.183 28.565 17.831c0 0 0.546 4.003 2.183 4.731s5.458 2.365 6.004 3.639 4.367 14.919 4.003 20.742c-0.364 5.822-2.001 32.932-20.014 41.301l-0.364 4.912s-13.464 12.008-34.024 10.553-26.096-3.621-30.93-10.189c-0.666-0.905-1.637-4.185-1.637-4.185l-1.819-3.821s-1.637-2.729 1.819-2.183c3.457 0.546 16.011 5.458 22.925 5.64s10.553-3.457 11.826-3.821c1.274-0.364 8.733-3.275 10.917-3.639 2.183-0.364 15.647-8.602 17.649-28.408s-15.83-31.451-26.019-31.633c0 0-6.368-0.182-7.824 0.728s-10.917 5.276-15.101 5.822c0 0-3.457 3.275-2.911 7.278s0.546 17.78 15.101 17.805c0 0 3.476-1.876 3.103-15.481z"
              />
            </svg>
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <template v-for="(b, i) in breadcrumbs">
        <li
          :id="'breadcrumb-' + (i + 1)"
          :key="'link' + i"
          itemprop="itemListElement"
          itemscope="itemscope"
          itemtype="http://schema.org/ListItem"
          class="flex"
        >
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 w-6 h-full text-bg-200"
              viewBox="0 0 24 44"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <template v-if="i != breadcrumbs.length - 1">
              <router-link
                itemprop="item"
                :to="b.link"
                class="ml-4 text-item-poor hover:text-white hover:underline"
              >
                <a itemprop="item">
                  <span itemprop="name">{{ b.name }}</span>
                </a>
              </router-link>
            </template>
            <span
              v-else
              class="ml-4 font-medium"
            >
              {{ b.name }}
            </span>
          </div>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  computed: {
    breadcrumbs () {
      // Generate breadcrumbs from URL
      const breadcrumbs = []
      const routeSplit = this.$route.path.split('/').slice(1)
      // Go through all route params and make breadcrumbs with correct links out of them, e.g.:
      // items -> /items
      // armor -> /items/armor
      // ...
      for (const [i, route] of routeSplit.entries()) {
        let name;
        const split = route.split('-').map(this.formatRouteSplit)
        name = split.join(' ')
        // If the certain part of the route contains a number, i.e. '-123123', don't format it as the - is intended.
        if (/\d/.test(route)) {
          name = route
        }
        if (i === 0) {
          breadcrumbs.push({
            name: `${this.shouldPluralizeRootRoute(name)}`,
            link: `/${this.shouldPluralizeRootRoute(route)}`
          })
        } else {
          if (name.trim() !== '') {
            breadcrumbs.push({
              name: this.getNameFromStore(breadcrumbs[i - 1].name, name),
              link: `${breadcrumbs[i - 1].link}/${route}`
            })
          }
        }
      }
      return breadcrumbs
    }
  },
  methods: {
    getNameFromStore (base, id) {
      if (base === 'NPCs') {
        try {
          return this.$store.state.npc?.npcs[id]?.name
        } catch (e) {
          return id
        }
      } else if (base === 'Item Sets') {
        try {
          return this.$store.state?.itemSets[id]?.name
        } catch (e) {
          return id
        }
      } else {
        try {
          const key = base?.toLowerCase()
          return this.$store.state[key][id].name
        } catch (e) {
          return id
        }
      }
    },
    shouldPluralizeRootRoute (rootRoute) {
      if (rootRoute.toLowerCase() === 'class') return 'Classes'
      const isNotPlural = /.*[^s]$/.test(rootRoute)

      if (isNotPlural) {
        return `${rootRoute}s`
      }
      return rootRoute
    },
    formatRouteSplit (routeName) {
      const isNPC = /^npc$/.test(routeName)
      const isNPCs = /^npcs$/.test(routeName)

      if (isNPC) {
        return `${routeName.toUpperCase()}s`
      } else if (isNPCs) {
        return `${routeName.slice(0, 3).toUpperCase()}${routeName.charAt(3)}`
      }
      return `${routeName.charAt(0).toUpperCase()}${routeName.slice(1)}`
    }
  }
}
</script>
