<template>
  <div class="space-y-2">
    <label
        :for="name"
        class="text-left text-gray-300"
    >
      {{ label }}
    </label>
    <multiselect
        v-model="input"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :placeholder="placeholder"
        :options="values"
        :hide-selected="true"
        label="name"
        :name="name"
        track-by="value"
        class="px-0 mr-2 default-input"
        :show-labels="false"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'FilterMultiSelect',
  components: { Multiselect },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    filterField: {
      type: String,
      required: true
    },
    filterModel: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      required: true
    }
  },
  computed: {
    placeholder () {
      const pluralize = (input) => {
        if (input.slice(-1) === 'y') return `${input.slice(0, -1)}ies`
        if (input.slice(-2) === 'ss') return `${input}es`
        if (input.slice(-1) !== 's') return input + 's'
        return input
      }
      return `Select ${pluralize(this.label)}`
    },
    input: {
      get () {
        return this.$store.getters.listViewFilter(this.name)?.value || []
      },
      set (value) {
        this.$store.commit('setListViewFilter', {
          name: this.name,
          field: this.filterField,
          operator: 'in',
          model: this.filterModel ? this.filterModel : undefined,
          value
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.multiselect__tags {
  @apply bg-bg-900 border-none text-white rounded;
}

.multiselect__content-wrapper {
  @apply bg-bg-300 text-footer-text border-bg-600;
}

.multiselect__option--highlight {
  @apply bg-bg-500;
}

.multiselect__tag {
  @apply bg-primary text-black rounded;
}

.multiselect__tag-icon {
  @apply hover:bg-bg-300 rounded-none;
}

.multiselect__tag-icon::after {
  @apply text-white;
}

.multiselect__input {
  @apply bg-bg-500;
}
</style>
