import { formatTimestampsIntoDate } from '../util';
import { getLocationsFromGameObject, getLocationsFromNPC, kebabCase } from '../util/stringUtils';
import { Achievement } from './Achievement';
import { GameObject } from './GameObject';
import Icon from './Icon';
import { Item, ItemNPCLootTable, ItemNPCVendor, ItemSet, ItemSoldBy, ItemSource, Skill, Spell } from './Item';
import { NPC, NPCLocations, NPCWithLootTable, PaginatedData } from './NPC';
import { Quest } from './Quest';
import { TableColumn } from './Table';
import { Title } from './Title';
import { Location, Zone } from './Zone';

type Row = {
  value?: string | number | Item[] | Skill[];
  isMoney?: boolean
  isReagents?: boolean
  isTokens?: boolean
  isCurrency?: boolean
  thumbnail?: string
  image?: string;
  alt?: string;
  isSkills?: boolean
}

type _RowAccessor<T> = (item: T) => Row[]

type ItemRowAccessor = _RowAccessor<Item>
type AnyRowAccessor = _RowAccessor<any>

export type RowAccessor = AnyRowAccessor

export type RelatedTableFilterOptionProps = {
  key: string
  title?: string
  isDisabled: boolean
  column?: TableColumn[],
  data?: any,
  rowAccessor?: RowAccessor,
  urlPrefix: string
}

export function setRelatedTable (data: PaginatedData<any>, key: string, title: string, RelatedTableOption: Function) {
  if (data) {
    const table = data.data && data.data.length ? RelatedTableOption(key, title, data) : null
    return table
  }
}

export function retrieveCreatedItemFromSpellIfApplicable (spell: Spell): Spell | Item {
  // Retrieve the item created by a spell, otherwise just return the spell
  if (spell && spell.SpellEffects && spell.SpellEffects.length > 0) {
    let item: Item = {} as Item
    // For each effect, check if an item is created
    spell.SpellEffects.forEach((effect: any) => {
      // If so, assign it to the item variable
      if (effect.item) {
        item = effect.item
      }
    }
    )
    // If the item variable has been assigned, return it, else return the spell
    if (item && item.icon) { return item } else return spell
  } else {
    return spell
  }
}
export default class RelatedTableFilterOption {
  readonly #key: string
  readonly #title?: string
  readonly #isDisabled: boolean
  readonly #column: TableColumn[]
  readonly #data: any[]
  readonly #rowAccessor?: RowAccessor | undefined
  readonly #urlPrefix?: string

  constructor (props : RelatedTableFilterOptionProps) {
    const { key, title, isDisabled, data, column, rowAccessor, urlPrefix } = props

    this.#key = key
    this.#title = title
    this.#isDisabled = isDisabled
    this.#column = column ?? []
    this.#data = data ?? []
    this.#rowAccessor = rowAccessor
    this.#urlPrefix = urlPrefix ?? undefined
  }

  static criteriaOf (key: string, title: string | undefined, data: PaginatedData<Achievement>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Criteria Of',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (achievement: Achievement) => [{
        thumbnail: Icon.fromName(achievement.icon),
        value: achievement.name
      }],
      urlPrefix: 'achievement'
    })
  }

  static sameModelAsObject (key: string, title: string | undefined, data: PaginatedData<GameObject>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Same Model As',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Type', sortable: true },
        { label: 'Location', sortable: false }
      ],
      data,
      rowAccessor: (data: GameObject) => [
        {
          value: data.name
        },
        {
          value: data.gameObjectType?.name
        },
        {
          value: getLocationsFromGameObject(data)
        }
      ],
      urlPrefix: 'object'
    })
  }

  static sameModelAs (key: string, title: string | undefined, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Same Model As',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Type', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon?.name ? item.icon.name : item.icon),
          thumbnailHeight: 30
        },
        { value: item.itemSubType?.name }
      ],
      urlPrefix: 'item'
    })
  }

  static sells (key: string, title: string | undefined, data: PaginatedData<ItemNPCVendor>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Sells',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Type', sortable: true },
        { label: 'Stock', sortable: true },
        { label: 'Stack Size', sortable: true },
        { label: 'Price', sortable: true }
      ],
      data,
      rowAccessor: (item: any) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon),
          thumbnailHeight: 30
        },
        { value: item.itemSubType?.name },
        { value: item.npcVendorItems?.maxCount || 'Unlimited' },
        { value: item.buyCount },
        {
          value: item.npcVendorItems?.requiredItem1 || item.npcVendorItems?.requiredItem2 || item.npcVendorItems?.requiredCurrency1 || item.npcVendorItems?.requiredCurrency2 ? item.npcVendorItems : item.buyPrice,
          isMoney: item.buyPrice > 0,
          isTokens: item.npcVendorItems?.requiredItem1 || item.npcVendorItems?.requiredItem2,
          isCurrency: item.npcVendorItems?.requiredCurrency1 || item.npcVendorItems?.requiredCurrency2
        }
      ],
      urlPrefix: 'item'
    })
  }

  static currencyFor (key: string, title: string | undefined, data: PaginatedData<any>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Currency For',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Price', sortable: true }
      ],
      data,
      rowAccessor: (VendorItemData: any) => [
        {
          value: VendorItemData.item.name,
          thumbnail: Icon.fromName(VendorItemData?.item?.icon),
          thumbnailHeight: 30
        },
        {
          value: VendorItemData,
          isCurrency: VendorItemData.requiredCurrency1 || VendorItemData.requiredCurrency2,
          isTokens: VendorItemData.requiredItem1 || VendorItemData.requiredItem2
        }
      ],
      urlPrefix: 'item'
    })
  }

  static rewardFromQuest (key: string, title: string | undefined, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Reward From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Exp.', sortable: true },
        { label: 'Money', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) => [
        { value: quest.name },
        { value: quest.level },
        { value: quest.rewardExperience },
        { value: quest.rewardMoney, isMoney: true }
      ],
      urlPrefix: 'quest'
    })
  }

  static items (key: string, title: string | undefined, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Items',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Req.', sortable: true },
        { label: 'Slot', sortable: true },
        { label: 'Type', sortable: true },
        { label: 'Source', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon),
          thumbnailHeight: 30
        },
        { value: item.requiredLevel },
        { value: item.itemSlot?.name },
        { value: item.itemSubType?.name },
        { value: item.itemSource?.name }
      ],
      urlPrefix: 'item'
    })
  }

  static canContain (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Can Contain',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Req.', sortable: true },
        { label: 'Type', sortable: true },
        { label: 'Source', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon),
          thumbnailHeight: 30
        },
        { value: item.requiredLevel },
        { value: item.itemSubType?.name },
        { value: item.itemSource?.name }
      ],
      urlPrefix: 'item'
    })
  }

  static canBePlacedIn (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Can Be Placed In',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Slots', sortable: true },
        { label: 'Type', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon),
          thumbnailHeight: 30
        },
        { value: item.containerSlots },
        { value: item.itemSubType?.name }
      ],
      urlPrefix: 'item'
    })
  }

  static diet (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Diet',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Food Type', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          value: item.name,
          thumbnail: Icon.fromName(item.icon?.name),
          thumbnailHeight: 30
        },
        { value: item.itemSubType?.name }
      ],
      urlPrefix: 'item'
    })
  }

  static itemSets (key: string, title: string | undefined, data: PaginatedData<ItemSet>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Item Sets',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Classes', sortable: true },
        { label: 'Req. Level', sortable: true },
        { label: 'Item Level', sortable: true }
      ],
      data,
      rowAccessor: (itemSet: ItemSet) => [
        {
          value: itemSet.name
        },
        {
          value: itemSet.allowedClasses.map((itemSetClass: any) => itemSetClass.name).join(', ')
        },
        {
          value: itemSet.items[0]?.requiredLevel
        },
        {
          value: itemSet.items[0]?.itemLevel
        }
      ],
      urlPrefix: 'item-set'
    })
  }

  static usedBy (key: string, title: string | undefined, data: PaginatedData<ItemSet>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Used By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Classes', sortable: true },
        { label: 'Req. Level', sortable: true },
        { label: 'Item Level', sortable: true }
      ],
      data,
      rowAccessor: (itemSet: ItemSet) => [
        {
          value: itemSet.name
        },
        {
          value: itemSet.allowedClasses.map((itemSetClass: any) => itemSetClass.name).join(', ')
        },
        {
          value: itemSet.items[0]?.requiredLevel
        },
        {
          value: itemSet.items[0]?.itemLevel
        }
      ],
      urlPrefix: 'item-set'
    })
  }
  // Maybe remove the none option

  static none (key: string, title: string | undefined) {
    return new RelatedTableFilterOption(
      {
        key: '',
        title: title,
        isDisabled: false,
        urlPrefix: ''
      }
    )
  }

  static itemSource (key: string, title: string | undefined, data: ItemSource[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Source',
      isDisabled: false,
      column: [
        { label: 'Where', sortable: true }
      ],
      data,
      rowAccessor: (item: ItemSource) => [
        { value: item.name }
      ],
      urlPrefix: 'item'
    })
  }

  static zones (key: string, title: string, data: Zone[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Zones',
      isDisabled: false,
      column: [
        { label: 'Zone', sortable: true }
      ],
      data,
      rowAccessor: (loc: Zone) => [
        { value: loc.name }
      ],
      urlPrefix: 'zone'
    })
  }

  static fishedIn (key: string, title: string, data: Zone[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Fished In',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Area', sortable: true },
        { label: 'Chance to Fish (%)', sortable: true }

      ],
      data,
      rowAccessor: (loc: any) => [
        { value: loc.name },
        { value: loc.fishingLoot[0]?.FishingTableZone?.area },
        { value: Number(Math.round(loc.fishingLoot[0]?.FishingTableZone?.dropChance * 10000) / 100) }
      ],
      urlPrefix: 'zone'
    })
  }

  static locations (key: string, title: string, data: NPCLocations[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Locations',
      isDisabled: false,
      column: [
        { label: 'Zone', sortable: true }
      ],
      data,
      rowAccessor: (loc: NPCLocations) => [
        { value: loc.name }
      ],
      urlPrefix: 'zone'
    })
  }

  static soldBy (key: string, title: string, data: ItemSoldBy[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Sold By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Price', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => [
        {
          thumbnail: Icon.fromName(npc.id, true),
          value: npc.name
        },
        {
          value: getLocationsFromNPC(npc)
        },
        {
          value: npc.npcVendorItems?.requiredItem1 || npc.npcVendorItems?.requiredItem2 || npc.npcVendorItems?.requiredCurrency1 || npc.npcVendorItems?.requiredCurrency2 ? npc.npcVendorItems : npc.sells[0].buyPrice,
          isMoney: npc.sells[0].buyPrice > 0,
          isTokens: npc.npcVendorItems?.requiredItem1 || npc.npcVendorItems?.requiredItem2,
          isCurrency: npc.npcVendorItems?.requiredCurrency1 || npc.npcVendorItems?.requiredCurrency2
        }
      ],
      urlPrefix: 'npc'
    })
  }

  static castedBy (key: string, title: string, data: NPC[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Casted By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Location', sortable: false }
      ],
      data,
      rowAccessor: (npc: NPC) => [
        {
          thumbnail: Icon.fromName(npc.id, true),
          value: npc.name
        },
        {
          value: getLocationsFromNPC(npc)
        }
      ],
      urlPrefix: 'npc'
    })
  }

  static taughtBy (key: string, title: string, data: NPC[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Taught By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Required Skill', sortable: true }

      ],
      data,
      rowAccessor: (npc: any) => [
        {
          thumbnail: Icon.fromName(npc.id, true),
          value: npc.name
        },
        {
          value: getLocationsFromNPC(npc)
        },
        {
          value: npc.trains[0].NPCTrainerSpells.requiredSkillValue
        }
      ],
      urlPrefix: 'npc'
    })
  }

  static achievements (key: string, title: string, data: PaginatedData<Achievement>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Achievements',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Points', sortable: true },
        { label: 'Category', sortable: true }
      ],
      data,
      rowAccessor: (achievement: Achievement) => [{
        thumbnail: Icon.fromName(achievement.icon),
        value: achievement.name
      }, { value: achievement.points }, { value: achievement.achievementCategory?.name }],
      urlPrefix: 'achievement'
    })
  }

  static iconUsedByItem (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Used By Item',
      isDisabled: false,
      column: [
        { label: 'Item Name', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [{
        thumbnail: Icon.fromName(item.icon),
        value: item.name
      }],
      urlPrefix: 'item'
    })
  }

  static iconUsedBySpell (key: string, title: string, data: Spell[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Used By Spell',
      isDisabled: false,
      column: [
        { label: 'Spell Name', sortable: true }
      ],
      data,
      rowAccessor: (spell: Spell) => [{
        thumbnail: Icon.fromName(spell.icon),
        value: spell.name
      }],
      urlPrefix: 'spell'
    })
  }

  static iconUsedByAchievement (key: string, title: string, data: Achievement[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Used By Achievement',
      isDisabled: false,
      column: [
        { label: 'Achievement Name', sortable: true }
      ],
      data,
      rowAccessor: (achievement: Achievement) => [{
        thumbnail: Icon.fromName(achievement.icon),
        value: achievement.name
      }],
      urlPrefix: 'achievement'
    })
  }

  static usedByItem (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Used By Item',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Item Level', sortable: false }

      ],
      data,
      rowAccessor: (item: Item) => [
        {
          thumbnail: Icon.fromName(item.icon),
          value: item.name
        },
        {
          value: item.itemLevel
        }
      ],
      urlPrefix: 'item'
    })
  }

  static spells (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Abilities',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'School', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          value: spell.spellSchool?.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static events (key: string, title: string, data: PaginatedData<any>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Events',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Category', sortable: true },
        { label: 'Date', sortable: true }
      ],
      data,
      rowAccessor: (event: any) => [
        {
          thumbnail: Icon.fromName(event.icon),
          value: event.name
        },
        {
          value: event.eventCategory?.name
        },
        {
          value: formatTimestampsIntoDate(event.startTime, event.endTime)
        }
      ],
      urlPrefix: 'event'
    })
  }

  static petTalents (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Talents',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'School', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: `https://cdn.wowclassicdb.com/icons/${spell.icon?.name}.jpg`,
          value: spell.name
        },
        {
          value: spell.spellSchool?.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static ranks (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Ranks',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Rank', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          value: spell.subtext
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static searchSpells (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Spells',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Rank', sortable: true },
        { label: 'Class', sortable: true },
        { label: 'School', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          value: spell.subtext
        },
        {
          value: spell.class?.name
        },
        {
          value: spell.spellSchool?.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static searchCreatureSpells (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Creature Spells',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Rank', sortable: true },
        { label: 'Class', sortable: true },
        { label: 'School', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          value: spell.subtext
        },
        {
          value: spell.class?.name
        },
        {
          value: spell.spellSchool?.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static reagentFor (key: string, title: string, data: Spell[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Reagent For',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(retrieveCreatedItemFromSpellIfApplicable(spell).icon),
          value: spell.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static createdBy (key: string, title: string, data: Spell[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Created By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Reagents', sortable: false }
      ],
      data,
      rowAccessor: (spell: Spell) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          isReagents: true,
          value: spell.reagents
        }

      ],
      urlPrefix: 'spell'
    })
  }

  static toolFor (key: string, title: string, data: Spell[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Tool For',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (spell: Spell) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static teaches (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Teaches',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Req. Level', sortable: true },
        { label: 'Cost', sortable: true },
        { label: 'Req. Skill', sortable: true }
      ],
      data,
      rowAccessor: (spell: any) => [
        {
          thumbnail: Icon.fromName(retrieveCreatedItemFromSpellIfApplicable(spell).icon),
          value: spell.name
        },
        {
          value: spell.taughtBy[0]?.NPCTrainerSpells?.level
        },
        {
          value: spell.taughtBy[0]?.NPCTrainerSpells?.cost,
          isMoney: true
        },
        {
          value: spell.taughtBy[0]?.NPCTrainerSpells?.requiredSkillValue
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static npcs (key: string, title: string, data: PaginatedData<NPC>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'NPCs',
      isDisabled: false,
      column: [
        { label: 'NPC', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false }
      ],
      data,
      rowAccessor: (npc: NPC) => [
        {
          thumbnail: Icon.fromName(npc.id, true),
          value: npc.name
        },
        {
          value: npc.maxLevel
        },
        {
          value: getLocationsFromNPC(npc)
        }
      ],
      urlPrefix: 'npc'
    })
  }

  static factions (key: string, title: string, data: any[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Factions',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (faction: any) => [
        { value: faction.name }
      ],
      urlPrefix: 'faction'
    })
  }

  static subLocations (key: string, title: string, data: PaginatedData<Location>) {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Sub Locations',
      isDisabled: false,
      data,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Suggested Level', sortable: true }
      ],
      rowAccessor: (zone: Location) => {
        return [
          { value: zone.name },
          { value: zone.suggestedLevel }
        ]
      },
      urlPrefix: ''
    })
  }

  static containedIn (key: string, title: string, data: GameObject[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Contained In',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (object: any) => [
        {
          value: object.name
        },
        { value: Number(Math.round(object.contains[0]?.LootTableGameObject?.dropChance * 10000) / 100) + '%' }
      ],
      urlPrefix: 'object'
    })
  }

  static fishedFrom (key: string, title: string, data: GameObject[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Fished From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Chance to Fish (%)', sortable: true }
      ],
      data,
      rowAccessor: (object: any) => [
        {
          value: object.name
        },
        { value: Number(Math.round(object.fishing[0]?.FishingTableGameObject?.dropChance * 10000) / 100) + '%' }
      ],
      urlPrefix: 'object'
    })
  }

  static unlocks (key: string, title: string, data: GameObject[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Unlocks',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }, { label: 'Location', sortable: false }

      ],
      data,
      rowAccessor: (object: GameObject) => [
        {
          value: object.name
        },
        {
          value: object.gameObjectSpawns[0]?.location?.name
        }
      ],
      urlPrefix: 'object'
    })
  }

  static minedFrom (key: string, title: string, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Mined From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.mining[0]?.MiningLootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static gatheredFrom (key: string, title: string, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Gathered From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.gathering[0]?.GatheringLootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static objects (key: string, title: string, data: PaginatedData<GameObject>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Objects',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Type', sortable: true },
        { label: 'Location', sortable: false }
      ],
      data,
      rowAccessor: (data: GameObject) => [
        {
          value: data.name
        },
        {
          value: data.gameObjectType?.name
        },
        {
          value: getLocationsFromGameObject(data)
        }
      ],
      urlPrefix: 'object'
    })
  }

  // for title page related table.
  static rewardFromAchievement (key: string, title: string, data: Achievement[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Reward From',
      isDisabled: false,
      column: [
        { label: 'Achievement Name', sortable: true },
        { label: 'Side', sortable: true },
        { label: 'Points', sortable: true },
        { label: 'Title Reward', sortable: true },
        { label: 'Category', sortable: true }
      ],
      data,
      rowAccessor: (achievement: Achievement) => [{
        thumbnail: Icon.fromName(achievement.icon),
        value: achievement.name
      },
      { value: achievement.titles ? achievement.titles[0]?.side?.name : '' },
      { value: achievement.points },
      { value: achievement.titles ? achievement.titles[0]?.name.replace('%s', '<Name>') : '' },
      { value: achievement.achievementCategory.name }],
      urlPrefix: 'achievement'
    })
  }

  static races (key: string, title: string, data: any[]): RelatedTableFilterOption {
    const baseUrl = process.env.VUE_APP_BASE_URL
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Races',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (race: any) => [{
        thumbnail: `${baseUrl}/img/race_${race.name.replace(/\s/g, '').toLowerCase()}_male.jpg`,
        value: race.name
      }],
      urlPrefix: 'race'
    })
  }

  static classes (key: string, title: string, data: any[]): RelatedTableFilterOption {
    const baseUrl = process.env.VUE_APP_BASE_URL
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Classes',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (classObj: any) => [{
        thumbnail: `${baseUrl}/img/Class_${classObj.name.replace(/\s/g, '')}.svg`,
        value: classObj.name
      }],
      urlPrefix: 'class'
    })
  }

  // achievement page, rewards related table
  static achievementRewards (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Rewards',
      isDisabled: false,
      column: [
        { label: 'Item', sortable: true },
        { label: 'Type', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [{
        thumbnail: Icon.fromName(item.icon),
        value: item.name
      }, { value: item.itemSubType?.name }],
      urlPrefix: 'item'
    })
  }

  static questRewards (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Quest Rewards',
      isDisabled: false,
      column: [
        { label: 'Item', sortable: true },
        { label: 'Type', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [{
        thumbnail: Icon.fromName(item.icon),
        value: item.name
      }, { value: item.itemSubType?.name }],
      urlPrefix: 'item'
    })
  }

  static drops (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Drops',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: ItemNPCLootTable) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: item.droppedBy ? Number(Math.round(item.droppedBy?.[0]?.LootTableNPC?.dropChance * 10000) / 100) : Number(Math.round(item.containedIn?.[0]?.LootTableGameObject?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static pickpocketing (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Pickpocketing',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.pickpocketedBy[0]?.PickpocketLootTableNPC?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static skinning (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Skinning',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.skinnedBy[0]?.SkinningLootTableNPC?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static salvage (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Salvaging',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.salvagedBy[0]?.SalvageLootTableNPC?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static mining (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Mining',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.minedBy[0]?.MiningLootTableNPC?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static gathering (key: string, title: string, data: PaginatedData<ItemNPCLootTable>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Gathering',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.gatheredBy[0]?.GatheringLootTableNPC?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static contains (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Contains',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.containedIn[0]?.LootTableGameObject?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static fishing (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Fishing',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Chance to Fish (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: Number(Math.round(item.fishedFrom[0]?.FishingTableGameObject?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static fishingZone (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Fishing',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Area', sortable: true },
        { label: 'Chance to Fish (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: any) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          { value: item.itemSubType?.name },
          { value: item.fishedInZone[0]?.FishingTableZone?.area },

          { value: Number(Math.round(item.fishedInZone[0]?.FishingTableZone?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static loot (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Loot',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.ItemLootTable?.minCount !== item.ItemLootTable?.maxCount
              ? `${item.ItemLootTable?.minCount} - ${item.ItemLootTable?.maxCount}`
              : item.ItemLootTable?.minCount
          },
          { value: Number(Math.round(item.ItemLootTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static lootedFrom (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Looted From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Drop Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.ItemLootTable?.minCount !== item.ItemLootTable?.maxCount
              ? `${item.ItemLootTable?.minCount} - ${item.ItemLootTable?.maxCount}`
              : item.ItemLootTable?.minCount
          },
          { value: Number(Math.round(item.ItemLootTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static disenchanting (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Disenchanting',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.DisenchantingTable?.minCount !== item.DisenchantingTable?.maxCount
              ? `${item.DisenchantingTable?.minCount} - ${item.DisenchantingTable?.maxCount}`
              : item.DisenchantingTable?.minCount
          },
          { value: Number(Math.round(item.DisenchantingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static disenchantedFrom (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Disenchanted From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.DisenchantingTable?.minCount !== item.DisenchantingTable?.maxCount
              ? `${item.DisenchantingTable?.minCount} - ${item.DisenchantingTable?.maxCount}`
              : item.DisenchantingTable?.minCount
          },
          { value: Number(Math.round(item.DisenchantingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static milling (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Milling',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.MillingTable?.minCount !== item.MillingTable?.maxCount
              ? `${item.MillingTable?.minCount} - ${item.MillingTable?.maxCount}`
              : item.MillingTable?.minCount
          },
          { value: Number(Math.round(item.MillingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static milledFrom (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Milled From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.MillingTable?.minCount !== item.MillingTable?.maxCount
              ? `${item.MillingTable.minCount} - ${item.MillingTable?.maxCount}`
              : item.MillingTable?.minCount
          },
          { value: Number(Math.round(item.MillingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static prospecting (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Prospecting',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.ProspectingTable?.minCount !== item.ProspectingTable?.maxCount
              ? `${item.ProspectingTable?.minCount} - ${item.ProspectingTable?.maxCount}`
              : item.ProspectingTable?.minCount
          },
          { value: Number(Math.round(item.ProspectingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static prospectedFrom (key: string, title: string, data: Item[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Prospected From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Quantity', sortable: true },
        { label: 'Chance (%)', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) =>
        [
          {
            value: item.name,
            thumbnail: Icon.fromName(item.icon),
            alt: `${item.name} Icon`
          },
          {
            value: item.ProspectingTable?.minCount !== item.ProspectingTable?.maxCount
              ? `${item.ProspectingTable?.minCount} - ${item.ProspectingTable?.maxCount}`
              : item.ProspectingTable?.minCount
          },
          { value: Number(Math.round(item.ProspectingTable?.dropChance * 10000) / 100) }
        ],
      urlPrefix: 'item'
    })
  }

  static objectiveOf (key: string, title: string, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Objective Of',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Req. Level', sortable: true },
        { label: 'Exp.', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) =>
        [
          {
            value: quest.name
          },
          { value: quest.minLevel },
          { value: quest.rewardExperience }
        ],
      urlPrefix: 'quest'
    })
  }

  static objectiveOfSource (key: string, title: string, data: Quest[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Required For',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Req. Level', sortable: true },
        { label: 'Exp.', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) =>
        [
          {
            value: quest.name
          },
          { value: quest.level },
          { value: quest.rewardExperience }
        ],
      urlPrefix: 'quest'
    })
  }

  static startsQuests (key: string, title: string, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Starts',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Rec. Level', sortable: true },
        { label: 'Min. Level', sortable: true },
        { label: 'Money', sortable: true },
        { label: 'Exp.', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) =>
        [
          {
            value: quest.name
          },
          { value: quest.level },
          { value: quest.minLevel },
          { value: quest.rewardMoney },
          { value: quest.rewardExperience }
        ],
      urlPrefix: 'quest'
    })
  }

  static quests (key: string, title: string, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Quests',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Type', sortable: true },
        { label: 'Rec. Level', sortable: true },
        { label: 'Min. Level', sortable: true },
        { label: 'Money', sortable: true },
        { label: 'Exp.', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) =>
        [
          {
            value: quest.name
          },
          {
            value: quest.questSort?.name
          },
          { value: quest.level > 0 ? quest.level : quest.minLevel },
          { value: quest.minLevel },
          { value: quest.rewardMoney, isMoney: true },
          { value: quest.rewardExperience }
        ],
      urlPrefix: 'quest'
    })
  }

  static endsQuests (key: string, title: string, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Ends',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true, alignLeft: true },
        { label: 'Rec. Level', sortable: true },
        { label: 'Min. Level', sortable: true },
        { label: 'Money', sortable: true },
        { label: 'Exp.', sortable: true }

      ],
      data,
      rowAccessor: (quest: Quest) =>
        [
          {
            value: quest.name
          },
          { value: quest.level },
          { value: quest.minLevel },
          { value: quest.rewardMoney },
          { value: quest.rewardExperience }
        ],
      urlPrefix: 'quest'
    })
  }

  static droppedBy (key: string, title: string | undefined, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Dropped By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.drops[0]?.LootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static skinnedBy (key: string, title: string, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Skinned From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.skinnings[0]?.SkinningLootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static salvagedFrom (key: string, title: string, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Salvaged From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.salvage[0]?.SalvageLootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static pickpocketedBy (key: string, title: string, data: NPCWithLootTable[]): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Pickpocketed From',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Level', sortable: true },
        { label: 'Location', sortable: false },
        { label: 'Drop Chance', sortable: true }
      ],
      data,
      rowAccessor: (npc: any) => {
        const level = `${npc.minLevel}${
          npc.minLevel === npc.maxLevel ? '' : ` - ${npc.maxLevel}`
        }`
        const location = npc.locations?.length ? getLocationsFromNPC(npc) : ''
        const dropChance = npc.pickpockets[0]?.PickpocketLootTableNPC?.dropChance
        const dropChanceFormatted = `${(dropChance * 100).toFixed(2)}%`
        return [
          { thumbnail: Icon.fromName(npc.id, true), value: npc.name },
          { value: npc.minLevel, rendered: level },
          { value: location },
          { value: dropChance, rendered: dropChanceFormatted }
        ]
      },
      urlPrefix: 'npc'
    })
  }

  static skillRecipes (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Recipes',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Reagents', sortable: false },
        { label: 'Skill', sortable: false }
      ],
      data,
      rowAccessor: (spell: Spell) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        },
        {
          isReagents: true,
          value: spell.reagents
        },
        {
          isSkills: true,
          value: spell.skills
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static skillRecipeItems (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Recipe Items',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          thumbnail: Icon.fromName(item.icon),
          value: item.name
        }
      ],
      urlPrefix: 'item'
    })
  }

  static skillCraftedItems (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Crafted Items',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          thumbnail: Icon.fromName(item.icon),
          value: item.name
        }
      ],
      urlPrefix: 'item'
    })
  }

  static skillRequiredBy (key: string, title: string, data: PaginatedData<Item>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Required By',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (item: Item) => [
        {
          thumbnail: Icon.fromName(item.icon),
          value: item.name
        }
      ],
      urlPrefix: 'item'
    })
  }

  static skillSpells (key: string, title: string, data: PaginatedData<Spell>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Spells',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (spell: Spell) => [
        {
          thumbnail: Icon.fromName(spell.icon),
          value: spell.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static skillQuests (key: string, title: string, data: PaginatedData<Quest>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Quests',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (quest: Quest) => [
        {
          value: quest.name
        }
      ],
      urlPrefix: 'spell'
    })
  }

  static skillTrainers (key: string, title: string, data: PaginatedData<NPC>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Trainers',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true }
      ],
      data,
      rowAccessor: (npc: NPC) => [
        {
          value: npc.name
        }
      ],
      urlPrefix: 'npc'
    })
  }

  static titles (key: string, title: string, data: PaginatedData<Title>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Titles',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Category', sortable: true },
        { label: 'Side', sortable: true },
        { label: 'Expansion', sortable: true }
      ],
      data,
      rowAccessor: (title: Title) => [
        {
          value: title.name
        },
        {
          value: title.titleCategory?.name
        },
        {
          value: title.side?.name
        },
        {
          value: title.expansion?.name
        }
      ],
      urlPrefix: 'title'
    })
  }

  static skills (key: string, title: string, data: PaginatedData<Skill>): RelatedTableFilterOption {
    return new RelatedTableFilterOption({
      key: key,
      title: title || 'Skills',
      isDisabled: false,
      column: [
        { label: 'Name', sortable: true },
        { label: 'Category', sortable: true }
      ],
      data,
      rowAccessor: (skill: Skill) => [
        {
          thumbnail: Icon.fromName(skill.icon),
          value: skill.name
        },
        {
          value: skill.category?.name
        }
      ],
      urlPrefix: 'skill'
    })
  }

  get id (): string {
    return `related-table-${this.#title ? kebabCase(this.#title) : 'null'}`
  }

  get urlPrefix (): string | undefined {
    return this.#urlPrefix
  }

  get key () {
    return this.#key
  }

  get title () {
    return this.#title
  }

  get isDisabled () {
    return this.#isDisabled
  }

  get column () {
    return this.#column
  }

  get data (): any[] {
    return this.#data
  }

  get rowAccessor (): RowAccessor | undefined {
    return this.#rowAccessor
  }

  copyWith (props: Partial<RelatedTableFilterOptionProps>): RelatedTableFilterOption {
    const { key, title, isDisabled, column, data, rowAccessor, urlPrefix } = props

    return new RelatedTableFilterOption(
      {
        key: key ?? this.#key,
        title: title ?? this.#title,
        isDisabled: isDisabled ?? this.#isDisabled,
        column: column ?? this.#column,
        data: data ?? this.#data,
        rowAccessor: rowAccessor ?? this.#rowAccessor,
        urlPrefix: urlPrefix ?? this.#urlPrefix
      }
    )
  }
}
