import locationTypes from '@/store/modules/location/location-types'
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('location', [...Object.keys(locationTypes.actions)])
  },
  computed: {
    ...mapGetters('location',
      [...Object.keys(locationTypes.getters)]
    )
  }
}
