<template>
  <div class="flex flex-col items-center w-full ">
    <div class="w-full overflow-auto">
      <table
        id="list"
        class="w-full shadow-lg max-h-96 bg-bg-200"
      >
        <thead class="text-table-text bg-bg-900">
          <tr>
            <th
              v-for="(col, i) in columns"
              :key="`col-${i}`"
              class="sm:table-cell hidden py-4 pl-4 text-base text-left transition cursor-pointer first:table-cell md:table-cell text-footer-text hover:text-primary"
              @click="col.sortable ? changeSortColumn(i) : null"
            >
              {{ col.label }}
              <img
                v-if="col.sortable && i === sortColumn"
                src="@/assets/img/icons/arrow_dropdown.svg"
                alt="Dropdown Arrow"
                class="inline dropdown-icon"
                :class="{ reverse: !sortAscending }"
              >
            </th>
          </tr>
        </thead>
        <tbody
          class="block overflow-auto table-height"
        >
          <!-- Skeleton Loaders -->
          <template v-if="loadingContent">
            <tr
              v-for="i in pageInfo.rowsPerPage"
              :key="`skeleton-${i}`"
            >
              <td
                :colspan="columns.length"
                class="h-20 "
              >
                <div
                  class="w-full skeleton-loader h-1/2"
                />
              </td>
            </tr>
          </template>
          <!-- Actual Content -->
          <template
            v-for="(row, i) in rows"
            v-else
          >
            <tr
              :key="`row-${i}`"
              class="transition-all border-b-2 group border-bg-900 bg-gradient-to-r from-table-1 to-table-2 odd:bg-gradient-to-r odd:from-table-3 odd:to-table-4 hover:from-table-1 hover:to-table-2"
            >
              <td
                v-for="(field, j) in row"
                :key="`row-field-${j}`"
                class="px-3 py-2 text-base text-left first:table-cell md:table-cell text-table-text"
              >
                <router-link
                  :to="getUrl(storedData[i])"
                  class="relative flex items-center"
                >
                  <img
                    v-if="field.icon"
                    class="inline float-left h-10 mr-3 border-2 border-bg-200"
                    :src="`https://cdn.wowclassicdb.com/icons/${field.icon.name ? field.icon.name : field.icon}.jpg`"
                    :alt="field.name"
                  >
                  <img
                    v-if="field.thumbnail"
                    v-lazy="field.thumbnail"
                    v-tooltip="{
                      data: getRow(i),
                      type: relatedTablePrefix ? relatedTablePrefix : 'item',
                    }"
                    :style="{ height: field.thumbnailHeight }"
                    :alt="field.name"
                    class="object-contain w-16 h-16 pr-4"
                  >
                  <div
                    v-if="field.icon"
                    v-tooltip="{
                      data: getRow(i),
                      type: relatedTablePrefix ? relatedTablePrefix : 'item',
                    }"
                    class="cursor-pointer"
                  >
                    <p
                      class="font-normal"
                      :style="{'color': '#' + getColorForRow(i, true)}"
                    >
                      {{ field.rendered || field.value || '' }}
                    </p>

                    <div v-if="storedData[i].displayClassOnTooltip">
                      <span
                        v-for="(itemClass, classIndex) in storedData[i].allowedClasses"
                        :key="classIndex"
                        :style="{'color': '#' + itemClass.colorCode}"
                      >
                        {{ itemClass.name }}
                      </span>
                    </div>
                  </div>
                  <p
                    v-if="!field.icon"
                    class="flex-1 w-full"
                  >
                    <span
                      v-if="j === 0"
                      v-tooltip="{
                        data: getRow(i),
                        type: relatedTablePrefix ? relatedTablePrefix : 'item',
                      }"
                      class="flex-1 w-full font-bold"
                      :style="{'color': '#' + getColorForRow(i, true)}"
                    >
                      {{ field.rendered || field.value || '-' }}
                    </span>
                    <span
                      v-else
                      class="flex-1 w-full transition-all text-table-text group-hover:text-primary"
                    >
                      <div v-if="field.isMoney || field.isReagents || field.isTokens || field.isSkills || field.isCurrency">
                        <money
                          v-if="field.isMoney"
                          :price="field.value"
                        />
                        <div
                          v-if="field.isReagents"
                          class="space-y-2"
                        >
                          <span
                            v-for="(reagent, reagentIndex) in field.value"
                            :key="reagentIndex"
                            class="inline-block"
                          >
                            <img
                              v-tooltip="{
                                data: reagent,
                                type: 'item',
                              }"
                              :src="`https://cdn.wowclassicdb.com/icons/${reagent.icon.name ? reagent.icon.name : reagent.icon}.jpg`"
                              :alt="reagent.name"
                              class="inline w-8 h-8"
                            >
                            <span
                              v-tooltip="{
                                data: reagent,
                                type: 'item',
                              }"
                              class="inline"
                            >
                              {{ reagent.SpellReagents.amount }}x
                              {{ reagent.name }}
                            </span>
                          </span>
                        </div>
                        <div
                          v-if="field.isCurrency"
                          class="space-y-2"
                        >
                          <div
                            v-for="index in 5"
                            :key="'currency' + index"
                          >
                            <router-link
                              v-if="field.value[`requiredCurrency${index}`]"
                              :to="`/currency/${field.value[`requiredCurrency${index}`].id}`"
                            >
                              <span
                                class="inline-block"
                              >
                                <img
                                  v-tooltip="{
                                    data: field.value[`requiredCurrency${index}`],
                                    type: 'currency',
                                  }"
                                  :src="`https://cdn.wowclassicdb.com/icons/${field.value[`requiredCurrency${index}`].icon?.name}.jpg`"
                                  :alt="field.value[`requiredCurrency${index}`].name"
                                  class="inline w-8 h-8"
                                >
                                <span
                                  v-tooltip="{
                                    data: field.value[`requiredCurrency${index}`],
                                    type: 'currency',
                                  }"
                                  class="inline font-bold"
                                  :style="{'color': '#9F9D96'}"
                                >
                                  {{ field.value[`requiredCurrencyCount${index}`] }}x
                                  {{ field.value[`requiredCurrency${index}`].name }}
                                </span>
                              </span>
                            </router-link></div>
                        </div>
                        <div
                          v-if="field.isTokens"
                          class="space-y-2"
                        >
                          <div
                            v-for="index in 5"
                            :key="'item' + index"
                          >
                            <router-link
                              v-if="field.value[`requiredItem${index}`]"
                              :to="`/item/${field.value[`requiredItem${index}`].id}`"
                            >
                              <span
                                class="inline-block"
                              >
                                <img
                                  v-tooltip="{
                                    data: field.value[`requiredItem${index}`],
                                    type: 'item',
                                  }"
                                  :src="`https://cdn.wowclassicdb.com/icons/${field.value[`requiredItem${index}`].icon?.name}.jpg`"
                                  :alt="field.value[`requiredItem${index}`].name"
                                  class="inline w-8 h-8"
                                >
                                <span
                                  v-tooltip="{
                                    data: field.value[`requiredItem${index}`],
                                    type: 'item',
                                  }"
                                  class="inline font-bold"
                                  :style="{'color': '#' + getColorForRow(field.value[`requiredItem${index}`], false) }"
                                >
                                  {{ field.value[`requiredItemCount${index}`] }}x
                                  {{ field.value[`requiredItem${index}`].name }}
                                </span>
                              </span>

                            </router-link>
                          </div>
                        </div>
                        <div v-if="field.isSkills">
                          <div
                            v-for="(skill, s) in field.value"
                            :key="s"
                            class="space-y-2"
                          >
                            <div class="flex items-center">
                              <img
                                v-if="skill.icon"
                                :alt="skill.name"
                                :src="`https://cdn.wowclassicdb.com/icons/${skill.icon?.name}.jpg`"
                                class="object-contain w-16 h-16 pr-4"
                              >
                              <div>
                                <p>{{ skill.name }}</p>
                                <span class="text-difficulty-normal"> {{ skill.SpellSkills.yellowLevel }} </span>
                                <span class="text-difficulty-easy"> {{ skill.SpellSkills.greenLevel }} </span>
                                <span class="text-difficulty-very-easy"> {{ skill.SpellSkills.greyLevel }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span v-else>
                        {{ field.rendered || field.value || '-' }}</span>
                    </span>
                  </p>
                </router-link>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <Pagination
        v-if="showPagination && pageInfo.totalItems > pageInfo.itemsPerPage"
        class="py-4"
        :total-entries="pageInfo.totalItems"
        :entries-per-page="pageInfo.itemsPerPage"
        :current-page="pageInfo.currentPage"
        :related-table="serverSide ? false : true"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue'
import tooltipMixin from '../mixins/tooltip-mixin'
import Money from './Money.vue'
export default {
  name: 'Table',
  components: {
    Pagination,
    Money
  },
  mixins: [
    tooltipMixin
  ],
  props: {
    showPagination: {
      type: Boolean,
      required: false,
      default: () => true
    },
    defaultSortColumn: {
      type: Number,
      default: 0
    },
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    rowAccessor: Function,
    serverSide: {
      type: String,
      default: null,
      required: false
    },
    relatedTablePrefix: {
      type: String,
      default: null,
      required: false
    },
    pageData: {
      type: Object,
      required: false,
      default: () => { return {} }
    },

    // Filter object have the syntax { <field>: { <operator>: <value>, ... } } NOTE: Server side only
    filter: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      sortColumn: this.defaultSortColumn,
      sortAscending: true,
      myTimeout: undefined
    }
  },
  computed: {
    storedData () {
      return this.serverSide ? this.$store.getters.tableDataForPage : this.data
    },
    loadingContent () {
      return this.$store.state.loading
    },
    query () {
      return this.$route.query
    },
    pageInfo () {
      return {
        totalItems: this.pageData.totalItems ? this.pageData.totalItems : this.data.length,
        rowsPerPage: this.pageData.rowsPerPage ? this.pageData.rowsPerPage : 50,
        itemsPerPage: this.pageData.itemsPerPage ? this.pageData.itemsPerPage : 50,
        currentPage: this.pageData.currentPage ? this.pageData.currentPage : 1,
        ...this.$store.state.pageInfo
      }
    },
    rows () {
      const rows = this.storedData.map(this.rowAccessor)
      if (!this.pageData) {
        const sortedData = this.serverSide ? rows : rows.sort((a, b) => { // Client-side sort and pagination
          const cA = a[this.sortColumn].value
          const cB = b[this.sortColumn].value
          const compare = typeof cA === 'string' ? cA.localeCompare(cB) : cA - cB
          return compare * (this.sortAscending ? 1 : -1)
        }).slice((this.pageInfo.currentPage - 1) * this.pageInfo.rowsPerPage, this.pageInfo.currentPage * this.pageInfo.rowsPerPage)

        return sortedData
      } else return this.storedData.map(this.rowAccessor)
    }
  },
  watch: {
    '$route.query.p': 'changePage',
    filter: function () {
      if (this.myTimeout) {
        clearTimeout(this.myTimeout)
      }

      this.myTimeout = setTimeout(() => {
        this.fetchRows()
      }, 600)
    }
  },

  methods: {
    getRow (i) {
      const row = this.storedData[i].item ? this.storedData[i].item : this.storedData[i]
      return row
    },
    changeSortColumn (col) {
      // Switch desc/asc if same col, otherwise switch
      if (col === this.sortColumn) this.sortAscending = !this.sortAscending
      else {
        this.sortColumn = col
        this.sortAscending = true
      }

      if (this.serverSide) this.fetchRows()
    },
    clearTable () { return this.$store.dispatch('handleFilterChange') },
    changePage () { // Client-side pagination
      if (this.serverSide) {
        this.fetchRows()
        return
      }
      if (!this.pageData?.currentPage) { this.pageInfo.currentPage = this.$route.query.p ? parseInt(this.$route.query.p) : 1 }
    },

    getColorForRow (value, valueIsIndex) {
      if (value && value.itemQuality) {
        return value.itemQuality?.colorCode
      }
      if (value.items && value.items.length) {
        return value.items[0].itemQuality?.colorCode
      }
      if (valueIsIndex) {
        if (this.storedData[value].itemQuality) {
          return this.storedData[value].itemQuality?.colorCode
        } else if (this.storedData[value].items?.length) {
          return this.storedData[value].items[0].itemQuality?.colorCode
        }
      }
      return '9F9D96'
    },

    getUrl (storedData) {
      if (this.relatedTablePrefix) {
        return `/${this.relatedTablePrefix}/${storedData.item ? storedData.item.id : storedData.id}`
      }

      if (this.serverSide) {
        return `${this.serverSide.slice(0, this.serverSide.length - 1)}/${storedData.id}`
      }

      if (!this.serverSide && !this.relatedTablePrefix) {
        if (storedData.itemQualityId) { return `/item/${storedData.id}` }
        if (storedData.npcType) { return `/npc/${storedData.id}` }
        if (storedData.items) { return `/item-set/${storedData.id}` }
        if (storedData.minLevel >= 0) { return `/quest/${storedData.id}` }
        if (!isNaN(storedData.spellSchoolId)) { return `/spell/${storedData.id}` }
        if (storedData.gameObjectType) { return `/object/${storedData.id}` }
        if (storedData.area) { return `/zone/${storedData.id}` }
      }

      return ''
    },

    fetchRows () {
      const page = this.$route.query.p || 1
      const sortBy = this.columns[this.sortColumn].serverSideSortField
      const sortByField = this.columns[this.sortColumn].serverSideSortNested
      const sortOrder = this.sortAscending ? 'ASC' : 'DESC'
      // Create filter strings
      const filterStrings = []
      for (const [field, values] of Object.entries(this.filter)) {
        for (const [operator, value] of Object.entries(values)) {
          let val = value
          const options = []
          if (Array.isArray(value)) options.push('a')
          else val = [val]
          if (typeof val[0] === 'number') options.push('i')

          filterStrings.push(`filter_${field}=${operator}:${val.join(',')}${options.length ? ':' + options.join('') : ''}`)
        }
      }

      const url = `${this.serverSide}?sort_by=${sortBy}&sort_order=${sortOrder}&p=${page}${sortByField ? `&sort_field=${sortByField}` : ''}${filterStrings.length ? '&' + filterStrings.join('&') : ''}`
      if (this.serverSide) return this.$store.dispatch('fetchTableData', url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/mixins';
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

td,
th {
  width: 20rem;
}

td + td,
th + th {
  width: auto;
}

.table-height {
  max-height: 800px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

#list {
  /* Hide all columns but the first one, 640 is the SM breakpoint for tailwind */
  @media (max-width: 640px) {
    th:not(:first-child),
    td:not(:first-child) {
      display: none;
    }
  }
}
</style>
