<template>
  <button
    v-if="filter && filter.data.data || filter.isDisabled"
    :id="`filter-option-${filter.id}`"
    class="flex items-center py-0 px-3.5 my-0 mr-1.5 ml-0 h-10 text-base rounded cursor-pointer placeholder-item-poor hover:bg-bg-300 transition duration-200 focus:ring-2 focus:ring-primary hover:text-primary focus:outline-none"
    :disabled="filter.isDisabled"
    :class="[isActive ? 'bg-button-active text-primary' : 'bg-button-inactive text-item-poor',
             filter.isDisabled ? 'pointer-events-none opacity-60': '']"
    @click="emitFunction($event)"
  >
    {{ filter.title }} ({{ filter.data.totalItems ? filter.data.totalItems : filter.data.length }})
  </button>
</template>

<script>
// eslint-disable-next-line semi
import RelatedTableFilterOption from '../models/RelatedTableFilterOption';

export default {
  props: {
    filter: {
      type: RelatedTableFilterOption,
      required: true
    },
    isActive: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    emitFunction (event) {
      if (!this.isActive) { this.$emit('click', event) }
    }
  }
}

</script>
